import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { FaFileExport, FaDownload, FaWhatsapp } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

export default function InvoiceGenerator({ user, libraryId }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pdfDataUri, setPdfDataUri] = useState(null);
  const [libraryDetails, setLibraryDetails] = useState({
    name: '',
    phone: '',
    address: '',
    slots: []
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSlotPrice, setSelectedSlotPrice] = useState(0);

  useEffect(() => {
    const fetchLibraryDetails = async () => {
      setIsLoading(true);
      try {
        const libraryDoc = await getDoc(doc(db, 'libraries', libraryId));
        if (libraryDoc.exists()) {
          const data = libraryDoc.data();
          setLibraryDetails({
            name: data.name || '',
            phone: data.phoneNumber || '',
            address: data.address || '',
            slots: data.slots || []
          });

          const selectedSlot = data.slots.find(slot => slot.time === user.selectedSlot);
          if (selectedSlot) {
            setSelectedSlotPrice(selectedSlot.price);
          } else {
            toast.error('Selected slot price not found');
          }
        } else {
          console.error("Library not found");
          toast.error("Library details not found");
        }
      } catch (error) {
        console.error("Error fetching library details:", error);
        toast.error("Failed to fetch library details");
      } finally {
        setIsLoading(false);
      }
    };

    fetchLibraryDetails();
  }, [libraryId, user.selectedSlot]);

  const generateInvoice = () => {
    const doc = new jsPDF();

    doc.addFont('https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf', 'Roboto', 'normal');
    doc.setFont('Roboto');

    doc.setFontSize(22);
    doc.setTextColor(0, 122, 255);
    doc.text(libraryDetails.name, 105, 20, { align: 'center' });
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.text(libraryDetails.address, 105, 28, { align: 'center' });
    doc.text(`Phone: ${libraryDetails.phone}`, 105, 33, { align: 'center' });
    doc.setLineWidth(0.5);
    doc.line(10, 42, 200, 42);

    doc.setFontSize(18);
    doc.setTextColor(0);
    doc.text('INVOICE', 10, 55);
    doc.setFontSize(12);
    doc.setTextColor(80);
    doc.text(`Date: ${new Date().toLocaleDateString()}`, 10, 65);
    doc.text(`Invoice #: INV-${user.id.substr(0, 6)}`, 10, 70);

    doc.setFontSize(14);
    doc.setTextColor(0);
    doc.text('Bill To:', 10, 85);
    doc.setFontSize(12);
    doc.setTextColor(60);
    doc.text(user.name, 10, 95);
    doc.text(user.email, 10, 100);
    doc.text(`Seat: ${user.selectedSeat || 'Not selected'}`, 10, 105);
    doc.text(`Slot: ${user.selectedSlot || 'N/A'}`, 10, 110);

    doc.setLineWidth(0.5);
    doc.line(10, 120, 200, 120);

    const formattedPrice = typeof selectedSlotPrice === 'number' ? selectedSlotPrice.toFixed(2) : selectedSlotPrice.toString();

    autoTable(doc, {
      startY: 130,
      head: [['Description', 'Slot', 'Amount', 'Status']],
      body: [
        [
          'Library Subscription',
          `${user.selectedSlot || 'N/A'}`,
          `₹ ${formattedPrice}`,
          user.billingCycles[user.currentCycleIndex].status || 'Pending',
        ]
      ],
      headStyles: {
        fillColor: [0, 122, 255],
        textColor: [255, 255, 255],
        font: 'Roboto',
      },
      styles: {
        fillColor: [245, 245, 245],
        fontSize: 11,
        font: 'Roboto',
      },
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 40 },
        2: { cellWidth: 40,},
        3: { cellWidth: 40, },
      }
    });

    const finalY = doc.lastAutoTable.finalY || 150;
    doc.setFontSize(14);
    doc.setTextColor(0, 122, 255);
    doc.text(`Total: ₹ ${formattedPrice}`, 190, finalY + 20, { align: 'right' });

    doc.setFontSize(10);
    doc.setTextColor(128);
    doc.text('Thank you for your business!', 105, 280, { align: 'center' });

    const pdfDataUri = doc.output('datauristring');
    setPdfDataUri(pdfDataUri);
    setIsModalOpen(true);
  };

  const handleDownload = () => {
    if (pdfDataUri) {
      const link = document.createElement('a');
      link.href = pdfDataUri;
      link.download = `invoice_${user.name}_${user.billingCycles[user.currentCycleIndex].startDate}.pdf`;
      link.click();
      toast.success('Invoice downloaded successfully!');
    }
  };

  const handleWhatsAppShare = async () => {
    if (pdfDataUri) {
      try {
        // Convert data URI to Blob
        const pdfBlob = await fetch(pdfDataUri).then(res => res.blob());

        // Create a File from the Blob
        const pdfFile = new File([pdfBlob], `invoice_${user.name}.pdf`, { type: 'application/pdf' });

        // Check if the Web Share API supports sharing files
        if (navigator.share && navigator.canShare && navigator.canShare({ files: [pdfFile] })) {
          await navigator.share({
            files: [pdfFile],
            title: 'Invoice',
            text: `Here's your invoice from ${libraryDetails.name}`,
          });
          toast.success('Invoice shared successfully!');
        } else {
          // Fallback for browsers that don't support file sharing
          const message = encodeURIComponent(`Here's your invoice from ${libraryDetails.name}`);
          const whatsappUrl = `https://wa.me/?text=${message}`;
          window.open(whatsappUrl, '_blank');
          toast.success('WhatsApp opened for sharing. Please attach the PDF manually.');
        }
      } catch (error) {
        console.error('Error sharing invoice:', error);
        toast.error('Failed to share invoice. Please try downloading and sharing manually.');
      }
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <button
        onClick={generateInvoice}
        className="text-green-600 hover:text-green-900 transition-colors p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-green-500"
        title="Generate Invoice"
      >
        <FaFileExport className="h-4 w-4" />
        <span className="sr-only">Generate Invoice</span>
      </button>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-lg w-full">
            <h2 className="text-2xl font-bold mb-4">Invoice Preview</h2>
            <div className="mt-4 mb-4">
              {pdfDataUri && (
                <iframe src={pdfDataUri} width="100%" height="400px" title="Invoice Preview" />
              )}
            </div>
            <div className="flex justify-between">
              <button
                onClick={handleDownload}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
              >
                <FaDownload className="inline-block mr-2" />
                Download
              </button>
              <button
                onClick={handleWhatsAppShare}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors"
              >
                <FaWhatsapp className="inline-block mr-2" />
                Share on WhatsApp
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 transition-colors"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}