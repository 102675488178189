import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <div className="bg-gradient-to-b from-blue-900 to-purple-900 min-h-screen text-white">
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto bg-white bg-opacity-10 rounded-lg shadow-xl overflow-hidden">
          <div className="p-8 sm:p-12">
            <h1 className="text-4xl font-bold mb-8 text-center">Terms of Service</h1>
            <p className="mb-6 text-gray-300 text-sm">Last updated: 01/10/2024</p>
            
            <div className="space-y-8">
              <Section title="1. Acceptance of Terms">
                <p>By accessing or using The Library Hub's services, you agree to be bound by these Terms of Service. If you disagree with any part of the terms, you may not access our services.</p>
              </Section>
              
              <Section title="2. Description of Service">
                <p>The Library Hub provides a platform for users to find and book study spaces in libraries. Our services include, but are not limited to:</p>
                <ul className="list-disc list-inside ml-4 mt-2 space-y-1">
                  <li>Library space search and discovery</li>
                  <li>Online booking and reservation system</li>
                  <li>User reviews and ratings</li>
                </ul>
              </Section>
              
              <Section title="3. User Accounts">
                <p>To access certain features of our service, you may be required to create an account. You are responsible for:</p>
                <ul className="list-disc list-inside ml-4 mt-2 space-y-1">
                  <li>Maintaining the confidentiality of your account information</li>
                  <li>All activities that occur under your account</li>
                  <li>Notifying us immediately of any unauthorized use of your account</li>
                </ul>
              </Section>
              
              <Section title="4. User Conduct">
                <p>You agree to use our services only for lawful purposes and in accordance with these Terms of Service. You agree not to:</p>
                <ul className="list-disc list-inside ml-4 mt-2 space-y-1">
                  <li>Use the service in any way that violates any applicable laws</li>
                  <li>Impersonate or attempt to impersonate The Library Hub, its employees, or other users</li>
                  <li>Engage in any conduct that restricts or inhibits anyone's use or enjoyment of the service</li>
                </ul>
              </Section>
              
              <Section title="5. Intellectual Property">
                <p>The content, features, and functionality of our service are owned by The Library Hub and are protected by copyright, trademark, and other intellectual property laws.</p>
              </Section>
              
              <Section title="6. Limitation of Liability">
                <p>The Library Hub shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of our services or any linked sites.</p>
              </Section>
              
              <Section title="7. Changes to Terms">
                <p>We reserve the right to modify these Terms of Service at any time. We will notify users of any significant changes by posting a notice on our website.</p>
              </Section>
              
              <Section title="8. Contact Information">
                <p>If you have any questions about these Terms of Service, please contact us at:</p>
                <p className="mt-2">Email: TheLibraryHub.in@gmail.com</p>
                <p>Phone: +91 9335567259</p>
              </Section>
            </div>
          </div>
        </div>
        <div className="mt-8 text-center">
          <Link to="/" className="inline-block px-6 py-3 bg-blue-600 text-white font-semibold rounded-full hover:bg-blue-700 transition-colors duration-300">
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

const Section = ({ title, children }) => (
  <section className="bg-white bg-opacity-5 rounded-lg p-6">
    <h2 className="text-2xl font-semibold mb-4">{title}</h2>
    <div className="text-gray-200">{children}</div>
  </section>
);

export default TermsOfService;