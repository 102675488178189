import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import EditLibrary from './EditLibrary';
import NewLibrary from './NewLibrary';
import LogsViewer from './LogsViewer';
import RequestsModal from './RequestsModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmationModal from './ConfirmationModal';
import dayjs from 'dayjs';
import { useAuth } from './AuthContext';

const OwnerDashboard = () => {
    const { currentUser, reLoginUser } = useAuth();
    const [libraries, setLibraries] = useState([]);
    const [showNewLibraryForm, setShowNewLibraryForm] = useState(false);
    const [showEditLibraryForm, setShowEditLibraryForm] = useState(false);
    const [selectedLibraryId, setSelectedLibraryId] = useState(null);
    const [selectedLibraryName, setSelectedLibraryName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showLogsViewer, setShowLogsViewer] = useState(false);
    const [showRequestsModal, setShowRequestsModal] = useState(false);
    const [requestCount, setRequestCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [newLibraryData, setNewLibraryData] = useState(null);

    useEffect(() => {
        fetchLibraries();
        fetchRequestCount();
    }, [showNewLibraryForm, showEditLibraryForm]);

    const fetchLibraries = async () => {
        try {
            setLoading(true);
            const librariesCollection = collection(db, 'libraries');
            const librariesSnapshot = await getDocs(librariesCollection);
            const librariesList = librariesSnapshot.docs.map((doc, index) => {
                const data = doc.data();
                return {
                    id: doc.id,
                    number: index + 1,
                    ...data,
                    joinDate: data.joinDate instanceof Timestamp 
                        ? dayjs(data.joinDate.toDate()).format('YYYY-MM-DD')
                        : 'N/A'
                };
            });
            setLibraries(librariesList);
        } catch (error) {
            console.error("Error fetching libraries:", error);
            toast.error("Failed to fetch libraries. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const fetchRequestCount = async () => {
        try {
            const requestsCollection = collection(db, 'request_lib');
            const requestsSnapshot = await getDocs(requestsCollection);
            setRequestCount(requestsSnapshot.size);
        } catch (error) {
            console.error("Error fetching request count:", error);
            toast.error("Failed to fetch request count. Please try again.");
        }
    };

    const logChange = async (action, libraryName) => {
        try {
            await addDoc(collection(db, 'logs'), {
                action,
                libraryName,
                ownerEmail: currentUser.email,
                timestamp: Timestamp.now(),
            });
        } catch (error) {
            console.error("Error logging change:", error);
            toast.error("Failed to log the change. Please try again.");
        }
    };

    const handleDelete = async () => {
        try {
            await deleteDoc(doc(db, 'libraries', selectedLibraryId));
            setLibraries(libraries.filter(library => library.id !== selectedLibraryId));
            toast.success(`Library ${selectedLibraryName} has been deleted successfully.`);
            logChange('delete', selectedLibraryName);
            setIsModalOpen(false);
        } catch (error) {
            console.error("Error deleting library:", error);
            toast.error(`Failed to delete library ${selectedLibraryName}.`);
        }
    };

    const handleEdit = (libraryId, libraryName) => {
        setSelectedLibraryId(libraryId);
        setSelectedLibraryName(libraryName);
        setShowEditLibraryForm(true);
    };

    const handleUpdateLibrary = (updatedLibrary) => {
        setLibraries(libraries.map(lib => lib.id === updatedLibrary.id ? {
            ...updatedLibrary,
            joinDate: updatedLibrary.joinDate instanceof Timestamp 
                ? dayjs(updatedLibrary.joinDate.toDate()).format('YYYY-MM-DD')
                : updatedLibrary.joinDate
        } : lib));
        setShowEditLibraryForm(false);
        logChange('update', updatedLibrary.name);
        toast.success(`Library ${updatedLibrary.name} has been updated successfully.`);
    };

    const handleAddLibrary = async (newLibrary) => {
        try {
            setShowNewLibraryForm(false);
            await fetchLibraries();
            logChange('add', newLibrary.name);
            toast.success(`Library ${newLibrary.name} has been added successfully.`);
        } catch (error) {
            if (error.code === 'auth/session-expired') {
                await reLoginUser();
                toast.info("Session expired. Please log in again.");
            } else {
                console.error("Error adding library:", error);
                toast.error(`Failed to add library ${newLibrary.name}.`);
            }
        }
    };

    const openModal = (libraryId, libraryName) => {
        setSelectedLibraryId(libraryId);
        setSelectedLibraryName(libraryName);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenNewLibrary = (requestData) => {
        setNewLibraryData(requestData);
        setShowNewLibraryForm(true);
        setShowRequestsModal(false);
    };

    return (
        <div className="min-h-screen bg-gray-100 p-4 md:p-8">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
                <h2 className="text-2xl md:text-3xl font-semibold mb-4 md:mb-0">The Library Hub Dashboard</h2>
                <div className="flex flex-wrap gap-2 md:gap-4">
                    <button
                        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
                        onClick={() => setShowNewLibraryForm(true)}
    >
                        New Library
                    </button>
                    <button
                        className="px-4 py-2 bg-green-600 text-white rounded-md flex items-center hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
                        onClick={() => setShowLogsViewer(true)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                            <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                        </svg>
                        View Logs
                    </button>
                    <button
                        className="px-4 py-2 bg-purple-600 text-white rounded-md flex items-center hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
                        onClick={() => setShowRequestsModal(true)}
                    >
                        Requests
                        {requestCount > 0 && (
                            <span className="ml-2 bg-red-500 text-white text-xs font-bold rounded-full px-2 py-1">
                                {requestCount}
                            </span>
                        )}
                    </button>
                </div>
            </div>
            
            {loading ? (
                <div className="flex justify-center items-center h-64">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
                </div>
            ) : (
                <div className="overflow-x-auto bg-white rounded-lg shadow">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">No.</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Library Name</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Phone Number</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Address</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Seats</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Registered Date</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {libraries.map(library => (
                                <tr key={library.id} className="hover:bg-gray-50">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{library.number}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{library.name}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{library.phoneNumber}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{library.adminEmail}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{`${library.address}, ${library.district}, ${library.state}, ${library.country} (${library.pincode})`}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{library.totalSeats}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{library.joinDate}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                        <button
                                            onClick={() => handleEdit(library.id, library.name)}
                                            className="text-indigo-600 hover:text-indigo-900 mr-4 focus:outline-none focus:underline"
                                            aria-label={`Edit ${library.name}`}
                                        >
                                            Edit
                                        </button>
                                        <button
                                            onClick={() => openModal(library.id, library.name)}
                                            className="text-red-600 hover:text-red-900 focus:outline-none focus:underline"
                                            aria-label={`Delete ${library.name}`}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

            {showNewLibraryForm && (
                <NewLibrary
                    onClose={() => setShowNewLibraryForm(false)}
                    onAddLibrary={handleAddLibrary}
                    initialData={newLibraryData}
                />
            )}

            {showEditLibraryForm && (
                <EditLibrary
                    libraryId={selectedLibraryId}
                    onClose={() => setShowEditLibraryForm(false)}
                    onUpdate={handleUpdateLibrary}
                />
            )}

            <ConfirmationModal
                isOpen={isModalOpen}
                onClose={closeModal}
                onConfirm={handleDelete}
                title="Confirm Deletion"
                message={`Are you sure you want to delete ${selectedLibraryName}?`}
            />

            {showLogsViewer && <LogsViewer onClose={() => setShowLogsViewer(false)} />}

            <RequestsModal
                isOpen={showRequestsModal}
                onClose={() => setShowRequestsModal(false)}
                onOpenNewLibrary={handleOpenNewLibrary}
            />
        </div>
    );
};

export default OwnerDashboard;