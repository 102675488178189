import React, { useEffect, useState } from 'react';
import { doc, getDoc, collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { ScaleLoader } from 'react-spinners';
import { FaUserPlus, FaMapMarkerAlt, FaChair, FaClock, FaPhoneAlt, FaStar } from 'react-icons/fa';
import JoinForm from './JoinForm';

const ReviewCard = ({ review }) => (
  <div className="bg-white dark:bg-gray-800 p-4 sm:p-6 rounded-lg shadow-lg transition-all duration-300 ease-in-out hover:shadow-xl hover:-translate-y-1 transform">
    <div className="flex justify-between items-center mb-4">
      <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">{review.userName}</h3>
      <div className="flex">
        {[...Array(5)].map((_, i) => (
          <FaStar key={i} className={`w-5 h-5 ${i < review.rating ? 'text-yellow-400' : 'text-gray-300 dark:text-gray-600'}`} />
        ))}
      </div>
    </div>
    <p className="text-gray-600 dark:text-gray-300 mb-4">{review.review}</p>
    <div className="text-sm text-gray-500 dark:text-gray-400">
      {new Date(review.timestamp).toLocaleDateString()}
    </div>
  </div>
);

const InfoCard = ({ icon, title, value, color, isAddress = false, mapLink = '', libraryName = '' }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const truncateAddress = (address, maxLength = 30) => {
        if (address.length <= maxLength) return address;
        return `${address.substring(0, maxLength)}...`;
    };

    const openGoogleMaps = () => {
        if (isAddress) {
            if (mapLink) {
                window.open(mapLink, '_blank');
            } else {
                const searchQuery = encodeURIComponent(`${libraryName} ${value}`);
                window.open(`https://www.google.com/maps/search/?api=1&query=${searchQuery}`, '_blank');
            }
        }
    };

    return (
        <div className={`bg-white dark:bg-gray-800 p-4 sm:p-5 md:p-6 lg:p-8 rounded-lg sm:rounded-xl shadow-lg transition-all duration-300 ease-in-out hover:shadow-xl hover:-translate-y-1 transform relative`}>
            <div className="flex items-center space-x-3 sm:space-x-4">
                <div 
                    className={`transition-transform duration-300 ease-in-out hover:scale-110 transform ${isAddress ? 'cursor-pointer' : ''}`}
                    onClick={isAddress ? openGoogleMaps : undefined}
                >
                    {icon}
                </div>
                <div className="flex-grow">
                    <h3 className={`text-base sm:text-lg md:text-xl font-semibold text-gray-800 dark:text-gray-200 mb-1 sm:mb-2 transition-colors duration-300 ease-in-out hover:text-${color}-600 dark:hover:text-${color}-400`}>{title}</h3>
                    {isAddress ? (
                        <div
                            className="relative cursor-pointer"
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                            onClick={openGoogleMaps}
                        >
                            <p className={`text-sm sm:text-base md:text-lg font-medium text-${color}-600 dark:text-${color}-400 transition-all duration-300 ease-in-out hover:text-${color}-700 dark:hover:text-${color}-300`}>
                                {truncateAddress(value)}
                            </p>
                            {showTooltip && (
                                <div className="absolute z-10 p-2 bg-gray-800 dark:bg-gray-700 text-white text-xs rounded shadow-lg mt-1 whitespace-normal max-w-xs">
                                    {value}
                                </div>
                            )}
                        </div>
                    ) : (
                        <p className={`text-xl sm:text-2xl md:text-3xl font-bold text-${color}-600 dark:text-${color}-400 transition-all duration-300 ease-in-out hover:text-${color}-700 dark:hover:text-${color}-300 hover:scale-105 transform`}>
                            {value}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

const SlotCard = ({ slot, openJoinForm }) => (
    <div className="bg-white dark:bg-gray-800 p-4 sm:p-5 md:p-6 rounded-lg sm:rounded-xl shadow-lg transition-all duration-300 ease-in-out hover:shadow-xl hover:-translate-y-1 transform">
        <div className="flex items-center space-x-2 sm:space-x-3 mb-3 sm:mb-4">
            <FaClock className="text-lg sm:text-xl md:text-2xl text-indigo-600 dark:text-indigo-400 transition-transform duration-300 ease-in-out hover:scale-110 transform" />
            <h3 className="text-base sm:text-lg md:text-xl font-semibold text-gray-800 dark:text-gray-200 transition-colors duration-300 ease-in-out hover:text-indigo-600 dark:hover:text-indigo-400">{slot.time || "N/A"}</h3>
        </div>
        <div className="flex items-center space-x-2 sm:space-x-3 mb-4 sm:mb-5 md:mb-6">
            <span className="text-lg sm:text-xl md:text-2xl text-emerald-600 dark:text-emerald-400 font-semibold transition-transform duration-300 ease-in-out hover:scale-110 transform">₹</span>
            <p className="text-base sm:text-lg md:text-xl font-medium text-gray-700 dark:text-gray-300 transition-colors duration-300 ease-in-out hover:text-emerald-600 dark:hover:text-emerald-400">{slot.price || "N/A"}</p>
        </div>
        <button
            onClick={() => openJoinForm(slot.time)}
            className="w-full bg-indigo-600 text-white py-2 sm:py-2.5 md:py-3 px-4 rounded-md sm:rounded-lg flex items-center justify-center transition-all duration-300 ease-in-out hover:bg-indigo-700 hover:shadow-lg transform hover:scale-105"
        >
            <FaUserPlus className="mr-2 transition-transform duration-300 ease-in-out group-hover:scale-110" /> 
            <span className="text-sm sm:text-base md:text-lg">Join Now</span>
        </button>
    </div>
);

export default function Component() {
    const { libraryId } = useParams();
    const [library, setLibrary] = useState(null);
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [visibleReviews, setVisibleReviews] = useState(5);
    const [hasMoreReviews, setHasMoreReviews] = useState(true);

    useEffect(() => {
        fetchLibrary();
        fetchReviews();
    }, [libraryId]);

    const fetchLibrary = async () => {
        setLoading(true);
        try {
            const libraryRef = doc(db, 'libraries', libraryId);
            const librarySnap = await getDoc(libraryRef);
            if (librarySnap.exists()) {
                setLibrary(librarySnap.data());
            } else {
                console.error("Library not found");
            }
        } catch (error) {
            console.error("Error fetching library:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchReviews = async () => {
        try {
            const reviewsQuery = query(
                collection(db, 'reviews'),
                where('libraryId', '==', libraryId),
                orderBy('timestamp', 'desc'),
                limit(visibleReviews)
            );
            const reviewsSnapshot = await getDocs(reviewsQuery);
            const reviewsData = reviewsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setReviews(reviewsData);
            setHasMoreReviews(reviewsData.length === visibleReviews);
        } catch (error) {
            console.error("Error fetching reviews:", error);
        }
    };

    const loadMoreReviews = () => {
        setVisibleReviews(prevVisible => prevVisible + 5);
    };

    useEffect(() => {
        if (visibleReviews > 5) {
            fetchReviews();
        }
    }, [visibleReviews]);

    const openJoinForm = (slot) => {
        setSelectedSlot(slot);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-blue-50 to-indigo-50 dark:from-gray-900 dark:to-gray-800">
                <ScaleLoader size={50} color="#4F46E5" />
            </div>
        );
    }

    if (!library) {
        return <div className="text-center text-xl sm:text-2xl font-semibold mt-10 text-gray-800 dark:text-gray-200">Library not found</div>;
    }

    return (
        <div className="min-h-screen bg-gradient-to-r from-blue-50 to-indigo-50 dark:from-gray-900 dark:to-gray-800 py-4 sm:py-6 md:py-8 lg:py-12 transition-colors duration-300">
            {/* Hero Section */}
            <div className="relative h-[30vh] sm:h-[35vh] md:h-[40vh] lg:h-[45vh] bg-cover bg-center rounded-lg sm:rounded-xl mb-6 sm:mb-8 md:mb-10 lg:mb-12 mx-auto shadow-xl sm:shadow-2xl overflow-hidden transition-all duration-500 ease-in-out hover:shadow-3xl max-w-[95vw] sm:max-w-[90vw] lg:max-w-6xl"
                style={{ backgroundImage: `url(${library.imageUrl || '/default-library.jpg'})` }}>
                <div className="absolute inset-0 bg-black bg-opacity-60 transition-opacity duration-300 ease-in-out hover:bg-opacity-50"></div>
                <div className="absolute inset-0 flex flex-col justify-center items-center text-center p-4 sm:p-6 md:p-8">
                    <h1 className="text-white text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-2 sm:mb-3 md:mb-4 drop-shadow-lg transform transition-transform duration-300 ease-in-out hover:scale-105">{library.name}</h1>
                    <p className="text-white text-sm sm:text-base md:text-lg lg:text-xl max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl mx-auto leading-relaxed drop-shadow-md transition-all duration-300 ease-in-out hover:text-gray-200 mb-2 sm:mb-3 md:mb-4">
                        {library.description || "A peaceful environment to cultivate knowledge and personal growth."}
                    </p>
                    {library.phoneNumber && (
                        <div className="flex items-center justify-center text-white text-sm sm:text-base md:text-lg transition-all duration-300 ease-in-out hover:text-gray-200">
                            <FaPhoneAlt className="mr-2" />
                            <span>{library.phoneNumber}</span>
                        </div>
                    )}
                </div>
            </div>

            {/* Info Cards Section */}
            <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 md:gap-8 mb-6 sm:mb-8 md:mb-10 lg:mb-12 max-w-[95vw] sm:max-w-[90vw] lg:max-w-6xl px-4">
                <InfoCard icon={<FaChair className="text-3xl sm:text-4xl md:text-5xl text-indigo-600 dark:text-indigo-400" />} title="Total Seats" value={library.totalSeats} color="indigo" />
                <InfoCard icon={<FaChair className="text-3xl sm:text-4xl md:text-5xl text-emerald-500 dark:text-emerald-400" />} title="Available Seats" value={library.availableSeats || "N/A"} color="emerald" />
                <InfoCard 
                    icon={<FaMapMarkerAlt className="text-3xl sm:text-4xl md:text-5xl text-rose-600 dark:text-rose-400" />} 
                    title="Location" 
                    value={library.address || "Not specified"} 
                    color="rose" 
                    isAddress={true} 
                    mapLink={library.mapLink}
                    libraryName={library.name}
                />
            </div>

            {/* Slots Section */}
            <div className="container mx-auto max-w-[95vw] sm:max-w-[90vw] lg:max-w-6xl px-4 mb-12">
                <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 sm:mb-6 md:mb-8 text-gray-800 dark:text-gray-200 text-center transition-all duration-300 ease-in-out hover:text-indigo-700 dark:hover:text-indigo-400">Available Time Slots</h2>
                <div className="grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 md:gap-8">
                    {library.slots && library.slots.length > 0 ? (
                        library.slots.map((slot, index) => (
                            <SlotCard key={index} slot={slot} openJoinForm={openJoinForm} />
                        ))
                    ) : (
                        <p className="text-center text-lg sm:text-xl md:text-2xl font-semibold text-gray-700 dark:text-gray-300 col-span-full">No slots available.</p>
                    )}
                </div>
            </div>

            {/* Reviews Section */}
            <div className="container mx-auto max-w-[95vw] sm:max-w-[90vw] lg:max-w-6xl px-4">
                <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 sm:mb-6 md:mb-8 text-gray-800 dark:text-gray-200 text-center transition-all duration-300 ease-in-out hover:text-indigo-700 dark:hover:text-indigo-400">Reviews</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {reviews.length > 0 ? (
                        reviews.map((review) => (
                            <ReviewCard key={review.id} review={review} />
                        ))
                    ) : (
                        <p className="text-center text-lg sm:text-xl md:text-2xl font-semibold text-gray-700 dark:text-gray-300 col-span-full">No reviews yet.</p>
                    )}
                </div>
                {hasMoreReviews && (
                    <div className="text-center mt-8">
                        <button
                            onClick={loadMoreReviews}
                            className="bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition-colors duration-300"
                        >
                            See More Reviews
                        </button>
                    </div>
                )}
            </div>

            {/* JoinForm Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 w-full bg-black bg-opacity-50 flex justify-center items-center z-50 transition-opacity duration-300 ease-in-out p-4">
                    <div className="bg-white dark:bg-gray-800 p-4 sm:p-6 md:p-8 rounded-lg sm:rounded-xl max-w-[95vw] sm:max-w-[80vw] md:max-w-[60vw] lg:max-w-xl w-full transform transition-all duration-300 ease-in-out hover:scale-105">
                        <JoinForm
                            selectedSlot={selectedSlot}
                            closeModal={closeModal}
                            libraryName={library.name}
                            libraryId={libraryId}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}