// WhyJoin.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaCheckCircle, FaUsers, FaChartLine, FaTools } from 'react-icons/fa';
import JoinTlh from './JoinTlh';

const WhyJoin = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const benefits = [
    { icon: FaUsers, title: 'Expanded Reach', description: 'Connect with a wider audience of students.' },
    { icon: FaChartLine, title: 'Increased Utilization', description: 'Boost your library\'s usage and resource efficiency.' },
    { icon: FaTools, title: 'Streamlined Management', description: 'Access tools to simplify Seat bookings and space management Payments management.' },
    { icon: FaCheckCircle, title: 'Enhanced Visibility', description: 'Showcase your unique facilities and services to potential users.' },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-900 via-indigo-900 to-purple-900 text-white">
      <div className="container mx-auto px-4 py-12 max-w-4xl">
        <motion.h1 
          className="text-4xl md:text-5xl lg:text-6xl font-bold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Why Join The Library Hub?
        </motion.h1>

        <motion.p 
          className="text-lg md:text-xl text-center mb-12 max-w-2xl mx-auto"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          Joining The Library Hub opens up a world of opportunities for your library. Increase your visibility, streamline operations, and connect with a broader audience of knowledge seekers.
        </motion.p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          {benefits.map((benefit, index) => (
            <motion.div 
              key={index}
              className="bg-white bg-opacity-10 rounded-lg p-6 shadow-lg hover:shadow-xl transition-shadow duration-300"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <benefit.icon className="text-4xl text-blue-400 mb-4" />
              <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
              <p className="text-gray-300">{benefit.description}</p>
            </motion.div>
          ))}
        </div>

        <motion.div 
          className="text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <button
            onClick={() => setIsModalOpen(true)}
            className="bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold py-3 px-6 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 inline-block focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
          >
            Join The Library Hub
          </button>
        </motion.div>
      </div>
      <JoinTlh isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

export default WhyJoin;