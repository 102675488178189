import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import LoadingAnimation from '../components/LoadingAnimation';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [role, setRole] = useState(null);
    const [libraryId, setLibraryId] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const auth = getAuth();

        // Handle auth state changes
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setLoading(true);
            if (user) {
                setCurrentUser(user);
                try {
                    const userDoc = await getDoc(doc(db, 'users', user.uid));
                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        setRole(userData.role || null);
                        setLibraryId(userData.libraryId || null);
                    } else {
                        console.warn('No user data found.');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            } else {
                setCurrentUser(null);
                setRole(null);
                setLibraryId(null);
            }
            setLoading(false);
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    // Function to set the library ID for the current user
    const setCurrentUserLibraryId = async (adminId) => {
        try {
            const adminDoc = await getDoc(doc(db, 'users', adminId));
            if (adminDoc.exists()) {
                const adminData = adminDoc.data();
                setLibraryId(adminData.libraryId || null);
            } else {
                console.warn('No admin data found.');
            }
        } catch (error) {
            console.error('Error fetching admin data:', error);
        }
    };

    // Show loading animation while fetching user data
    if (loading) {
        return <LoadingAnimation />;
    }

    return (
        <AuthContext.Provider value={{ currentUser, setCurrentUser, role, setRole, libraryId, setLibraryId, setCurrentUserLibraryId }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
