// Home.js
import React, { useEffect, useState, useCallback, useRef } from 'react'
import { motion, useScroll, useTransform, AnimatePresence, useSpring, useMotionValue, useVelocity, useAnimationFrame } from 'framer-motion'
import { createPortal } from 'react-dom'
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore'
import { db } from '../firebase'
import { FaSearch, FaMapMarkerAlt, FaCalendarAlt, FaUsers, FaTimes, FaChevronRight, FaArrowRight } from 'react-icons/fa'
import Facilities from './Facilities'
import PricingAndTiming from './PricingAndTiming'
import Testimonials from './Testimonials'
import LibraryCarousel from './LibraryCarousel'
import LibraryCard from './LibraryCard'
import LibraryDetails from './LibraryDetails'
import CustomCursor from './CustomCursor'
import SignupModal from './SignupModal'
import Footer from './Footer'


const FloatingElement = ({ children }) => {
  const y = useMotionValue(0)

  useAnimationFrame((t) => {
    let moveBy = Math.sin(t / 1000) * 30
    y.set(moveBy)
  })

  return (
    <motion.div 
      style={{ y }} 
      className="relative z-10"
    >
      {children}
    </motion.div>
  )
}

export default function Home() {
  const [libraries, setLibraries] = useState([])
  const [currentLibraryIndex, setCurrentLibraryIndex] = useState(0)
  const [selectedLibrary, setSelectedLibrary] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [isTyping, setIsTyping] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1)
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false)
  const inputRef = useRef(null)
  const suggestionsRef = useRef(null)

  const { scrollYProgress } = useScroll()
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  })

  useEffect(() => {
    const fetchLibraries = async () => {
      try {
        const querySnapshot = await getDocs(query(collection(db, 'libraries'), orderBy('name'), limit(10)))
        const librariesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        setLibraries(librariesData)
      } catch (error) {
        console.error('Error fetching libraries:', error)
        setError('Failed to fetch libraries. Please try again later.')
      }
    }

    fetchLibraries()
  }, [])

  useEffect(() => {
    if (libraries.length > 0) {
      const intervalId = setInterval(() => {
        setCurrentLibraryIndex(prevIndex => (prevIndex + 1) % libraries.length)
      }, 5000)

      return () => clearInterval(intervalId)
    }
  }, [libraries])

  const fetchSuggestions = useCallback(async (term) => {
    if (term.length < 1) {
      setSuggestions([])
      return
    }

    setIsLoading(true)
    setError(null)

    try {
      const q = query(
        collection(db, 'libraries'),
        orderBy('name'),
        limit(10)
      )
      const querySnapshot = await getDocs(q)
      let results = querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(library => library.name.toLowerCase().includes(term.toLowerCase()))

      results.sort((a, b) => {
        const aStartsWithTerm = a.name.toLowerCase().startsWith(term.toLowerCase())
        const bStartsWithTerm = b.name.toLowerCase().startsWith(term.toLowerCase())
        if (aStartsWithTerm && !bStartsWithTerm) return -1
        if (!aStartsWithTerm && bStartsWithTerm) return 1
        return a.name.localeCompare(b.name)
      })

      setSuggestions(results.slice(0, 2))
      if (results.length === 0) {
        setError('No libraries found. Please try a different search term.')
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error)
      setError('Failed to fetch suggestions. Please try again.')
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (searchTerm) {
        fetchSuggestions(searchTerm)
      } else {
        setSuggestions([])
        setError(null)
      }
    }, 300)

    return () => clearTimeout(debounceTimer)
  }, [searchTerm, fetchSuggestions])

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value)
    setIsTyping(true)
    setSelectedSuggestionIndex(-1)
  }

  const handleSuggestionClick = (library) => {
    setSelectedLibrary(library)
    setSearchTerm('')
    setSuggestions([])
    setError(null)
    setIsTyping(false)
    window.location.href = `/library/${library.id}`
  }

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault()
      setSelectedSuggestionIndex(prev => 
        prev < suggestions.length - 1 ? prev + 1 : prev
      )
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      setSelectedSuggestionIndex(prev => (prev > 0 ? prev - 1 : prev))
    } else if (e.key === 'Enter' && selectedSuggestionIndex !== -1) {
      handleSuggestionClick(suggestions[selectedSuggestionIndex])
    } else if (e.key === 'Escape') {
      setIsTyping(false)
    }
  }

  const handleClickOutside = useCallback((event) => {
    if (suggestionsRef.current && !suggestionsRef.current.contains(event.target) &&
        inputRef.current && !inputRef.current.contains(event.target)) {
      setIsTyping(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  const SuggestionsPortal = ({ children }) => {
    const [portalContainer] = useState(() => document.createElement('div'))
  
    useEffect(() => {
      document.body.appendChild(portalContainer)
      return () => {
        document.body.removeChild(portalContainer)
      }
    }, [portalContainer])
  
    return createPortal(children, portalContainer)
  }

  const handleViewMore = () => {
    window.location.href = '/libraries'
  }

  const handleViewAllLibraries = () => {
    window.open('/libraries', '_blank')
  }

  const handleSignupClick = () => {
    setIsSignupModalOpen(true)
  }

  const handleLearnMoreClick = () => {
    window.location.href = '/why-join'
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-900 via-indigo-900 to-purple-900 text-white overflow-hidden">
      <motion.div
        className="fixed top-0 left-0 right-0 h-1 bg-blue-500 origin-left z-50"
        style={{ scaleX }}
      />
      <div className="container relative mx-auto px-4 py-12">
        <section className="relative overflow-hidden mb-16 md:mb-32">
          <motion.div 
            className="absolute inset-0 z-0"
            animate={{
              scale: [1, 1.1, 1.2, 1.1, 1],
              rotate: [0, 90, 180, 270, 360],
            }}
            transition={{
              duration: 20,
              ease: "linear",
              repeat: Infinity,
            }}
          >
            <div className="w-full h-full bg-gradient-to-br from-blue-500 to-purple-600 opacity-30 rounded-full blur-3xl"></div>
          </motion.div>
          <div className="relative z-10 flex flex-col items-center justify-center py-10 md:py-20 text-center">
            <motion.h1 
              className="text-4xl md:text-5xl lg:text-7xl font-bold mb-4 md:mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              Welcome to The Library Hub
            </motion.h1>
            <motion.p 
              className="text-md mx-4 md:text-xl lg:text-2xl text-gray-300 mb-6 md:mb-10 max-w-2xl"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              Discover thousands of libraries and start your learning journey today!
            </motion.p>
            <motion.div
              className="w-full max-w-md relative"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >
              <div className="relative flex items-center px-4 md:px-8">
                <input
                  ref={inputRef}
                  type="text"
                  placeholder="Search for libraries..."
                  className="w-full px-4 pr-10 md:pr-12 py-3 rounded-full bg-white bg-opacity-20 backdrop-blur-md text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all duration-300 text-sm md:text-base"
                  value={searchTerm}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  onFocus={() => setIsTyping(true)}
                />
                <FaSearch className="absolute right-8 md:right-12 text-gray-300 pointer-events-none" />
              </div>

              <AnimatePresence>
                {isTyping && (suggestions.length > 0 || error) && (
                  <SuggestionsPortal>
                    <motion.div
                      ref={suggestionsRef}
                      className="absolute z-50 w-full max-w-md mt-2 bg-white bg-opacity-95 backdrop-blur-md rounded-lg shadow-lg"
                      style={{
                        top: inputRef.current ? inputRef.current.getBoundingClientRect().bottom + window.scrollY : 0,
                        left: inputRef.current ? inputRef.current.getBoundingClientRect().left : 0,
                      }}
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                    >
                      <div className="flex justify-between items-center px-4 py-2 bg-gray-100">
                        <span className="font-semibold text-gray-700">Suggestions</span>
                        <button
                          onClick={() => setIsTyping(false)}
                          className="text-gray-500 hover:text-gray-700"
                        >
                          <FaTimes />
                        </button>
                      </div>
                      {isLoading ? (
                        <p className="px-4 py-2 text-gray-600">Loading...</p>
                      ) : error ? (
                        <p className="px-4 py-2 text-red-500">{error}</p>
                      ) : (
                        <>
                          <ul className="divide-y divide-gray-200">
                            {suggestions.map((library, index) => (
                              <motion.li
                                key={library.id}
                                className={`px-4 py-3 hover:bg-blue-50 cursor-pointer transition-colors duration-200 ${
                                  index === selectedSuggestionIndex ? 'bg-blue-100' : ''
                                }`}
                                onClick={() => handleSuggestionClick(library)}
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                              >
                                <p className="font-semibold text-gray-800">{library.name}</p>
                                <p className="text-sm text-gray-600">{library.address}</p>
                              </motion.li>
                            ))}
                          </ul>
                          <div className="px-4 py-2 bg-gray-100">
                            <button
                              onClick={handleViewMore}
                              className="w-full text-center text-blue-600 hover:text-blue-800 font-semibold"
                            >
                              View More
                            </button>
                          </div>
                        </>
                      )}
                    </motion.div>
                  </SuggestionsPortal>
                )}
              </AnimatePresence>
            </motion.div>
          </div>
        </section>

        {selectedLibrary && (
          <motion.section 
            className="mb-16 md:mb-32"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-8 md:mb-12">Selected Library</h2>
            <LibraryDetails library={selectedLibrary} />
          </motion.section>
        )}

        <motion.section 
          className="mb-16 md:mb-32 relative z-10"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true, amount: 0.2 }}
        >
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-8 md:mb-12 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">How It Works</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-8">
            {[
              { icon: FaSearch, title: "Search", description: "Find libraries near you" },
              { icon: FaMapMarkerAlt, title: "Choose", description: "Select your preferred library" },
              { icon: FaCalendarAlt, title: "Subscribe", description: "Pick a monthly plan" },
              { icon: FaUsers, title: "Study", description: "Join a vibrant learning community" },
            ].map((step, index) => (
              <FloatingElement key={index}>
                <motion.div 
                  className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg p-6 md:p-8 shadow-lg hover:shadow-xl transition-shadow duration-300"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  viewport={{ once: true, amount: 0.2 }}
                >
                  <step.icon className="text-4xl md:text-5xl text-blue-400 mb-4 md:mb-6" />
                  <h3 className="text-xl md:text-2xl font-semibold mb-2 md:mb-4">{step.title}</h3>
                  <p className="text-gray-300 text-base md:text-lg">{step.description}</p>
                </motion.div>
              </FloatingElement>
            ))}
          </div>
        </motion.section>

        <motion.section 
          className="mb-16 md:mb-32"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true, amount: 0.2 }}
        >
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-8 md:mb-12 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">Explore Our Libraries</h2>
          <div className="text-center">
            <p className="text-lg md:text-xl text-gray-300 mb-6 md:mb-8">
              Discover a wide range of libraries and find the perfect study space for you.
            </p>
            <motion.button 
              onClick={handleViewAllLibraries}
              className="group bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold py-3 md:py-4 px-6 md:px-8 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 text-base md:text-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              View All Libraries
              <FaChevronRight className="inline-block ml-2 transform group-hover:translate-x-1 transition-transform duration-200" />
            </motion.button>
          </div>
        </motion.section>


        <motion.section 
          className="mb-16 md:mb-32"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true, amount: 0.2 }}
        >
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-8 md:mb-12 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">Featured Library</h2>
          {libraries.length > 0 ? (
            <div className="p-4 md:p-8 transition-shadow duration-300">
              <LibraryCard library={libraries[currentLibraryIndex]} />
            </div>
          ) : (
            <p className="text-center text-gray-400">Loading libraries...</p>
          )}
        </motion.section>

        <motion.section
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true, amount: 0.2 }}
          className="mb-16 md:mb-32"
        >
          <Facilities />
        </motion.section>

        <motion.section
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true, amount: 0.2 }}
          className="mb-16 md:mb-32 mt-16 md:mt-32"
        >
          <PricingAndTiming />
        </motion.section>

        <motion.section 
          className="mb-16 md:mb-32 relative z-10"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true, amount: 0.2 }}
        >
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-8 md:mb-12 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">Why Choose The Library Hub?</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
            {[
              { title: "Thousands of Libraries", description: "Access to a vast network of study spaces" },
              { title: "Flexible Subscriptions", description: "Choose plans that fit your schedule and budget" },
              { title: "Multiple Time Slots", description: "Study when it's convenient for you" },
              { title: "Diverse Study Environments", description: "From quiet zones to collaborative spaces" },
              { title: "Amenities Galore", description: "High-speed Wi-Fi, comfortable seating, and more" },
              { title: "Community of Learners", description: "Connect with like-minded individuals" },
            ].map((feature, index) => (
              <FloatingElement key={index}>
                <motion.div 
                  className="bg-white bg-opacity-5 rounded-lg p-6 md:p-8 shadow-lg hover:shadow-xl transition-shadow duration-300"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  viewport={{ once: true, amount: 0.2 }}
                >
                  <h3 className="text-xl md:text-2xl font-semibold mb-2 md:mb-4">{feature.title}</h3>
                  <p className="text-gray-300 text-base md:text-lg">{feature.description}</p>
                </motion.div>
              </FloatingElement>
            ))}
          </div>
        </motion.section>


        <motion.section 
          className="mb-16 md:mb-32"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true, amount: 0.2 }}
        >
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-6 md:mb-8 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">Ready to Get Started?</h2>
          <div className="text-center">
            <p className="text-lg md:text-xl text-gray-300 mb-6 md:mb-8">Join thousands of students who have found their perfect study space with The Library Hub.</p>
            <motion.button 
              className="group bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold py-3 md:py-4 px-6 md:px-8 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 text-base md:text-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleSignupClick}
            >
              Sign Up Now
              <FaArrowRight className="inline-block ml-2 transform group-hover:translate-x-1 transition-transform duration-200" />
            </motion.button>
          </div>
        </motion.section>

        <motion.section
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true, amount: 0.2 }}
          className="mb-16 md:mb-32"
        >
          <Testimonials />
        </motion.section>

        <motion.section 
        className="mb-16 md:mb-32"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true, amount: 0.2 }}
      >
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-8 md:mb-12 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">Are You a Library Owner?</h2>
        <div className="text-center">
          <p className="text-lg md:text-xl text-gray-300 mb-6 md:mb-8">
            Discover the benefits of joining The Library Hub and expand your reach to more students.
          </p>
          <motion.button 
            onClick={handleLearnMoreClick}
            className="group inline-flex items-center bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold py-3 md:py-4 px-6 md:px-8 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 text-base md:text-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Learn Why You Should Join
            <FaArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform duration-200" />
          </motion.button>
        </div>
        </motion.section>

        <motion.section
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true, amount: 0.2 }}
          className="mb-16 md:mb-32"
        >
          <LibraryCarousel />
        </motion.section>
      </div>

      <Footer />
      <SignupModal isOpen={isSignupModalOpen} onClose={() => setIsSignupModalOpen(false)} />
    </div>
  )
}