import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import NewLibrary from './NewLibrary';

export default function RequestsModal({ isOpen, onClose, onOpenNewLibrary }) {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deletingId, setDeletingId] = useState(null);
  const [showNewLibraryModal, setShowNewLibraryModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);

  useEffect(() => {
    if (isOpen) {
      fetchRequests();
    }
  }, [isOpen]);

  const fetchRequests = async () => {
    try {
      setLoading(true);
      const requestsCollection = collection(db, 'request_lib');
      const requestsSnapshot = await getDocs(requestsCollection);
      const requestsList = requestsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setRequests(requestsList);
    } catch (error) {
      console.error("Error fetching requests:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAdmit = (request) => {
    setSelectedRequest(request);
    setShowNewLibraryModal(true);
  };

  const handleDeleteRequest = async (requestId) => {
    try {
      setDeletingId(requestId);
      await deleteDoc(doc(db, 'request_lib', requestId));
      setRequests(requests.filter(request => request.id !== requestId));
    } catch (error) {
      console.error("Error deleting request:", error);
    } finally {
      setDeletingId(null);
    }
  };

  const handleNewLibraryClose = () => {
    setShowNewLibraryModal(false);
    setSelectedRequest(null);
  };

  const handleNewLibrarySubmit = async (newLibraryData) => {
    await onOpenNewLibrary(newLibraryData);
    await handleDeleteRequest(selectedRequest.id);
    setShowNewLibraryModal(false);
    setSelectedRequest(null);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl">
        <div className="p-6">
          <h2 className="text-3xl font-bold mb-4">Library Requests</h2>
          <p className="text-gray-600 mb-6">Review and admit new library requests.</p>
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-h-[60vh] overflow-y-auto">
              {requests.map((request) => (
                <div key={request.id} className="bg-gray-50 p-6 rounded-lg shadow-md">
                  <h3 className="font-semibold text-xl mb-2">{request.name}</h3>
                  <p className="text-gray-600 mb-1">{request.address}</p>
                  <p className="text-gray-600 mb-1">{request.district}, {request.state}, {request.country}</p>
                  <p className="text-gray-600 mb-1">Email: {request.email}</p>
                  <p className="text-gray-600 mb-4">Phone: {request.phoneNumber}</p>
                  <div className="flex justify-end space-x-2">
                    <button
                      onClick={() => handleAdmit(request)}
                      className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
                    >
                      Admit
                    </button>
                    <button
                      onClick={() => handleDeleteRequest(request.id)}
                      disabled={deletingId === request.id}
                      className={`px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition duration-150 ease-in-out ${deletingId === request.id ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                      {deletingId === request.id ? 'Deleting...' : 'Delete'}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="bg-gray-100 px-6 py-4 flex justify-end">
          <button
            onClick={onClose}
            className="px-6 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
          >
            Close
          </button>
        </div>
      </div>
      {showNewLibraryModal && (
        <NewLibrary
          onClose={handleNewLibraryClose}
          onAddLibrary={handleNewLibrarySubmit}
          initialData={selectedRequest}
        />
      )}
    </div>
  );
}