import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const LibraryCard = ({ library }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/library/${library.id}`);
    };

    return (
        <motion.div
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
            className="bg-white shadow-lg rounded-3xl p-6 m-4 cursor-pointer hover:shadow-2xl transition-shadow duration-300 max-w-5xl mx-auto"
        >
            <div className="flex flex-col md:flex-row items-center">
                <div className="flex-1 mb-6 md:mb-0 md:mr-8">
                    {/* Animated Library Name */}
                    <motion.h3
                        key={library.id} // Ensures animation applies when the library changes
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        className="text-4xl font-bold text-gray-800 mb-2"
                    >
                        {library.name}
                    </motion.h3>

                    <p className="text-lg text-gray-600 mb-4">{library.phoneNumber}</p>
                    <div className="text-md mb-4">
                        <p className="font-semibold text-gray-600">
                            <span className="text-gray-400">Pincode:</span> {library.pincode}
                        </p>
                        <p className="font-semibold text-gray-600">
                            <span className="text-gray-400">Total Seats:</span> {library.totalSeats}
                        </p>
                    </div>
                    <div className="text-center mt-6">
                        {/* View Details Button with Click Event */}
                        <motion.button
                            onClick={handleClick}
                            whileHover={{ scale: 1.1 }}
                            className="bg-gradient-to-r from-green-500 to-teal-600 hover:from-green-400 hover:to-teal-500 text-white py-3 px-10 rounded-full text-lg font-semibold shadow-md"
                        >
                            View Details
                        </motion.button>
                    </div>
                </div>
                <div className="flex-1 bg-gradient-to-r from-blue-900 via-blue-800 to-blue-700 rounded-2xl p-6 text-white shadow-inner">
                    <h4 className="font-semibold mb-4 text-center text-white text-xl">Available Slots</h4>
                    {library.slots?.map((slot, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, x: -50 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: index * 0.1 }}
                            className="mb-4 flex justify-between items-center border-b border-yellow-400 pb-3"
                        >
                            <p className="text-lg">{slot.time}</p>
                            <p className="font-bold text-lg">₹{slot.price}</p>
                        </motion.div>
                    ))}
                </div>
            </div>
        </motion.div>
    );
};

export default LibraryCard;
