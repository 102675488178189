import React, { useEffect, useState } from 'react';
import { FaCrown, FaCalendarAlt, FaClock, FaChair, FaDollarSign, FaInfoCircle, FaStar, FaTimes, FaCommentAlt } from 'react-icons/fa';
import { doc, getDoc, setDoc, collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { db } from '../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = () => {
    const navigate = useNavigate();
    const { currentUser, libraryId } = useAuth();
    const [userData, setUserData] = useState(null);
    const [libraryData, setLibraryData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
    const [rating, setRating] = useState(0);
    const [review, setReview] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [hasReviewed, setHasReviewed] = useState(false);

    useEffect(() => {
        if (!currentUser || !libraryId) {
            console.error("User ID or Library ID is undefined. Redirecting to login.");
            navigate('/login');
            return;
        }

        const fetchData = async () => {
            try {
                setLoading(true);
                const userDocRef = doc(db, 'users', currentUser.uid);
                const libraryDocRef = doc(db, 'libraries', libraryId);

                const [userDoc, libraryDoc] = await Promise.all([
                    getDoc(userDocRef),
                    getDoc(libraryDocRef),
                ]);

                if (userDoc.exists()) {
                    const data = userDoc.data();
                    let lastBillingDate, nextPaymentDate, remainingDays;

                    if (data.lastBillingDate && data.lastBillingDate instanceof Timestamp) {
                        lastBillingDate = data.lastBillingDate.toDate();
                        nextPaymentDate = new Date(lastBillingDate);
                        nextPaymentDate.setDate(nextPaymentDate.getDate() + 30);

                        const today = new Date();
                        remainingDays = Math.max(0, Math.ceil((nextPaymentDate - today) / (1000 * 60 * 60 * 24)));
                    } else {
                        // Fallback to createdAt if lastBillingDate is not available
                        const fallbackDate = data.createdAt ? data.createdAt.toDate() : new Date();
                        lastBillingDate = fallbackDate;
                        nextPaymentDate = new Date(fallbackDate);
                        nextPaymentDate.setDate(nextPaymentDate.getDate() + 30);

                        const today = new Date();
                        remainingDays = Math.max(0, Math.ceil((nextPaymentDate - today) / (1000 * 60 * 60 * 24)));
                    }

                    setUserData({
                        ...data,
                        remainingDays,
                        nextPaymentDate: formatDate(nextPaymentDate),
                        lastBillingDate: formatDate(lastBillingDate),
                    });
                } else {
                    console.error("No such user document!");
                }

                if (libraryDoc.exists()) {
                    setLibraryData(libraryDoc.data());
                } else {
                    console.error("No such library document!");
                }

                // Check if user has already reviewed this library
                const reviewQuery = query(
                    collection(db, 'reviews'),
                    where('userId', '==', currentUser.uid),
                    where('libraryId', '==', libraryId)
                );
                const reviewSnapshot = await getDocs(reviewQuery);
                setHasReviewed(!reviewSnapshot.empty);

            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [currentUser, libraryId, navigate]);

    const formatDate = (date) => {
        return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        }).replace(/\//g, '/');
    };

    const handleSubmitReview = async () => {
        if (rating === 0) {
            toast.error("Please select a rating before submitting.");
            return;
        }

        setSubmitting(true);
        try {
            const reviewData = {
                userId: currentUser.uid,
                libraryId,
                rating,
                review,
                timestamp: new Date().toISOString(),
                libraryName: libraryData.name,
                userName: userData.name,
            };

            const reviewRef = doc(collection(db, 'reviews'));
            await setDoc(reviewRef, reviewData);

            setIsReviewModalOpen(false);
            setRating(0);
            setReview('');
            setHasReviewed(true);
            toast.success("Thank you for your review!");
        } catch (error) {
            console.error("Error submitting review:", error);
            toast.error("Failed to submit review. Please try again.");
        } finally {
            setSubmitting(false);
        }
    };

    if (loading) {
        return <div className="text-white dark:text-gray-200 text-center py-10">Loading...</div>;
    }

    if (!userData || !libraryData) {
        return <div className="text-white dark:text-gray-200 text-center py-10">No data available.</div>;
    }

    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-950 to-blue-900 dark:from-gray-900 dark:to-black py-10 px-4 sm:px-6 lg:px-8">
            <div className="max-w-6xl mx-auto">
                <div className="text-center mb-8">
                    <h1 className="text-3xl sm:text-4xl font-bold text-white dark:text-gray-100">{userData.name}'s Dashboard</h1>
                    <p className="text-lg sm:text-xl mt-4 text-teal-200 dark:text-teal-300">
                        <span className="font-semibold">{libraryData.name}</span>
                    </p>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {/* Current Plan Card */}
                    <div className="bg-gradient-to-r from-green-400 to-blue-500 dark:from-green-600 dark:to-blue-700 text-white shadow-lg rounded-lg p-6">
                        <div className="flex items-center justify-between mb-4">
                            <FaCrown className="text-3xl" />
                            <h2 className="text-xl sm:text-2xl font-semibold">Current Plan</h2>
                        </div>
                        <p className="text-2xl sm:text-3xl font-bold">{userData.currentPlan || 'N/A'}</p>
                    </div>

                    {/* Remaining Days Card */}
                    <div className="bg-gradient-to-r from-purple-500 to-pink-500 dark:from-purple-700 dark:to-pink-700 text-white shadow-lg rounded-lg p-6">
                        <div className="flex items-center justify-between mb-4">
                            <FaCalendarAlt className="text-3xl" />
                            <h2 className="text-xl sm:text-2xl font-semibold">Remaining Days</h2>
                        </div>
                        <p className="text-2xl sm:text-3xl font-bold">{userData.remainingDays || 'N/A'} days</p>
                    </div>

                    {/* Allowed Time Card */}
                    <div className="bg-gradient-to-r from-yellow-500 to-red-500 dark:from-yellow-600 dark:to-red-600 text-white shadow-lg rounded-lg p-6">
                        <div className="flex items-center justify-between mb-4">
                            <FaClock className="text-3xl" />
                            <h2 className="text-xl sm:text-2xl font-semibold">Allowed Time</h2>
                        </div>
                        <p className="text-2xl sm:text-3xl font-bold">{userData.selectedSlot || 'N/A'}</p>
                    </div>

                    {/* Seat Number Card */}
                    <div className="bg-gradient-to-r from-teal-400 to-blue-400 dark:from-teal-600 dark:to-blue-600 text-white shadow-lg rounded-lg p-6">
                        <div className="flex items-center justify-between mb-4">
                            <FaChair className="text-3xl" />
                            <h2 className="text-xl sm:text-2xl font-semibold">Seat Number</h2>
                        </div>
                        <p className="text-2xl sm:text-3xl font-bold">{userData.selectedSeat || 'N/A'}</p>
                    </div>

                    {/* Next Payment Date Card */}
                    <div className="bg-gradient-to-r from-indigo-400 to-purple-500 dark:from-indigo-600 dark:to-purple-700 text-white shadow-lg rounded-lg p-6">
                        <div className="flex items-center justify-between mb-4">
                            <FaDollarSign className="text-3xl" />
                            <h2 className="text-xl sm:text-2xl font-semibold">Next Payment Date</h2>
                        </div>
                        <p className="text-2xl sm:text-3xl font-bold">{userData.nextPaymentDate || 'N/A'}</p>
                    </div>

                    {/* Last Billing Date Card */}
                    <div className="bg-gradient-to-r from-red-400 to-orange-500 dark:from-red-600 dark:to-orange-600 text-white shadow-lg rounded-lg p-6">
                        <div className="flex items-center justify-between mb-4">
                            <FaInfoCircle className="text-3xl" />
                            <h2 className="text-xl sm:text-2xl font-semibold">Last Billing Date</h2>
                        </div>
                        <p className="text-2xl sm:text-3xl font-bold">{userData.lastBillingDate || 'N/A'}</p>
                    </div>
                </div>

                {/* Review Card */}
                {!hasReviewed && (
                    <div className="mt-8 bg-gradient-to-r from-teal-500 to-blue-500 dark:from-teal-700 dark:to-blue-700 text-white shadow-lg rounded-lg p-6 cursor-pointer hover:shadow-xl transition-all duration-300" onClick={() => setIsReviewModalOpen(true)}>
                        <div className="flex items-center justify-between">
                            <div>
                                <h2 className="text-xl sm:text-2xl font-semibold mb-2">Share Your Experience</h2>
                                <p className="text-sm sm:text-base">Help us improve by leaving a review for {libraryData.name}!</p>
                            </div>
                            <FaCommentAlt className="text-4xl" />
                        </div>
                    </div>
                )}

                {/* Custom Review Modal */}
                {isReviewModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 dark:bg-opacity-70 flex items-center justify-center z-50 p-4">
                        <div className="bg-white dark:bg-gray-800 rounded-lg p-6 sm:p-8 max-w-md w-full">
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-xl sm:text-2xl font-semibold text-gray-900 dark:text-gray-100">Review {libraryData.name}</h3>
                                <button 
                                    onClick={() => setIsReviewModalOpen(false)}
                                    className="text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-200 transition-colors duration-200"
                                >
                                    <FaTimes size={24} />
                                </button>
                            </div>
                            <div className="mb-4">
                                <p className="text-sm text-gray-600 dark:text-gray-400">{libraryData.address}</p>
                            </div>
                            <div className="mb-6">
                                <div className="flex items-center mb-4">
                                    {[1, 2, 3, 4, 5].map((star) => (
                                        <FaStar
                                            key={star}
                                            className={`cursor-pointer transition-colors duration-200 ${
                                                star <= rating ? 'text-yellow-400' : 'text-gray-300 dark:text-gray-600'
                                            } text-3xl sm:text-4xl mr-1`}
                                            onClick={() => setRating(star)}
                                        />
                                    ))}
                                </div>
                                <textarea
                                    className="w-full p-3 border border-gray-300 dark:border-gray-600 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none transition-shadow duration-200 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                                    rows={4}
                                    placeholder="Write your review here..."
                                    value={review}
                                    onChange={(e) => setReview(e.target.value)}
                                ></textarea>
                            </div>
                            <div className="flex justify-end space-x-3">
                                <button
                                    className="px-4 py-2 bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-gray-200 rounded-md hover:bg-gray-300 dark:hover:bg-gray-500 transition-colors duration-200"
                                    onClick={() => setIsReviewModalOpen(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className={`px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200 ${
                                        submitting ?   'opacity-50 cursor-not-allowed' : ''
                                    }`}
                                    onClick={handleSubmitReview}
                                    disabled={submitting}
                                >
                                    {submitting ? 'Submitting...' : 'Submit Review'}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <ToastContainer 
                position="bottom-right" 
                autoClose={3000} 
                theme="colored"
                toastClassName="dark:bg-gray-800 dark:text-white"
            />
        </div>
    );
};

export default Dashboard;