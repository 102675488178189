import React, { useState, useEffect, useCallback } from 'react';
import { FaPhoneAlt, FaEnvelope, FaCalendar, FaClock, FaChair, FaMoneyBillWave, FaTimes, FaHistory, FaChevronDown, FaChevronUp, FaBirthdayCake } from 'react-icons/fa';
import InvoiceGenerator from './InvoiceGenerator';
import IdCardGenerator from './IdCardGenerator';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

export default function UserDetailsModal({ isOpen, onClose, user, libraryId }) {
  const [userHistory, setUserHistory] = useState(null);
  const [libraryNames, setLibraryNames] = useState({});
  const [showLibraryHistory, setShowLibraryHistory] = useState(false);

  const fetchUserHistory = useCallback(async () => {
    if (user && user.id) {
      const userHistoryRef = doc(db, 'userHistory', user.id);
      const userHistoryDoc = await getDoc(userHistoryRef);
      if (userHistoryDoc.exists()) {
        setUserHistory(userHistoryDoc.data());
      }
    }
  }, [user]);

  const fetchLibraryNames = useCallback(async () => {
    if (userHistory) {
      const libraryIds = [
        ...(userHistory.currentLibrary ? [userHistory.currentLibrary.libraryId] : []),
        ...userHistory.previousLibraries.map(lib => lib.libraryId)
      ];
      
      const names = {};
      for (const id of libraryIds) {
        const libraryRef = doc(db, 'libraries', id);
        const libraryDoc = await getDoc(libraryRef);
        if (libraryDoc.exists()) {
          names[id] = libraryDoc.data().name;
        }
      }
      setLibraryNames(names);
    }
  }, [userHistory]);

  const toggleLibraryHistory = useCallback(async () => {
    if (!showLibraryHistory && !userHistory) {
      await fetchUserHistory();
    }
    setShowLibraryHistory(prev => !prev);
  }, [showLibraryHistory, userHistory, fetchUserHistory]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
  };

  useEffect(() => {
    if (showLibraryHistory && userHistory && Object.keys(libraryNames).length === 0) {
      fetchLibraryNames();
    }
  }, [showLibraryHistory, userHistory, libraryNames, fetchLibraryNames]);

  if (!isOpen || !user) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 dark:bg-opacity-70 flex justify-center items-center z-1000 p-4">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">{user.name}'s Details</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200">
            <FaTimes size={24} />
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-4">
            <div className="flex items-center gap-3">
              <FaPhoneAlt className="text-blue-500 dark:text-blue-400" />
              <span className="text-gray-700 dark:text-gray-300">{user.phoneNumber || 'N/A'}</span>
            </div>
            <div className="flex items-center gap-3">
              <FaEnvelope className="text-blue-500 dark:text-blue-400" />
              <span className="text-gray-700 dark:text-gray-300">{user.email}</span>
            </div>
            <div className="flex items-center gap-3">
              <FaBirthdayCake className="text-blue-500 dark:text-blue-400" />
              <span className="text-gray-700 dark:text-gray-300">DOB: {user.dob || 'N/A'}</span>
            </div>
            <div className="flex items-center gap-3">
              <FaCalendar className="text-blue-500 dark:text-blue-400" />
              <span className="text-gray-700 dark:text-gray-300">Joined: {user.joinDate}</span>
            </div>
            <div className="flex items-center gap-3">
              <FaClock className="text-blue-500 dark:text-blue-400" />
              <span className="text-gray-700 dark:text-gray-300">Slot: {user.selectedSlot || 'N/A'}</span>
            </div>
            <div className="flex items-center gap-3">
              <FaChair className="text-blue-500 dark:text-blue-400" />
              <span className="text-gray-700 dark:text-gray-300">Seat: {user.selectedSeat || 'Not selected'}</span>
            </div>
            <div className="flex items-center gap-3">
              <FaMoneyBillWave className="text-blue-500 dark:text-blue-400" />
              <span className="text-gray-700 dark:text-gray-300">Last Billing: {user.lastBillingDate || 'N/A'}</span>
            </div>
          </div>
          <div>
            <h3 className="font-semibold mb-3 text-gray-900 dark:text-white">Billing Cycles:</h3>
            <ul className="space-y-2 max-h-40 overflow-y-auto">
              {user.billingCycles && user.billingCycles.map((cycle, index) => (
                <li key={index} className="flex justify-between items-center bg-gray-100 dark:bg-gray-700 p-2 rounded">
                  <span className="text-gray-700 dark:text-gray-300">{cycle.startDate} - {cycle.endDate}</span>
                  <span className={`px-2 py-1 rounded-full text-xs ${
                    cycle.status === 'Paid' ? 'bg-green-200 text-green-800 dark:bg-green-900 dark:text-green-200' :
                    cycle.status === 'Unpaid' ? 'bg-yellow-200 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200' :
                    'bg-red-200 text-red-800 dark:bg-red-900 dark:text-red-200'
                  }`}>
                    {cycle.status}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="mt-6">
          <button
            onClick={toggleLibraryHistory}
            className="flex items-center gap-2 text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300 transition-colors"
          >
            <FaHistory />
            {showLibraryHistory ? 'Hide Library History' : 'Show Library History'}
            {showLibraryHistory ? <FaChevronUp /> : <FaChevronDown />}
          </button>
          {showLibraryHistory && userHistory && (
            <div className="mt-4 space-y-4">
              {userHistory.currentLibrary && (
                <div className="bg-blue-100 dark:bg-blue-900 p-4 rounded-lg">
                  <h4 className="font-medium text-blue-800 dark:text-blue-200">Current Library</h4>
                  <p className="text-gray-700 dark:text-gray-300">{libraryNames[userHistory.currentLibrary.libraryId] || 'Unknown Library'}</p>
                  <p className="text-sm text-gray-600 dark:text-gray-400">Joined: {formatDate(userHistory.currentLibrary.joinDate)}</p>
                </div>
              )}
              {userHistory.previousLibraries && userHistory.previousLibraries.length > 0 && (
                <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
                  <h4 className="font-medium text-gray-800 dark:text-gray-200 mb-2">Previous Libraries</h4>
                  <ul className="space-y-3">
                    {userHistory.previousLibraries.map((lib, index) => (
                      <li key={index} className="bg-white dark:bg-gray-600 p-3 rounded-md shadow-sm">
                        <p className="font-medium text-gray-700 dark:text-gray-300">{libraryNames[lib.libraryId] || 'Unknown Library'}</p>
                        <p className="text-sm text-gray-600 dark:text-gray-400">Joined: {formatDate(lib.joinDate)}</p>
                        <p className="text-sm text-gray-600 dark:text-gray-400">Left: {formatDate(lib.exitDate)}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex justify-between items-center mt-6">
          <div className="flex space-x-4">
            <InvoiceGenerator user={user} libraryId={libraryId} />
            <IdCardGenerator user={user} libraryId={libraryId} />
          </div>
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-200 rounded hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}