import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaHome, FaInfoCircle, FaSignInAlt, FaUser, FaLock, FaSignOutAlt, FaBars, FaTimes, FaMoon, FaSun } from 'react-icons/fa';
import { getAuth, signOut } from 'firebase/auth';
import { useAuth } from './AuthContext';
import { useDarkMode } from './DarkModeContext';

export default function Navbar() {
  const { currentUser, role } = useAuth() || {};
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const darkModeContext = useDarkMode();

  const handleLogout = async () => {
    try {
      await signOut(getAuth());
      setShowLogoutConfirm(false);
      setIsMenuOpen(false);
      navigate('/');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const NavLinks = ({ onClick = () => {} }) => (
    <>
      <Link to="/" className="text-white flex items-center hover:text-yellow-300 transition-colors duration-200" onClick={onClick}>
        <FaHome className="mr-2" /> Home
      </Link>
      <Link to="/about" className="text-white flex items-center hover:text-yellow-300 transition-colors duration-200" onClick={onClick}>
        <FaInfoCircle className="mr-2" /> About
      </Link>
      {currentUser ? (
        <>
          {role !== 'admin' && role !== 'owner' && (
            <Link to="/dashboard" className="text-white flex items-center hover:text-yellow-300 transition-colors duration-200" onClick={onClick}>
              <FaUser className="mr-2" /> Account
            </Link>
          )}
          {role === 'admin' && (
            <Link to="/admin-dashboard" className="text-white flex items-center hover:text-yellow-300 transition-colors duration-200" onClick={onClick}>
              <FaLock className="mr-2" /> Admin
            </Link>
          )}
          {role === 'owner' && (
            <Link to="/owner-dashboard" className="text-white flex items-center hover:text-yellow-300 transition-colors duration-200" onClick={onClick}>
              <FaLock className="mr-2" /> Owner
            </Link>
          )}
          <button 
            onClick={() => {
              setShowLogoutConfirm(true);
              onClick();
            }}
            className="text-white flex items-center hover:text-yellow-300 transition-colors duration-200 focus:outline-none"
          >
            <FaSignOutAlt className="mr-2" /> Logout
          </button>
        </>
      ) : (
        <Link to="/login" className="text-white flex items-center hover:text-yellow-300 transition-colors duration-200" onClick={onClick}>
          <FaSignInAlt className="mr-2" /> Login
        </Link>
      )}
      {darkModeContext && location.pathname !== '/' && (
        <button
          onClick={() => {
            darkModeContext.toggleDarkMode();
            onClick();
          }}
          className="text-white flex items-center hover:text-yellow-300 transition-colors duration-200 focus:outline-none"
          aria-label={darkModeContext.darkMode ? "Switch to light mode" : "Switch to dark mode"}
        >
          {darkModeContext.darkMode ? <FaSun className="mr-2" /> : <FaMoon className="mr-2" />}
          {darkModeContext.darkMode ? 'Light Mode' : 'Dark Mode'}
        </button>
      )}
    </>
  );

  return (
    <nav className={`bg-gradient-to-r from-teal-400 via-blue-500 to-indigo-600 text-white p-4 shadow-lg ${darkModeContext && darkModeContext.darkMode ? 'dark' : ''}`}>
      <div className="container mx-auto flex flex-wrap justify-between items-center">
        <Link to="/" className="text-white text-lg font-bold mb-2 md:mb-0">
          The Library Hub
        </Link>
        <button
          className="md:hidden text-white focus:outline-none"
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>
        <div className={`w-full md:w-auto md:flex ${isMenuOpen ? 'block' : 'hidden'}`}>
          <div className="flex flex-col md:flex-row md:items-center md:space-x-4 space-y-2 md:space-y-0 mt-2 md:mt-0">
            <NavLinks onClick={() => setIsMenuOpen(false)} />
          </div>
        </div>
      </div>

      {showLogoutConfirm && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className={`${darkModeContext && darkModeContext.darkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'} p-6 rounded-lg shadow-lg`}>
            <h2 className="text-xl font-semibold mb-4">Confirm Logout</h2>
            <p className="mb-6">Are you sure you want to log out?</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowLogoutConfirm(false)}
                className={`px-4 py-2 ${darkModeContext && darkModeContext.darkMode ? 'bg-gray-600 text-white' : 'bg-gray-300 text-gray-700'} rounded-md hover:bg-opacity-80 transition-colors duration-200`}
              >
                Cancel
              </button>
              <button
                onClick={handleLogout}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors duration-200"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}