import React from 'react';

const LoadingAnimation = () => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <video
        src="library-hub\public\Animation.webm"
        autoPlay
        loop
        muted
        className="w-24 h-24" 
      />
    </div>
  );
};

export default LoadingAnimation;
