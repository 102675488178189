import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaUniversity } from 'react-icons/fa';

const testimonials = [
    {
        name: 'Homant Kaushik',
        college: 'mitthu institue of technology (MIT)',
        testimonial: 'The digital library has been a game-changer for my studies. The peaceful environment and 24/7 access to resources have made my learning experience exceptional. The facilities are top-notch, and I highly recommend it to anyone serious about their education!',
        image: 'https://via.placeholder.com/150',
    },
    {
        name: 'Jane Smith',
        college: 'Stanford University',
        testimonial: 'I love studying at this digital library. The high-speed WiFi and air-conditioned halls create the perfect study environment. The staff is friendly, and the cleanliness is always maintained. This is the best place to focus and achieve academic success.',
        image: 'https://via.placeholder.com/150',
    },
    {
        name: 'Michael Brown',
        college: 'MIT',
        testimonial: 'The digital library offers a fantastic learning environment. The fully air-conditioned halls and clean facilities make long study sessions comfortable. The supportive community and reliable power backup are added bonuses that keep me coming back every day.',
        image: 'https://via.placeholder.com/150',
    },
];

const Testimonial = () => {
    const [isPaused, setIsPaused] = useState(false);

    return (
        <div className="relative overflow-hidden mt-12 sm:mt-16 md:mt-24 mb-8 sm:mb-10 text-center px-4 sm:px-6 lg:px-8">
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 sm:mb-6 md:mb-8 text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-indigo-600">
                Our Happy Learners
            </h1>
            <div className="overflow-hidden">
                <motion.div
                    className="flex space-x-3 sm:space-x-4 md:space-x-6"
                    initial={{ x: '100%' }}
                    animate={{ x: isPaused ? '0%' : '-100%' }}
                    transition={{
                        ease: "linear",
                        duration: 20,
                        repeat: Infinity,
                    }}
                    onHoverStart={() => setIsPaused(true)}
                    onHoverEnd={() => setIsPaused(false)}
                >
                    {[...testimonials, ...testimonials].map((testimonial, index) => (
                        <div
                            key={index}
                            className="bg-gradient-to-r from-purple-500 to-indigo-500 text-white p-3 sm:p-4 md:p-6 rounded-lg shadow-lg min-w-[220px] sm:min-w-[260px] md:min-w-[300px] max-w-[280px] sm:max-w-[320px] md:max-w-md flex-shrink-0 mt-4 sm:mt-6 md:mt-10"
                        >
                            <div className="flex flex-col sm:flex-row items-center mb-2 sm:mb-4">
                                <img
                                    src={testimonial.image}
                                    alt={testimonial.name}
                                    className="w-12 h-12 sm:w-16 sm:h-16 md:w-20 md:h-20 rounded-full mb-2 sm:mb-0"
                                />
                                <div className="sm:ml-3 md:ml-4 text-center sm:text-left">
                                    <h2 className="text-lg sm:text-xl md:text-2xl font-bold">{testimonial.name}</h2>
                                    <p className="flex items-center justify-center sm:justify-start text-xs sm:text-sm mt-1">
                                        <FaUniversity className="mr-1 sm:mr-2" />
                                        {testimonial.college}
                                    </p>
                                </div>
                            </div>
                            <p className="text-xs sm:text-sm mt-2 sm:mt-4 line-clamp-4 sm:line-clamp-none">{testimonial.testimonial}</p>
                        </div>
                    ))}
                </motion.div>
            </div>
        </div>
    );
};

export default Testimonial;