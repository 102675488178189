import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { FaTimes, FaSearch, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { ScaleLoader } from 'react-spinners';
import { useDarkMode } from './DarkModeContext';

export default function Component({ onClose, libraryId }) {
  const [alumni, setAlumni] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [alumniPerPage] = useState(10);
  const { darkMode } = useDarkMode();

  useEffect(() => {
    const fetchAlumni = async () => {
      try {
        setLoading(true);
        const userHistoryRef = collection(db, 'userHistory');
        const querySnapshot = await getDocs(userHistoryRef);
        
        const alumniPromises = querySnapshot.docs.map(async (historyDoc) => {
          const historyData = historyDoc.data();
          const previousLibrary = historyData.previousLibraries?.find(lib => lib.libraryId === libraryId);
          
          if (!previousLibrary) return null;

          const userDocRef = doc(db, 'users', historyData.userId);
          const userDocSnap = await getDoc(userDocRef);
          const userData = userDocSnap.data();

          if (!userData) return null;

          return {
            id: historyDoc.id,
            name: userData.name || 'N/A',
            phoneNumber: userData.phoneNumber || 'N/A',
            joinDate: previousLibrary.joinDate || 'N/A',
            exitDate: previousLibrary.exitDate || 'N/A',
            billingStatus: previousLibrary.billingStatus || {}
          };
        });

        const alumniData = (await Promise.all(alumniPromises)).filter(Boolean);
        setAlumni(alumniData);
      } catch (error) {
        console.error("Error fetching alumni:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAlumni();
  }, [libraryId]);

  const filteredAlumni = alumni.filter(alum => 
    alum.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    alum.phoneNumber.includes(searchTerm)
  );

  const indexOfLastAlumni = currentPage * alumniPerPage;
  const indexOfFirstAlumni = indexOfLastAlumni - alumniPerPage;
  const currentAlumni = filteredAlumni.slice(indexOfFirstAlumni, indexOfLastAlumni);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (dateValue) => {
    if (dateValue === 'N/A') return 'N/A';
    let date;
    if (typeof dateValue === 'string') {
      if (dateValue.includes('at')) {
        date = new Date(dateValue.split(' at ')[0]);
      } else {
        date = new Date(dateValue);
      }
    } else if (dateValue && dateValue.toDate) {
      // Handle Firestore Timestamp
      date = dateValue.toDate();
    } else if (dateValue && typeof dateValue.getTime === 'function') {
      date = dateValue;
    } else {
      return 'Invalid Date';
    }
    return date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
  };

  const getLastBillingStatus = (billingStatus) => {
    const entries = Object.entries(billingStatus);
    return entries.length > 0 ? entries[entries.length - 1][1] : 'N/A';
  };

  return (
    <div className={`fixed inset-0 z-50 overflow-auto ${darkMode ? 'bg-gray-900 bg-opacity-75' : 'bg-black bg-opacity-50'}`}>
      <div className={`relative p-4 sm:p-6 md:p-8 w-full max-w-4xl mx-auto my-4 sm:my-8 ${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-lg`}>
        <button
          onClick={onClose}
          className={`absolute top-2 right-2 sm:top-4 sm:right-4 ${darkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-black'}`}
        >
          <FaTimes size={24} />
        </button>
        <h2 className={`text-xl sm:text-2xl font-bold mb-4 sm:mb-6 ${darkMode ? 'text-white' : 'text-gray-800'}`}>Alumni</h2>
        
        <div className="mb-4 relative">
          <input
            type="text"
            placeholder="Search alumni..."
            className={`w-full p-2 pl-10 border rounded-md ${darkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-gray-100 text-black border-gray-300'}`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FaSearch className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`} />
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <ScaleLoader color={darkMode ? "#ffffff" : "#000000"} />
          </div>
        ) : alumni.length === 0 ? (
          <div className={`text-center py-8 ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            No alumni found for this library.
          </div>
        ) : (
          <>
            <div className={`overflow-x-auto ${darkMode ? 'bg-gray-700' : 'bg-white'} rounded-lg shadow`}>
              <table className="min-w-full">
                <thead className={darkMode ? 'bg-gray-800' : 'bg-gray-200'}>
                  <tr>
                    <th className="py-2 px-3 sm:py-3 sm:px-4 text-left text-xs sm:text-sm">Name</th>
                    <th className="py-2 px-3 sm:py-3 sm:px-4 text-left text-xs sm:text-sm">Phone</th>
                    <th className="py-2 px-3 sm:py-3 sm:px-4 text-left text-xs sm:text-sm">Join Date</th>
                    <th className="py-2 px-3 sm:py-3 sm:px-4 text-left text-xs sm:text-sm">Exit Date</th>
                    <th className="py-2 px-3 sm:py-3 sm:px-4 text-left text-xs sm:text-sm">Last Billing</th>
                  </tr>
                </thead>
                <tbody>
                  {currentAlumni.map((alum) => (
                    <tr key={alum.id} className={`border-b ${darkMode ? 'border-gray-600 hover:bg-gray-600' : 'border-gray-200 hover:bg-gray-100'}`}>
                      <td className="py-2 px-3 sm:py-3 sm:px-4 text-xs sm:text-sm">{alum.name}</td>
                      <td className="py-2 px-3 sm:py-3 sm:px-4 text-xs sm:text-sm">{alum.phoneNumber}</td>
                      <td className="py-2 px-3 sm:py-3 sm:px-4 text-xs sm:text-sm">{formatDate(alum.joinDate)}</td>
                      <td className="py-2 px-3 sm:py-3 sm:px-4 text-xs sm:text-sm">{formatDate(alum.exitDate)}</td>
                      <td className="py-2 px-3 sm:py-3 sm:px-4 text-xs sm:text-sm">{getLastBillingStatus(alum.billingStatus)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="mt-4 flex flex-col sm:flex-row justify-between items-center">
              <div className={`text-xs sm:text-sm mb-2 sm:mb-0 ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                Showing {indexOfFirstAlumni + 1} to {Math.min(indexOfLastAlumni, filteredAlumni.length)} of {filteredAlumni.length} alumni
              </div>
              <div className="flex">
                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`mx-1 px-2 sm:px-3 py-1 rounded text-xs sm:text-sm ${
                    darkMode
                      ? 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  } ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  <FaChevronLeft />
                </button>
                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={indexOfLastAlumni >= filteredAlumni.length}
                  className={`mx-1 px-2 sm:px-3 py-1 rounded text-xs sm:text-sm ${
                    darkMode
                      ? 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  } ${indexOfLastAlumni >= filteredAlumni.length ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  <FaChevronRight />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}