import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { FaTimes, FaCheck, FaTrash } from 'react-icons/fa';
import NewStudent from './NewStudent';
import { useDarkMode } from './DarkModeContext';

const RequestsViewer = ({ onClose, libraryId, onApproveRequest }) => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showNewStudentForm, setShowNewStudentForm] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const { darkMode } = useDarkMode();

  useEffect(() => {
    const fetchRequests = async () => {
      setLoading(true);
      setError(null);
      try {
        const q = query(collection(db, 'requests_s'), where('libraryId', '==', libraryId), where('status', '==', 'pending'));
        const querySnapshot = await getDocs(q);
        const requestsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setRequests(requestsData);
      } catch (err) {
        console.error('Error fetching requests:', err);
        setError('Failed to load requests. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, [libraryId]);

  const handleApprove = async (request) => {
    setSelectedRequest(request);
    setShowNewStudentForm(true);
  };

  const handleDecline = async (requestId) => {
    try {
      await deleteDoc(doc(db, 'requests_s', requestId));
      setRequests(requests.filter(r => r.id !== requestId));
    } catch (error) {
      console.error('Error declining request:', error);
      setError('Failed to decline request. Please try again.');
    }
  };

  const handleAddStudent = async (newStudent) => {
    try {
      // Add the new student to the users collection
      await onApproveRequest(newStudent);

      // Delete the request from the requests_s collection
      await deleteDoc(doc(db, 'requests_s', selectedRequest.id));

      // Update the local state
      setRequests(requests.filter(r => r.id !== selectedRequest.id));

      setShowNewStudentForm(false);
      setSelectedRequest(null);
    } catch (error) {
      console.error('Error approving request:', error);
      setError('Failed to approve request. Please try again.');
    }
  };

  if (loading) {
    return (
      <div className={`fixed inset-0 ${darkMode ? 'bg-gray-900' : 'bg-black'} bg-opacity-50 flex items-center justify-center z-50`}>
        <div className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'} p-6 rounded-lg shadow-xl`}>
          <p className="text-lg font-semibold">Loading requests...</p>
        </div>
      </div>
    );
  }

  return (
    <div className={`fixed inset-0 ${darkMode ? 'bg-gray-900' : 'bg-black'} bg-opacity-50 flex items-center justify-center z-50`}>
      <div className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'} rounded-lg shadow-xl p-4 sm:p-6 w-full max-w-4xl max-h-[90vh] overflow-y-auto`}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl sm:text-2xl font-bold">Pending Requests</h2>
          <button onClick={onClose} className={`${darkMode ? 'text-gray-300 hover:text-gray-100' : 'text-gray-500 hover:text-gray-700'}`}>
            <FaTimes size={24} />
          </button>
        </div>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {requests.length === 0 ? (
          <p>No pending requests.</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className={`${darkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                  <th className="text-left p-2">Name</th>
                  <th className="text-left p-2 hidden sm:table-cell">Email</th>
                  <th className="text-left p-2 hidden md:table-cell">Phone</th>
                  <th className="text-left p-2">Slot</th>
                  <th className="text-left p-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {requests.map((request) => (
                  <tr key={request.id} className={`border-b ${darkMode ? 'border-gray-700' : 'border-gray-200'}`}>
                    <td className="p-2">{request.name}</td>
                    <td className="p-2 hidden sm:table-cell">{request.email}</td>
                    <td className="p-2 hidden md:table-cell">{request.phoneNumber}</td>
                    <td className="p-2">{request.selectedSlot}</td>
                    <td className="p-2">
                      <button
                        onClick={() => handleApprove(request)}
                        className={`mr-2 ${darkMode ? 'text-green-400 hover:text-green-300' : 'text-green-600 hover:text-green-800'}`}
                        aria-label="Approve"
                      >
                        <FaCheck />
                      </button>
                      <button
                        onClick={() => handleDecline(request.id)}
                        className={`${darkMode ? 'text-red-400 hover:text-red-300' : 'text-red-600 hover:text-red-800'}`}
                        aria-label="Decline"
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {showNewStudentForm && (
        <NewStudent
          onClose={() => setShowNewStudentForm(false)}
          onAddStudent={handleAddStudent}
          libraryId={libraryId}
          approvedRequest={selectedRequest}
        />
      )}
    </div>
  );
};

export default RequestsViewer;