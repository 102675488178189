import React, { useState, useEffect } from 'react';
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import dayjs from 'dayjs';
import { FaTimes } from 'react-icons/fa';

const LogsViewer = ({ onClose, libraryId }) => {
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        fetchLogs();
    }, []);

    const fetchLogs = async () => {
        try {
            const logsCollection = collection(db, 'logs');
            const logsQuery = query(
                logsCollection, 
                where('libraryId', '==', libraryId),
                orderBy('timestamp', 'desc'),
                limit(50)
            );
            const logsSnapshot = await getDocs(logsQuery);
            const logsList = logsSnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    ...data,
                    timestamp: dayjs(data.timestamp.toDate()).format('DD-MM-YYYY HH:mm:ss'),
                    adminEmail: data.adminEmail || 'N/A', 
                    userEmail: data.userEmail || 'N/A', 
                };
            });
            setLogs(logsList);
        } catch (error) {
            console.error("Error fetching logs:", error);
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 dark:bg-opacity-70 flex justify-center items-center p-4">
            <div className="relative bg-white dark:bg-gray-800 p-4 sm:p-6 rounded-lg shadow-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 text-gray-800 dark:text-white">Activity Logs</h2>
                <button 
                    className="absolute top-2 right-2 sm:top-4 sm:right-4 text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300 transition-colors"
                    onClick={onClose}
                >
                    <FaTimes size={24} />
                </button>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-lg shadow-md">
                        <thead className="bg-gray-100 dark:bg-gray-600">
                            <tr>
                                <th className="py-2 px-4 border-b dark:border-gray-500 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Admin Email</th>
                                <th className="py-2 px-4 border-b dark:border-gray-500 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Action</th>
                                <th className="py-2 px-4 border-b dark:border-gray-500 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Name</th>
                                <th className="py-2 px-4 border-b dark:border-gray-500 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">User Email</th>
                                <th className="py-2 px-4 border-b dark:border-gray-500 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Timestamp</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 dark:divide-gray-500">
                            {logs.map(log => (
                                <tr key={log.id} className="hover:bg-gray-50 dark:hover:bg-gray-600 transition-colors">
                                    <td className="py-2 px-4 text-sm text-gray-900 dark:text-gray-100">{log.adminEmail}</td>
                                    <td className="py-2 px-4 text-sm text-gray-900 dark:text-gray-100">{log.action}</td>
                                    <td className="py-2 px-4 text-sm text-gray-900 dark:text-gray-100">{log.userName}</td>
                                    <td className="py-2 px-4 text-sm text-gray-900 dark:text-gray-100">{log.userEmail}</td>
                                    <td className="py-2 px-4 text-sm text-gray-900 dark:text-gray-100">{log.timestamp}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default LogsViewer;