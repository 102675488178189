import React, { useState, useEffect } from 'react';
import { FaClock, FaRupeeSign } from 'react-icons/fa';
import JoinForm from './JoinForm';
import { db } from '../firebase';
import { collection, query, getDocs } from 'firebase/firestore';

const PricingAndTiming = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState('');
    const [libraries, setLibraries] = useState([]);
    const [selectedLibrary, setSelectedLibrary] = useState(null);
    const [vacantSeats, setVacantSeats] = useState([]);

    useEffect(() => {
        const fetchLibraries = async () => {
            const q = query(collection(db, 'libraries'));
            const querySnapshot = await getDocs(q);
            const fetchedLibraries = [];
            querySnapshot.forEach((doc) => {
                fetchedLibraries.push({ id: doc.id, ...doc.data() });
            });
            setLibraries(fetchedLibraries);
            if (fetchedLibraries.length > 0) {
                setSelectedLibrary(fetchedLibraries[0]);
            }
        };
        fetchLibraries();
    }, []);

    useEffect(() => {
        if (selectedLibrary) {
            const calculateVacantSeats = () => {
                const totalSeats = selectedLibrary.totalSeats;
                const occupiedSeats = selectedLibrary.occupiedSeats || [];
                const vacantSeats = [];
                for (let i = 1; i <= totalSeats; i++) {
                    if (!occupiedSeats.includes(i)) {
                        vacantSeats.push(i);
                    }
                }
                setVacantSeats(vacantSeats);
            };
            calculateVacantSeats();
        }
    }, [selectedLibrary]);

    const openModal = (slot) => {
        setSelectedSlot(slot);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form submitted");
        closeModal();
    };

    const handleLibraryChange = (e) => {
        const selectedId = e.target.value;
        const library = libraries.find(lib => lib.id === selectedId);
        setSelectedLibrary(library);
    };

    return (
        <div className="max-w-5xl mx-auto p-4 sm:p-6 lg:p-8 mt-10 sm:mt-20 bg-gradient-to-r from-red-200 via-orange-200 to-yellow-200 rounded-lg shadow-lg border border-red-300">
            <h2 className="text-3xl sm:text-4xl font-extrabold text-center text-gray-800 mb-6 sm:mb-8">Library Timing & Pricing</h2>
            
            {libraries.length > 0 && (
                <div className="mb-6 sm:mb-8 flex justify-center">
                    <select
                        onChange={handleLibraryChange}
                        value={selectedLibrary?.id || ''}
                        className="w-full sm:w-auto px-4 sm:px-6 py-2 sm:py-3 border border-gray-300 rounded-full shadow-sm text-gray-700 font-bold bg-white focus:outline-none focus:ring-2 focus:ring-yellow-400 hover:border-yellow-400 transition-colors duration-300 ease-in-out"
                    >
                        {libraries.map((library) => (
                            <option key={library.id} value={library.id}>
                                {library.name}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            {selectedLibrary && (
                <div className="space-y-4 sm:space-y-6">
                    {selectedLibrary.slots?.map((slot, index) => (
                        <div
                            key={index}
                            className={`flex flex-col sm:flex-row items-center justify-between p-4 sm:p-6 bg-white border-l-4 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 ${
                                index === 0
                                    ? 'border-blue-600'
                                    : index === 1
                                    ? 'border-green-600'
                                    : index === 2
                                    ? 'border-yellow-600'
                                    : 'border-gray-600'
                            }`}
                        >
                            <div className="flex items-center mb-4 sm:mb-0">
                                <FaClock className={`text-2xl sm:text-3xl mr-4 sm:mr-6 ${
                                    index === 0
                                        ? 'text-blue-600'
                                        : index === 1
                                        ? 'text-green-600'
                                        : index === 2
                                        ? 'text-yellow-600'
                                        : 'text-gray-600'
                                }`} />
                                <div>
                                    <h3 className="text-xl sm:text-2xl font-semibold text-gray-800">{slot.time}</h3>
                                    <p className="text-lg sm:text-xl font-medium text-gray-600">
                                        <FaRupeeSign className="inline text-yellow-500" /> {slot.price} INR
                                    </p>
                                </div>
                            </div>
                            <button
                                onClick={() => openModal(slot.time)}
                                className={`w-full sm:w-auto px-4 sm:px-6 py-2 sm:py-3 rounded-lg shadow-md transform hover:scale-105 hover:shadow-lg transition-transform duration-300 ease-in-out font-semibold tracking-wide text-white ${
                                    index === 0
                                        ? 'bg-blue-600 hover:bg-blue-700 active:bg-blue-800 focus:ring-2 focus:ring-blue-400'
                                        : index === 1
                                        ? 'bg-green-600 hover:bg-green-700 active:bg-green-800 focus:ring-2 focus:ring-green-400'
                                        : index === 2
                                        ? 'bg-yellow-600 hover:bg-yellow-700 active:bg-yellow-800 focus:ring-2 focus:ring-yellow-400'
                                        : 'bg-gray-600 hover:bg-gray-700 active:bg-gray-800 focus:ring-2 focus:ring-gray-400'
                                }`}
                            >
                                Join Now
                            </button>
                        </div>
                    ))}
                </div>
            )}

            {isModalOpen && (
                <JoinForm
                    selectedSlot={selectedSlot}
                    closeModal={closeModal}
                    handleSubmit={handleSubmit}
                    vacantSeats={vacantSeats}
                    libraryId={selectedLibrary?.id || ''}
                    libraryName={selectedLibrary?.name || ''}
                />
            )}
        </div>
    );
};

export default PricingAndTiming;