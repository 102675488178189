import React, { useState, useEffect } from 'react'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../firebase'
import { useAuth } from './AuthContext'
import { toast } from 'react-toastify'

export default function EditStudent({ userId, onClose, onUpdateStudent = () => {} }) {
  const { libraryId } = useAuth()
  const [currentPage, setCurrentPage] = useState(1)
  const [studentData, setStudentData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    selectedSlot: '',
    selectedSeat: '',
    joinDate: '',
    goal: '',
    address: '',
    college: '',
  })
  const [previousSeat, setPreviousSeat] = useState('')
  const [previousSlot, setPreviousSlot] = useState('')
  const [seatStats, setSeatStats] = useState({
    slots: {},
    totalSeats: 0
  })
  const [slots, setSlots] = useState([])
  const [availableSeats, setAvailableSeats] = useState([])
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const studentDoc = await getDoc(doc(db, 'users', userId))
        if (studentDoc.exists()) {
          const student = studentDoc.data()
          setStudentData(student)
          setPreviousSeat(student.selectedSeat)
          setPreviousSlot(student.selectedSlot)
        } else {
          setErrorMessage("Student not found")
        }

        const libraryDoc = await getDoc(doc(db, 'libraries', libraryId))
        if (libraryDoc.exists()) {
          const libraryData = libraryDoc.data()
          setSlots(libraryData.slots || [])
        }

        const seatStatsDoc = await getDoc(doc(db, 'seat_stats', libraryId))
        if (seatStatsDoc.exists()) {
          const data = seatStatsDoc.data()
          setSeatStats({
            slots: data.slots || {},
            totalSeats: data.totalSeats || 0
          })
        }
      } catch (error) {
        console.error("Error fetching data:", error)
        setErrorMessage('Error fetching data. Please try again.')
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [userId, libraryId])

  useEffect(() => {
    if (studentData.selectedSlot) {
      const newAvailableSeats = calculateAvailableSeats(studentData.selectedSlot)
      setAvailableSeats(newAvailableSeats)
    }
  }, [studentData.selectedSlot, seatStats])

  const calculateAvailableSeats = (selectedSlot) => {
    if (!selectedSlot || !seatStats.slots) return []

    const allOccupiedSeats = new Set()

    if (seatStats.slots['full day']) {
      seatStats.slots['full day'].forEach(seat => allOccupiedSeats.add(seat))
    }

    if (selectedSlot === 'full day') {
      Object.values(seatStats.slots).forEach(seats => {
        seats.forEach(seat => allOccupiedSeats.add(seat))
      })
    } else {
      if (seatStats.slots[selectedSlot]) {
        seatStats.slots[selectedSlot].forEach(seat => allOccupiedSeats.add(seat))
      }
    }

    return Array.from({ length: seatStats.totalSeats }, (_, i) => (i + 1).toString())
      .filter(seat => !allOccupiedSeats.has(seat) || seat === studentData.selectedSeat)
  }

  const handleChange = (name, value) => {
    setStudentData(prevData => ({
      ...prevData,
      [name]: value,
    }))

    if (name === 'selectedSlot') {
      const newAvailableSeats = calculateAvailableSeats(value)
      setAvailableSeats(newAvailableSeats)
      setStudentData(prevData => ({
        ...prevData,
        selectedSeat: '',
      }))
    }
  }

  const updateSeatStats = async (libraryId, newSeat, newSlot) => {
    try {
      const seatStatsDocRef = doc(db, 'seat_stats', libraryId)
      const seatStatsDoc = await getDoc(seatStatsDocRef)

      if (seatStatsDoc.exists()) {
        const seatStats = seatStatsDoc.data()
        let updatedSlots = { ...seatStats.slots }

        if (previousSlot && previousSeat) {
          updatedSlots[previousSlot] = (updatedSlots[previousSlot] || []).filter(seat => seat !== previousSeat)
        }

        if (newSlot && newSeat) {
          updatedSlots[newSlot] = [...(updatedSlots[newSlot] || [])]
          if (!updatedSlots[newSlot].includes(newSeat)) {
            updatedSlots[newSlot].push(newSeat)
          }
        }

        await updateDoc(seatStatsDocRef, { slots: updatedSlots })
      }
    } catch (error) {
      console.error("Error updating seat stats:", error)
      throw error
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setErrorMessage('')

    try {
      await updateDoc(doc(db, 'users', userId), studentData)

      if (studentData.selectedSeat !== previousSeat || studentData.selectedSlot !== previousSlot) {
        await updateSeatStats(libraryId, studentData.selectedSeat, studentData.selectedSlot)
      }

      if (typeof onUpdateStudent === 'function') {
        onUpdateStudent(studentData)
      } else {
        console.warn('onUpdateStudent is not a function. Student data may not be properly updated in the parent component.')
      }

      // Show success toast notification
      toast.success(`${studentData.name} updated successfully!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })

      onClose()
    } catch (error) {
      console.error("Error updating student:", error)
      setErrorMessage('Error updating student. Please try again.')
      
      // Show error toast notification
      toast.error('Failed to update student. Please try again.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 dark:bg-opacity-70 z-50 p-4 overflow-y-auto">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl p-6 w-full max-w-lg relative">
        <button 
          onClick={onClose} 
          className="absolute top-4 right-4 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
          aria-label="Close"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
          Edit Student
        </h2>

        {currentPage === 1 && (
          <form onSubmit={(e) => { e.preventDefault(); setCurrentPage(2); }} className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-200">Name</label>
              <input
                id="name"
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md text-sm shadow-sm placeholder-gray-400 dark:placeholder-gray-400
                           focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                           disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
                           dark:text-white dark:focus:ring-offset-gray-900"
                value={studentData.name}
                onChange={(e) => handleChange('name', e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-200">Email</label>
              <input
                id="email"
                className="mt-1 block w-full px-3 py-2 bg-gray-100 dark:bg-gray-600 border border-gray-300 dark:border-gray-500 rounded-md text-sm shadow-sm 
                           focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                           disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
                           dark:text-gray-300"
                value={studentData.email}
                onChange={(e) => handleChange('email', e.target.value)}
                required
                disabled
              />
            </div>
            <div>
              <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 dark:text-gray-200">Phone Number</label>
              <input
                id="phoneNumber"
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md text-sm shadow-sm placeholder-gray-400 dark:placeholder-gray-400
                           focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                           disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
                           dark:text-white dark:focus:ring-offset-gray-900"
                value={studentData.phoneNumber}
                onChange={(e) => handleChange('phoneNumber', e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="address" className="block text-sm font-medium text-gray-700 dark:text-gray-200">Address</label>
              <input
                id="address"
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md text-sm shadow-sm placeholder-gray-400 dark:placeholder-gray-400
                           focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                           disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
                           dark:text-white dark:focus:ring-offset-gray-900"
                value={studentData.address}
                onChange={(e) => handleChange('address', e.target.value)}
                required
              />
            </div>
            <div className="flex justify-between pt-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 dark:focus:ring-offset-gray-900"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 dark:focus:ring-offset-gray-900"
              >
                Next
              </button>
            </div>
          </form>
        )}

        {currentPage === 2 && (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="goal" className="block text-sm font-medium text-gray-700 dark:text-gray-200">Preparing for</label>
              <input
                id="goal"
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md text-sm shadow-sm placeholder-gray-400 dark:placeholder-gray-400
                           focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                           disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
                           dark:text-white dark:focus:ring-offset-gray-900"
                value={studentData.goal}
                onChange={(e) => handleChange('goal', e.target.value)}
                placeholder="UPSC, SSC, JEE, NEET, etc."
              />
            </div>
            <div>
              <label htmlFor="college" className="block text-sm font-medium text-gray-700 dark:text-gray-200">College</label>
              <input
                id="college"
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md text-sm shadow-sm placeholder-gray-400 dark:placeholder-gray-400
                           focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                           disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
                           dark:text-white dark:focus:ring-offset-gray-900"
                value={studentData.college}
                onChange={(e) => handleChange('college', e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="selectedSlot" className="block text-sm font-medium text-gray-700 dark:text-gray-200">Select Slot</label>
              <select
                id="selectedSlot"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm rounded-md
                           bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                value={studentData.selectedSlot}
                onChange={(e) => handleChange('selectedSlot', e.target.value)}
              >
                <option value="">Select a slot</option>
                {slots.map((slot, index) => (
                  <option key={index} value={slot.time}>
                    {`${slot.time} - ₹${slot.price}`}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="selectedSeat" className="block text-sm font-medium text-gray-700 dark:text-gray-200">Select Seat</label>
              <select
                id="selectedSeat"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm rounded-md
                           bg-white dark:bg-gray-700 text-gray-900 dark:text-white
                           disabled:bg-gray-100 disabled:text-gray-500 dark:disabled:bg-gray-600 dark:disabled:text-gray-400"
                value={studentData.selectedSeat}
                onChange={(e) => handleChange('selectedSeat', e.target.value)}
                disabled={!studentData.selectedSlot}
              >
                <option value="">Select a seat</option>
                {availableSeats.map((seat) => (
                  <option key={seat} value={seat}>
                    {seat}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="joinDate" className="block text-sm font-medium text-gray-700 dark:text-gray-200">Join Date</label>
              <input
                id="joinDate"
                type="date"
                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md text-sm shadow-sm
                           focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                           disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none
                           dark:text-white dark:focus:ring-offset-gray-900"
                value={studentData.joinDate}
                onChange={(e) => handleChange('joinDate', e.target.value)}
                required
              />
            </div>
            {errorMessage && <p className="text-red-500 dark:text-red-400 text-sm">{errorMessage}</p>}
            <div className="flex justify-between pt-4">
              <button
                type="button"
                onClick={() => setCurrentPage(1)}
                className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 dark:focus:ring-offset-gray-900"
              >
                Back
              </button>
              <button
                type="submit"
                disabled={loading}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 dark:focus:ring-offset-gray-900 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? 'Updating...' : 'Update Student'}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}