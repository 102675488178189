import React from 'react';
import { FaWifi, FaBatteryFull, FaSnowflake, FaWater, FaLeaf, FaVideo, FaToilet } from 'react-icons/fa';

const Facilities = () => {
  return (
    <div className="max-w-5xl mx-auto bg-gradient-to-r from-blue-500 to-purple-600 text-white p-8 rounded-lg shadow-lg my-8">
      <h2 className="text-3xl font-bold mb-6 text-center">Library Facilities</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        <div className="flex items-center space-x-4 p-4 bg-white bg-opacity-10 rounded-lg hover:bg-opacity-20 transition">
          <FaWifi className="text-3xl" />
          <span className="text-lg font-medium">Free WiFi 24/7</span>
        </div>
        <div className="flex items-center space-x-4 p-4 bg-white bg-opacity-10 rounded-lg hover:bg-opacity-20 transition">
          <FaBatteryFull className="text-3xl" />
          <span className="text-lg font-medium">Power Backup (Inverter)</span>
        </div>
        <div className="flex items-center space-x-4 p-4 bg-white bg-opacity-10 rounded-lg hover:bg-opacity-20 transition">
          <FaSnowflake className="text-3xl" />
          <span className="text-lg font-medium">Fully Air-Conditioned Hall</span>
        </div>
        <div className="flex items-center space-x-4 p-4 bg-white bg-opacity-10 rounded-lg hover:bg-opacity-20 transition">
          <FaWater className="text-3xl" />
          <span className="text-lg font-medium">Mineral Water</span>
        </div>
        <div className="flex items-center space-x-4 p-4 bg-white bg-opacity-10 rounded-lg hover:bg-opacity-20 transition">
          <FaLeaf className="text-3xl" />
          <span className="text-lg font-medium">Peaceful Environment</span>
        </div>
        <div className="flex items-center space-x-4 p-4 bg-white bg-opacity-10 rounded-lg hover:bg-opacity-20 transition">
          <FaVideo className="text-3xl" />
          <span className="text-lg font-medium">CCTV Surveillance</span>
        </div>
        <div className="flex items-center space-x-4 p-4 bg-white bg-opacity-10 rounded-lg hover:bg-opacity-20 transition">
          <FaToilet className="text-3xl" />
          <span className="text-lg font-medium">Clean Toilet and Washroom</span>
        </div>
      </div>
    </div>
  );
};

export default Facilities;
