import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { db } from '../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const JoinForm = ({ selectedSlot, closeModal, libraryName, libraryId }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    address: '',
    goal: '',
    collegeName: '',
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 2;

  const navigate = useNavigate();

  const handleClose = () => {
    closeModal();
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const form = e.target.form;
      if (form) {
        const index = Array.prototype.indexOf.call(form, e.target);
        if (index < form.elements.length - 1) {
          form.elements[index + 1].focus();
        } else {
          handleNextPage();
        }
      }
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (currentPage < totalPages) {
      handleNextPage();
      return;
    }
  
    try {
      // Save request data in Firestore
      await addDoc(collection(db, 'requests_s'), {
        name: formData.name,
        email: formData.email,
        selectedSlot: selectedSlot || 'Unknown',
        libraryId: libraryId || 'Unknown',
        status: 'pending',
        phoneNumber: formData.phoneNumber || '',
        address: formData.address || '',
        goal: formData.goal || '',
        collegeName: formData.collegeName || '',
        createdAt: serverTimestamp(),
      });
  
      // Show the confirmation message
      setShowConfirmation(true);
    } catch (error) {
      console.error('Error saving request data:', error);
      setErrorMessage('Error submitting request. Please try again.');
    }
  };

  const JoinConfirmation = () => (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-xl shadow-2xl max-w-md w-full">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Subscription Confirmation</h2>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={() => {
              setShowConfirmation(false);
              navigate('/login');
            }}
          >
            <FaTimes className="w-6 h-6" />
          </button>
        </div>
        <div className="text-gray-600 space-y-4">
          <p className="font-semibold">
            Thank you for subscribing to the {selectedSlot} slot at {libraryName}!
          </p>
          <p>
            Your request has been received and is pending approval. To complete your registration:
          </p>
          <ol className="list-decimal list-inside space-y-2">
            <li>Visit {libraryName} in person</li>
            <li>Inform the staff about your subscription request</li>
            <li>Pay the corresponding amount for the {selectedSlot} slot</li>
          </ol>
          <p>
            Once your payment is confirmed, your subscription will be activated.
          </p>
        </div>
        <button
          className="mt-6 w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-75 transition-colors duration-200"
          onClick={() => {
            setShowConfirmation(false);
            navigate('/login');
          }}
        >
          Close
        </button>
      </div>
    </div>
  );

  const inputClass = "w-full px-4 py-2 border rounded-lg shadow-inner focus:outline-none focus:ring-2 focus:ring-indigo-300 text-gray-900 placeholder-gray-500 bg-white bg-opacity-75";
  const labelClass = "block text-white text-sm font-semibold mb-2";

  return (
    <>
      {showConfirmation ? (
        <JoinConfirmation />
      ) : (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-gradient-to-br from-blue-500 via-indigo-600 to-purple-700 p-8 rounded-xl shadow-2xl max-w-md w-full transform transition-all duration-300 ease-in-out">
            <button
              className="absolute top-4 right-4 text-white text-2xl hover:text-gray-300"
              onClick={handleClose}
            >
              <FaTimes />
            </button>

            <h2 className="text-3xl font-extrabold text-center text-white mb-6">
              Join {libraryName} - {selectedSlot} Slot
            </h2>
            <form onSubmit={handleSubmit}>
              {currentPage === 1 && (
                <>
                  <div className="mb-4">
                    <label className={labelClass}>Name</label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      className={inputClass}
                      placeholder="Enter your name"
                      autoComplete="name"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className={labelClass}>Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      className={inputClass}
                      placeholder="Enter your email"
                      autoComplete="email"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className={labelClass}>Goal</label>
                    <input
                      type="text"
                      name="goal"
                      value={formData.goal}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      className={inputClass}
                      placeholder="e.g., Preparing for UPSC, JEE, NEET"
                    />
                  </div>
                </>
              )}

              {currentPage === 2 && (
                <>
                  <div className="mb-4">
                    <label className={labelClass}>Phone Number</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      className={inputClass}
                      placeholder="Enter your phone number"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className={labelClass}>Address</label>
                    <input
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      className={inputClass}
                      placeholder="Enter your address"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className={labelClass}>College Name</label>
                    <input
                      type="text"
                      name="collegeName"
                      value={formData.collegeName}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      className={inputClass}
                      placeholder="Enter your college name"
                    />
                  </div>
                </>
              )}

              {errorMessage && (
                <div className="mb-4 text-red-500 text-sm font-semibold">{errorMessage}</div>
              )}

              <div className="flex justify-between mt-6">
                {currentPage > 1 && (
                  <button
                    type="button"
                    onClick={handlePreviousPage}
                    className="w-1/3 py-2 px-4 text-center bg-white bg-opacity-25 text-white font-semibold rounded-lg shadow-inner hover:bg-opacity-50 transition ease-in-out duration-200 focus:outline-none"
                  >
                    Previous
                  </button>
                )}
                <button
                  type="submit"
                  className="w-1/3 py-2 px-4 text-center bg-indigo-700 text-white font-semibold rounded-lg shadow-lg hover:bg-indigo-600 transition ease-in-out duration-200 focus:outline-none"
                >
                  {currentPage < totalPages ? 'Next' : 'Submit'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default JoinForm;