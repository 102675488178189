import React, { useState } from 'react';
import { ClipLoader } from 'react-spinners';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, userName }) => {
    const [loading, setLoading] = useState(false);

    if (!isOpen) return null;

    const handleConfirm = async () => {
        setLoading(true);
        try {
            await onConfirm();
        } catch (error) {
            console.error('Error during confirmation:', error);
        } finally {
            setLoading(false);
            onClose();
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 p-4">
            <div className="bg-black bg-opacity-50 dark:bg-opacity-70 absolute inset-0"></div>
            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 sm:p-8 z-10 shadow-lg max-w-sm w-full">
                <h2 className="text-xl font-semibold mb-4 dark:text-white">Remove User</h2>
                <p className="mb-6 dark:text-gray-300">
                    Are you sure you want to remove the user <strong className="text-red-600 dark:text-red-400">{userName}</strong>?
                </p>
                <p className="mb-6 text-sm text-gray-600 dark:text-gray-400">
                    This action cannot be undone. All data associated with this user will be permanently deleted.
                </p>
                <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-2">
                    <button
                        className="px-4 py-2 bg-gray-300 dark:bg-gray-600 text-gray-700 dark:text-gray-200 rounded-md hover:bg-gray-400 dark:hover:bg-gray-500 transition-colors duration-200"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        className={`px-4 py-2 rounded-md text-white ${loading ? 'bg-red-500 dark:bg-red-600' : 'bg-red-600 dark:bg-red-700'} ${loading ? 'hover:bg-red-600 dark:hover:bg-red-700' : 'hover:bg-red-700 dark:hover:bg-red-800'} transition-colors duration-200`}
                        onClick={handleConfirm}
                        disabled={loading}
                    >
                        {loading ? (
                            <div className="flex items-center justify-center">
                                <ClipLoader size={20} color="#fff" />
                                <span className="ml-2">Removing...</span>
                            </div>
                        ) : (
                            'Yes, Remove User'
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;