import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { collection, query, where, doc, addDoc, updateDoc, Timestamp, getDoc, setDoc, onSnapshot, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../firebase';
import { FaTrash, FaEdit, FaClock, FaChair, FaSearch, FaFilter, FaChevronLeft, FaChevronRight, FaUserPlus, FaClipboardList, FaChartBar, FaEllipsisV, FaPlus, FaBell, FaGraduationCap } from 'react-icons/fa';
import EditStudent from './EditStudent';
import NewStudent from './NewStudent';
import LogsViewer from './LogsViewer';
import ConfirmationModal from './ConfirmationModal';
import StatusChangeModal from './StatusChangeModal';
import ExportOptions from './ExportOptions';
import UserDetailsModal from './UserDetailsModal';
import RequestsViewer from './RequestsViewer';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';
import { useAuth } from './AuthContext';
import { ScaleLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useOptimizedUsers } from './useOptimizedUsers';
import AddExpenseModal from './AddExpenseModal';
import { useDarkMode } from './DarkModeContext';
import AlumniViewer from './AlumniViewer';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export default function Component() {
  const { currentUser, role, libraryId } = useAuth();
  const { users, loading, refetchUsers, updateUser } = useOptimizedUsers(libraryId);
  const [libraryName, setLibraryName] = useState('');
  const [libraryData, setLibraryData] = useState(null);
  const [showNewStudentForm, setShowNewStudentForm] = useState(false);
  const [showEditStudentForm, setShowEditStudentForm] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserName, setSelectedUserName] = useState('');
  const [selectedUserEmail, setSelectedUserEmail] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isStatusChangeModalOpen, setIsStatusChangeModalOpen] = useState(false);
  const [showLogsViewer, setShowLogsViewer] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [sortColumn, setSortColumn] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(20);
  const [statusChangeConfirmation, setStatusChangeConfirmation] = useState(null);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isUserDetailsModalOpen, setIsUserDetailsModalOpen] = useState(false);
  const [isAddExpenseModalOpen, setIsAddExpenseModalOpen] = useState(false);
  const [showRequestsViewer, setShowRequestsViewer] = useState(false);
  const [requestsCount, setRequestsCount] = useState(0);
  const [showAlumniViewer, setShowAlumniViewer] = useState(false);
  const { darkMode } = useDarkMode();

  const paginate = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  const fetchLibraryData = useCallback(async (libraryId) => {
    if (!libraryId) return;
    try {
      const libraryDoc = await getDoc(doc(db, 'libraries', libraryId));
      if (libraryDoc.exists()) {
        const data = libraryDoc.data();
        setLibraryName(data.name);
        setLibraryData(data);
      }
    } catch (error) {
      console.error("Error fetching library data:", error);
      toast.error("Failed to fetch library data");
    }
  }, []);

  useEffect(() => {
    if (libraryId) {
      fetchLibraryData(libraryId);
    }
  }, [libraryId, fetchLibraryData]);

  useEffect(() => {
    if (!libraryId) return;

    const usersCollection = collection(db, 'users');
    const q = query(usersCollection, where('libraryId', '==', libraryId), where('role', '==', 'user'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "modified" || change.type === "removed") {
          refetchUsers();
        }
      });
    });

    return () => unsubscribe();
  }, [libraryId, refetchUsers]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, filterStatus, usersPerPage]);

  useEffect(() => {
    if (!libraryId) return;

    const requestsCollection = collection(db, 'requests_s');
    const q = query(requestsCollection, where('libraryId', '==', libraryId), where('status', '==', 'pending'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      setRequestsCount(snapshot.size);
    });

    return () => unsubscribe();
  }, [libraryId]);

  const handleCycleChange = useCallback((userId, newCycleIndex) => {
    const updatedUser = users.find(u => u.id === userId);
    if (updatedUser) {
      updatedUser.currentCycleIndex = parseInt(newCycleIndex);
      updateUser(updatedUser);
    }
  }, [users, updateUser]);

  const logChange = useCallback(async (action, userName, userEmail = null, userId = null) => {
    try {
      await addDoc(collection(db, 'logs'), {
        action,
        userName,
        userEmail,
        userId,
        adminEmail: currentUser.email,
        libraryId,
        timestamp: Timestamp.now(),
      });
    } catch (error) {
      console.error("Error logging change:", error);
      toast.error("Failed to log change");
    }
  }, [currentUser.email, libraryId]);

  const updatePaymentStatus = useCallback(async (userId, cycleIndex, newStatus) => {
    if (!userId || !libraryId) {
      console.error("User ID or Library ID is undefined");
      return;
    }
    try {
      const userDoc = doc(db, 'users', userId);
      const userSnapshot = await getDoc(userDoc);
      const userData = userSnapshot.data();
      
      const joinDate = userData.joinDate instanceof Timestamp ? dayjs(userData.joinDate.toDate()) : (userData.joinDate ? dayjs(userData.joinDate) : null);
      const createdAt = userData.createdAt instanceof Timestamp ? dayjs(userData.createdAt.toDate()) : (userData.createdAt ? dayjs(userData.createdAt) : null);
      
      const billingStartDate = joinDate || createdAt || dayjs();
      const cycleStartDate = billingStartDate.add(cycleIndex, 'month');
      const cycleKey = cycleStartDate.format('YYYY-MM');

      const billingStatus = userData.billingStatus || {};
      billingStatus[cycleKey] = newStatus;

      const updateData = { billingStatus };

      if (newStatus === 'Paid') {
        updateData.lastBillingDate = Timestamp.fromDate(cycleStartDate.toDate());
      }

      await updateDoc(userDoc, updateData);

      const userHistoryRef = doc(db, 'userHistory', userId);
      const userHistorySnapshot = await getDoc(userHistoryRef);
      const userHistoryData = userHistorySnapshot.data();

      if (userHistoryData && userHistoryData.currentLibrary) {
        const updatedBillingStatus = {
          ...userHistoryData.currentLibrary.billingStatus,
          [cycleKey]: newStatus
        };

        await updateDoc(userHistoryRef, {
          'currentLibrary.billingStatus': updatedBillingStatus
        });

        if (newStatus === 'Paid') {
          await updateDoc(userHistoryRef, {
            'currentLibrary.lastBillingDate': Timestamp.fromDate(cycleStartDate.toDate())
          });
        }
      }

      await updateRevenueStats(libraryId, cycleKey, userData.selectedSlot, newStatus, userData.monthlyFee);

      refetchUsers();

      logChange('status update', userData.name, userData.email, userId);
      toast.success(`Status updated for ${userData.name} for Month starting ${cycleStartDate.format('DD-MM-YYYY')}`);
    } catch (error) {
      console.error("Error updating payment status:", error);
      toast.error(`Failed to update status for user.`);
    }
  }, [libraryId, refetchUsers, logChange]);

  const updateRevenueStats = async (libraryId, month, slot, status, amount) => {
    const revenueStatsRef = doc(db, 'revenue_stats', `${libraryId}_${month}`);
    const revenueStatsDoc = await getDoc(revenueStatsRef);

    let revenueStats = revenueStatsDoc.exists() ? revenueStatsDoc.data() : {
      libraryId,
      month,
      totalRevenue: 0,
      slotRevenue: {},
      paidPayments: {},
      pendingPayments: {},
      overduePayments: {},
      expenses: [],
      totalExpenses: 0
    };

    if (!revenueStats.slotRevenue[slot]) revenueStats.slotRevenue[slot] = 0;
    if (!revenueStats.paidPayments[slot]) revenueStats.paidPayments[slot] = 0;
    if (!revenueStats.pendingPayments[slot]) revenueStats.pendingPayments[slot] = 0;
    if (!revenueStats.overduePayments[slot]) revenueStats.overduePayments[slot] = 0;

    if (status === 'Paid') {
      revenueStats.totalRevenue += amount;
      revenueStats.slotRevenue[slot] += amount;
      revenueStats.paidPayments[slot]++;
    } else if (status === 'Unpaid') {
      revenueStats.pendingPayments[slot]++;
    } else if (status === 'Overdue') {
      revenueStats.overduePayments[slot]++;
    }

    await setDoc(revenueStatsRef, revenueStats);
  };

  const handleStatusChange = useCallback((userId, userName, cycleIndex, newStatus, currentStatus) => {
    setStatusChangeConfirmation({
      userId,
      userName,
      cycleIndex,
      newStatus,
      currentStatus
    });
    setIsStatusChangeModalOpen(true);
  }, []);

  const confirmStatusChange = useCallback(async () => {
    if (statusChangeConfirmation) {
      const { userId, cycleIndex, newStatus } = statusChangeConfirmation;
      await updatePaymentStatus(userId, cycleIndex, newStatus);
      setIsStatusChangeModalOpen(false);
      setStatusChangeConfirmation(null);
    }
  }, [statusChangeConfirmation, updatePaymentStatus]);

  const cancelStatusChange = useCallback(() => {
    setIsStatusChangeModalOpen(false);
    setStatusChangeConfirmation(null);
  }, []);

  const handleDelete = useCallback(async () => {
    if (!selectedUserId) {
      console.error("No user selected for deletion");
      toast.error("Failed to remove user: No user selected");
      return;
    }
  
    try {
      const userRef = doc(db, 'users', selectedUserId);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data();
  
      if (!userData) {
        throw new Error("User data not found");
      }
  
      const userHistoryRef = doc(db, 'userHistory', selectedUserId);
      const userHistoryDoc = await getDoc(userHistoryRef);
      const userHistoryData = userHistoryDoc.data();
  
      if (userHistoryData) {
        const currentLibrary = userHistoryData.currentLibrary;
        if (currentLibrary) {
          await updateDoc(userHistoryRef, {
            previousLibraries: arrayUnion({
              ...currentLibrary,
              exitDate: Timestamp.now()
            }),
            currentLibrary: null
          });
        }
      }
  
      if (userData.libraryId && userData.selectedSlot && userData.selectedSeat) {
        const seatStatsRef = doc(db, 'seat_stats', userData.libraryId);
        const seatStatsDoc = await getDoc(seatStatsRef);
        const seatStatsData = seatStatsDoc.data();
  
        if (seatStatsData && seatStatsData.slots[userData.selectedSlot]) {
          const updatedSlots = seatStatsData.slots[userData.selectedSlot].filter(
            seat => seat !== userData.selectedSeat
          );
  
          await updateDoc(seatStatsRef, {
            [`slots.${userData.selectedSlot}`]: updatedSlots
          });
        }
      }
  
      await updateDoc(userRef, {
        libraryId: null,
        selectedSeat: null,
        selectedSlot: null,
        billingCycles: [],
        currentCycleIndex: null,
        lastBillingDate: null,
        monthlyFee: null,
        joinDate: null
      });
  
      if (userData.libraryId) {
        const libraryRef = doc(db, 'libraries', userData.libraryId);
        await updateDoc(libraryRef, {
          users: arrayRemove(selectedUserId)
        });
      }
  
      refetchUsers();
      toast.success(`User ${selectedUserName} has been removed successfully.`);
      logChange('delete', selectedUserName, selectedUserEmail, selectedUserId);
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error(`Failed to remove user ${selectedUserName}: ${error.message}`);
    }
  }, [selectedUserId, selectedUserName, selectedUserEmail, logChange, refetchUsers]);

  const handleEdit = useCallback((userId, userName, userEmail) => {
    setSelectedUserId(userId);
    setSelectedUserName(userName);
    setSelectedUserEmail(userEmail);
    setShowEditStudentForm(true);
  }, []);

  const handleAddStudent = useCallback(async (newStudent) => {
    setShowNewStudentForm(false);
    await refetchUsers();
    logChange('add', newStudent.name, newStudent.email);
  }, [logChange, refetchUsers]);

  const openDeleteModal = useCallback((userId, userName, userEmail) => {
    setSelectedUserId(userId);
    setSelectedUserName(userName);
    setSelectedUserEmail(userEmail);
    setIsDeleteModalOpen(true);
  }, []);

  const closeDeleteModal = useCallback(() => setIsDeleteModalOpen(false), []);

  const getStatusBackgroundColor = useCallback((status) => {
    switch (status) {
      case 'Paid': return darkMode ? 'bg-green-900 text-green-100' : 'bg-green-200';
      case 'Unpaid': return darkMode ? 'bg-yellow-900 text-yellow-100' : 'bg-yellow-200';
      case 'Overdue': return darkMode ? 'bg-red-900 text-red-100' : 'bg-red-200';
      default: return darkMode ? 'bg-gray-700 text-gray-100' : 'bg-gray-200';
    }
  }, [darkMode]);

  const formatTimestamp = useCallback((timestamp) => {
    if (timestamp instanceof Timestamp) {
      return dayjs(timestamp.toDate()).format('DD-MM-YYYY');
    }
    return timestamp || 'N/A';
  }, []);

  const handleSort = useCallback((column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  }, [sortColumn, sortDirection]);

  const getRowBackgroundColor = useCallback((user) => {
    if (!user || !user.billingCycles || user.billingCycles.length === 0 || user.currentCycleIndex === undefined) {
      return darkMode ? 'bg-gray-800 text-gray-200' : 'bg-gray-100';
    }
    const currentCycle = user.billingCycles[user.currentCycleIndex];
    if (!currentCycle) {
      return darkMode ? 'bg-gray-800 text-gray-200' : 'bg-gray-100';
    }
    const currentStatus = currentCycle.status;
    switch (currentStatus) {
      case 'Paid': return darkMode ? 'bg-cyan-950 text-slate-400' : 'bg-green-100';
      case 'Unpaid': return darkMode ? 'bg-#94a3b8 text-slate-400' : 'bg-yellow-100';
      case 'Overdue': return darkMode ? 'bg-red-900/50 text-red-100' : 'bg-red-100';
      default: return darkMode ? 'bg-gray-800 text-gray-200' : 'bg-gray-100';
    }
  }, [darkMode]);

  const filteredUsers = useMemo(() => {
    return users.filter(user => 
      (user.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
       (user.phone && user.phone.toLowerCase().includes(searchTerm.toLowerCase()))) &&
      (filterStatus === 'all' || (user.billingCycles && user.currentCycleIndex !== undefined && user.billingCycles[user.currentCycleIndex] && user.billingCycles[user.currentCycleIndex].status === filterStatus))
    );
  }, [users, searchTerm, filterStatus]);

  const sortedUsers = useMemo(() => {
    return [...filteredUsers].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortColumn] > b[sortColumn]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredUsers, sortColumn, sortDirection]);

  const currentUsers = useMemo(() => {
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    return sortedUsers.slice(indexOfFirstUser, indexOfLastUser);
  }, [sortedUsers, currentPage, usersPerPage]);

  const totalPages = Math.ceil(sortedUsers.length / usersPerPage);

  const openUserDetailsModal = useCallback((user) => {
    setSelectedUser(user);
    setIsUserDetailsModalOpen(true);
  }, []);

  const handleApproveRequest = async (newStudent) => {
    await handleAddStudent(newStudent);
    setRequestsCount(prevCount => prevCount - 1);
  };

  if (role !== 'admin' && role !== 'owner') {
    return <div className="text-center text-2xl mt-10">Access Denied</div>;
  }

  return (
    <div className={`min-h-screen p-4 md:p-8 ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'}`}>
      <div className='flex flex-col md:flex-row justify-between mb-8'>
        <div className='flex flex-col mb-4 md:mb-0'>
          <h2 className="text-3xl font-semibold mb-2">Welcome to Your Dashboard</h2>
          {libraryName && <p className={`text-lg ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}> {libraryName}</p>}
        </div>
        <div className="flex flex-wrap gap-4 items-start">
          <button
            className={`px-4 py-2 ${darkMode ? 'bg-blue-700' : 'bg-blue-600'} text-white rounded-md flex items-center hover:bg-blue-700 transition-colors`}
            onClick={() => setShowNewStudentForm(true)}
          >
            <FaUserPlus className="mr-2" />
            Add New Student
          </button>
          <div className="relative">
            <button
              className={`px-4 py-2 ${darkMode ? 'bg-purple-700' : 'bg-purple-600'} text-white rounded-md flex items-center hover:bg-purple-700 transition-colors`}
              onClick={() => setShowMoreOptions(!showMoreOptions)}
            >
              <FaEllipsisV className="mr-2" />
              <span className="hidden sm:inline">More Options</span>
              <span className="inline sm:hidden ml-2">More</span>
            </button>
            {showMoreOptions && (
              <div className={`absolute right-0 mt-2 w-48 ${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-md shadow-lg z-10`}>
                <button
                  className={`w-full px-4 py-2 text-left ${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} flex items-center`}
                  onClick={() => {
                    setShowRequestsViewer(true);
                    setShowMoreOptions(false);
                  }}
                >
                  <FaBell className="mr-2" />
                  Requests
                  {requestsCount > 0 && (
                    <span className="ml-2 bg-red-500 text-white rounded-full px-2 py-1 text-xs">
                      {requestsCount}
                    </span>
                  )}
                </button>
                <button
                  className={`w-full px-4 py-2 text-left ${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} flex items-center`}
                  onClick={() => {
                    setShowLogsViewer(true);
                    setShowMoreOptions(false);
                  }}
                >
                  <FaClipboardList className="mr-2" />
                  View Activity Logs
                </button>
                <ExportOptions data={filteredUsers} fileName="users_data" />
                <Link
                  to="/analytics"
                  className={`w-full px-4 py-2 text-left ${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} flex items-center`}
                  onClick={() => setShowMoreOptions(false)}
                >
                  <FaChartBar className="mr-2" />
                  View Analytics
                </Link>
                <button
                  className={`w-full px-4 py-2 text-left ${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} flex items-center`}
                  onClick={() => {
                    setIsAddExpenseModalOpen(true);
                    setShowMoreOptions(false);
                  }}
                >
                  <FaPlus className="mr-2" />
                  Add Expense
                </button>
                <button
                  className={`w-full px-4 py-2 text-left ${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} flex items-center`}
                  onClick={() => {
                    setShowAlumniViewer(true);
                    setShowMoreOptions(false);
                  }}
                >
                  <FaGraduationCap className="mr-2" />
                  Alumni
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mb-4 flex flex-col md:flex-row gap-4">
        <div className="flex-1 relative">
          <input
            type="text"
            placeholder="Search by name or phone"
            className={`w-full p-2 pl-10 border rounded-md ${darkMode ? 'bg-gray-800 text-white border-gray-700' : 'bg-white text-black border-gray-300'}`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FaSearch className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`} />
        </div>
        <div className="relative">
          <select
            className={`w-full md:w-auto p-2 pl-10 border rounded-md appearance-none ${darkMode ? 'bg-gray-800 text-white border-gray-700' : 'bg-white text-black border-gray-300'}`}
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="all">All Statuses</option>
            <option value="Paid">Paid</option>
            <option value="Unpaid">Unpaid</option>
            <option value="Overdue">Overdue</option>
          </select>
          <FaFilter className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`} />
        </div>
        <div className="relative">
          <select
            className={`w-full md:w-auto p-2 border rounded-md appearance-none ${darkMode ? 'bg-gray-800 text-white border-gray-700' : 'bg-white text-black border-gray-300'}`}
            value={usersPerPage}
            onChange={(e) => setUsersPerPage(Number(e.target.value))}
          >
            <option value={10}>10 rows</option>
            <option value={20}>20 rows</option>
            <option value={50}>50 rows</option>
          </select>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center min-h-[60vh]">
          <ScaleLoader size={50} color={darkMode ? "#60A5FA" : "#4A90E2"} />
        </div>
      ) : (
        <div className={`overflow-x-auto rounded-lg shadow ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
          <table className="min-w-full">
            <thead className={darkMode ? 'bg-gray-700' : 'bg-gray-200'}>
              <tr>
                <th className="py-3 px-4 text-left">S.No</th>
                <th className="py-3 px-4 text-left cursor-pointer" onClick={() => handleSort('name')}>
                  Name {sortColumn === 'name' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th className="py-3 px-4 text-left hidden sm:table-cell cursor-pointer" onClick={() => handleSort('phone')}>
                  Phone {sortColumn === 'phone' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th className="py-3 px-4 text-left cursor-pointer hidden md:table-cell" onClick={() => handleSort('joinDate')}>
                  Joining Date {sortColumn === 'joinDate' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th className="py-3 px-4 text-left cursor-pointer hidden md:table-cell" onClick={() => handleSort('lastBillingDate')}>
                  Last Billing {sortColumn === 'lastBillingDate' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th className="py-3 px-4 text-left">Billing Month</th>
                <th className="py-3 px-4 text-left">Status</th>
                <th className="py-3 px-4 text-left">Seat</th>
                <th className="py-3 px-4 text-left">Slots</th>
                <th className="py-3 px-4 text-left">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map((user, index) => (
                <tr key={user.id} className={`border-b ${darkMode ? 'border-gray-700' : 'border-gray-200'} ${getRowBackgroundColor(user)}`}>
                  <td className="py-2 px-4">{(currentPage - 1) * usersPerPage + index + 1}</td>
                  <td 
                    className={`py-2 px-4 cursor-pointer ${darkMode ? 'hover:text-blue-400' : 'hover:text-blue-600'}`}
                    onClick={() => openUserDetailsModal(user)}
                  >
                    {user.name}
                  </td>
                  <td className="py-2 hidden sm:table-cell px-4">{user.phoneNumber || 'N/A'}</td>
                  <td className="py-2 px-4 hidden md:table-cell">{user.joinDate}</td>
                  <td className="py-2 px-4 hidden md:table-cell">{user.lastBillingDate}</td>
                  <td className="py-2 px-4">
                    <select 
                      className={`w-full p-1 rounded-md border ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'} text-sm`}
                      value={user.currentCycleIndex !== undefined ? user.currentCycleIndex : ''}
                      onChange={(e) => handleCycleChange(user.id, e.target.value)}
                    >
                      {user.billingCycles && user.billingCycles.map((cycle, index) => (
                        <option key={index} value={index}>
                          {cycle.startDate} - {cycle.endDate}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="py-2 px-4">
                    <select 
                      className={`w-full p-1 rounded-md border ${darkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'} text-sm ${
                        getStatusBackgroundColor(
                          user.billingCycles &&
                          user.currentCycleIndex !== undefined &&
                          user.billingCycles[user.currentCycleIndex]
                            ? user.billingCycles[user.currentCycleIndex].status
                            : 'Unknown'
                        )
                      }`}
                      value={
                        user.billingCycles &&
                        user.currentCycleIndex !== undefined &&
                        user.billingCycles[user.currentCycleIndex]
                          ? user.billingCycles[user.currentCycleIndex].status
                          : 'Unknown'
                      }
                      onChange={(e) => handleStatusChange(
                        user.id,
                        user.name,
                        user.currentCycleIndex,
                        e.target.value,
                        user.billingCycles && user.currentCycleIndex !== undefined && user.billingCycles[user.currentCycleIndex]
                          ? user.billingCycles[user.currentCycleIndex].status
                          : 'Unknown'
                      )}
                    >
                      <option value="Paid">Paid</option>
                      <option value="Unpaid">Unpaid</option>
                      <option value="Overdue">Overdue</option>
                      <option value="Unknown">Unknown</option>
                    </select>
                  </td>
                  <td className="py-2 px-4">
                    <div className="flex items-center">
                      <FaChair className="mr-2 text-blue-500" />
                      <span className="text-sm">{user.selectedSeat || 'Not selected'}</span>
                    </div>
                  </td>
                  <td className="py-2 px-4">
                    <div className="flex items-center">
                      <FaClock className="mr-2 text-blue-500" />
                      <span className="text-sm">{user.selectedSlot || 'N/A'}</span>
                    </div>
                  </td>
                  <td className="py-2 px-4">
                    <div className="flex gap-2">
                      <button
                        className={`${darkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-900'} transition-colors`}
                        onClick={() => handleEdit(user.id, user.name, user.email)}
                      >
                        <FaEdit />
                      </button>
                      <button
                        className={`${darkMode ? 'text-red-400 hover:text-red-300' : 'text-red-600 hover:text-red-900'} transition-colors`}
                        onClick={() => openDeleteModal(user.id, user.name, user.email)}
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className={`flex justify-between items-center mt-4 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
        <div className="text-sm">
          Showing {(currentPage - 1) * usersPerPage + 1} to {Math.min(currentPage * usersPerPage, sortedUsers.length)} of {sortedUsers.length} entries
        </div>
        <nav className="inline-flex rounded-md shadow">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-3 py-1 rounded-l-md ${darkMode ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' : 'bg-white text-gray-700 hover:bg-gray-100'} ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <FaChevronLeft />
          </button>
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`px-3 py-1 ${darkMode ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' : 'bg-white text-gray-700 hover:bg-gray-100'} ${currentPage === index + 1 ? (darkMode ? 'bg-gray-700' : 'bg-gray-200') : ''}`}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`px-3 py-1 rounded-r-md ${darkMode ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' : 'bg-white text-gray-700 hover:bg-gray-100'} ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <FaChevronRight />
          </button>
        </nav>
      </div>

      {showNewStudentForm && (
        <NewStudent
          onClose={() => setShowNewStudentForm(false)}
          onAddStudent={handleAddStudent}
          libraryId={libraryId}
          libraryData={libraryData}
        />
      )}

      {showEditStudentForm && (
        <EditStudent
          userId={selectedUserId}
          userName={selectedUserName}
          userEmail={selectedUserEmail}
          onClose={() => setShowEditStudentForm(false)}
          onEditStudent={refetchUsers}
          libraryId={libraryId}
          libraryData={libraryData}
        />
      )}

      {showLogsViewer && (
        <LogsViewer onClose={() => setShowLogsViewer(false)} libraryId={libraryId} />
      )}

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDelete}
        title="Confirm Deletion"
        userName={selectedUserName}
      />

      <StatusChangeModal
        isOpen={isStatusChangeModalOpen}
        onClose={cancelStatusChange}
        onConfirm={confirmStatusChange}
        userName={statusChangeConfirmation?.userName}
        newStatus={statusChangeConfirmation?.newStatus}
        currentStatus={statusChangeConfirmation?.currentStatus}
      />

      <UserDetailsModal
        isOpen={isUserDetailsModalOpen}
        onClose={() => setIsUserDetailsModalOpen(false)}
        libraryId={libraryId}
        user={selectedUser}
      />

      <AddExpenseModal
        isOpen={isAddExpenseModalOpen}
        onClose={() => setIsAddExpenseModalOpen(false)}
        libraryId={libraryId}
      />

      {showRequestsViewer && (
        <RequestsViewer
          onClose={() => setShowRequestsViewer(false)}
          libraryId={libraryId}
          onApproveRequest={handleApproveRequest}
        />
      )}

      {showAlumniViewer && (
        <AlumniViewer
          onClose={() => setShowAlumniViewer(false)}
          libraryId={libraryId}
        />
      )}

      <ToastContainer position="bottom-right" />
    </div>
  );
}