import { motion, useSpring } from 'framer-motion';
import React, { useEffect } from 'react';

const CustomCursor = () => {
  const cursorX = useSpring(-100, { stiffness: 100, damping: 20 });
  const cursorY = useSpring(-100, { stiffness: 100, damping: 20 });
  const cursorSize = useSpring(32, { damping: 25, stiffness: 150 });
  const cursorOpacity = useSpring(0, { damping: 25, stiffness: 150 });

  useEffect(() => {
    const moveCursor = (e) => {
      const currentSize = cursorSize.get(); // Get the current size of the cursor
      cursorX.set(e.clientX - currentSize / 2); // Adjust X position to center the cursor
      cursorY.set(e.clientY - currentSize / 2); // Adjust Y position to center the cursor
    };

    const handleMouseEnter = (e) => {
      const target = e.target;
      if (['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'P', 'SPAN', 'A', 'BUTTON'].includes(target.tagName)) {
        cursorSize.set(264); // Larger cursor on hover over these elements
      } else {
        cursorSize.set(32); // Default cursor size
      }
      cursorOpacity.set(1);
    };

    const handleMouseLeave = () => {
      cursorOpacity.set(0);
    };

    window.addEventListener('mousemove', moveCursor);
    document.body.addEventListener('mouseover', handleMouseEnter);
    document.body.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      window.removeEventListener('mousemove', moveCursor);
      document.body.removeEventListener('mouseover', handleMouseEnter);
      document.body.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [cursorX, cursorY, cursorSize]); // Add cursorSize as dependency

  return (
    <motion.div
      className="fixed top-0 left-0 bg-white rounded-full pointer-events-none z-50 mix-blend-difference"
      style={{
        x: cursorX,
        y: cursorY,
        width: cursorSize,
        height: cursorSize,
        opacity: cursorOpacity,
      }}
      transition={{
        type: 'spring',
        damping: 25,
        stiffness: 150,
        delay: 0.05,
      }}
    />
  );
};

export default CustomCursor;
