import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, orderBy, limit, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from './AuthContext';
import { Doughnut, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

export default function Analytics() {
    const { libraryId } = useAuth();
    const [seatStats, setSeatStats] = useState({});
    const [revenueStats, setRevenueStats] = useState(null);
    const [libraryData, setLibraryData] = useState(null);
    const [totalSeats, setTotalSeats] = useState(50);
    const [loading, setLoading] = useState(true);
    const [availableMonths, setAvailableMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            if (libraryId) {
                try {
                    // Fetch seat stats
                    const seatStatsRef = collection(db, 'seat_stats');
                    const seatStatsQuery = query(seatStatsRef, where('libraryId', '==', libraryId));
                    const seatStatsSnapshot = await getDocs(seatStatsQuery);
                    
                    if (!seatStatsSnapshot.empty) {
                        const seatStatsData = seatStatsSnapshot.docs[0].data();
                        setSeatStats(seatStatsData.slots || {});
                        setTotalSeats(seatStatsData.totalSeats || 50);
                    }

                    // Fetch all available months
                    const revenueStatsRef = collection(db, 'revenue_stats');
                    const allMonthsQuery = query(
                        revenueStatsRef,
                        where('libraryId', '==', libraryId),
                        orderBy('month', 'desc')
                    );
                    const allMonthsSnapshot = await getDocs(allMonthsQuery);
                    const months = allMonthsSnapshot.docs.map(doc => doc.data().month);
                    setAvailableMonths(months);

                    // Set the most recent month as default
                    if (months.length > 0) {
                        setSelectedMonth(months[0]);
                    }

                    // Fetch library data
                    const libraryRef = collection(db, 'libraries');
                    const libraryQuery = query(libraryRef, where('libraryId', '==', libraryId));
                    const librarySnapshot = await getDocs(libraryQuery);

                    if (!librarySnapshot.empty) {
                        setLibraryData(librarySnapshot.docs[0].data());
                    }

                } catch (error) {
                    console.error("Error fetching analytics data:", error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [libraryId]);

    useEffect(() => {
        const fetchRevenueStats = async () => {
            if (selectedMonth) {
                const revenueStatsRef = collection(db, 'revenue_stats');
                const revenueStatsQuery = query(
                    revenueStatsRef,
                    where('libraryId', '==', libraryId),
                    where('month', '==', selectedMonth),
                    limit(1)
                );
                const revenueStatsSnapshot = await getDocs(revenueStatsQuery);
                
                if (!revenueStatsSnapshot.empty) {
                    const revenueStatsDoc = revenueStatsSnapshot.docs[0];
                    setRevenueStats({
                        id: revenueStatsDoc.id,
                        ...revenueStatsDoc.data()
                    });
                }
            }
        };

        fetchRevenueStats();
    }, [selectedMonth, libraryId]);

    const calculateAvailableSeats = (slotName) => {
        const fullDayOccupied = seatStats['full day'] ? seatStats['full day'].length : 0;
        
        if (slotName === 'full day') {
            const maxOtherSlotOccupancy = Math.max(
                ...Object.entries(seatStats)
                    .filter(([slot]) => slot !== 'full day')
                    .map(([_, seats]) => seats.length)
            );
            
            const occupied = fullDayOccupied;
            const available = Math.max(totalSeats - fullDayOccupied - maxOtherSlotOccupancy, 0);
            
            return { occupied, available };
        } else {
            const slotOccupied = seatStats[slotName] ? seatStats[slotName].length : 0;
            const available = Math.max(totalSeats - (slotOccupied + fullDayOccupied), 0);
            
            return { occupied: slotOccupied, available };
        }
    };

    const updateSlotRevenue = async (slotTime, calculatedRevenue) => {
        if (!revenueStats) return;

        const revenueStatsRef = doc(db, 'revenue_stats', revenueStats.id);
        
        try {
            // Calculate the new total revenue
            const newSlotRevenue = {
                ...revenueStats.slotRevenue,
                [slotTime]: calculatedRevenue
            };
            const newTotalRevenue = Object.values(newSlotRevenue).reduce((sum, revenue) => sum + revenue, 0);

            // Update both slotRevenue and totalRevenue
            await updateDoc(revenueStatsRef, {
                [`slotRevenue.${slotTime}`]: calculatedRevenue,
                totalRevenue: newTotalRevenue
            });

            // Update local state
            setRevenueStats(prevStats => ({
                ...prevStats,
                slotRevenue: newSlotRevenue,
                totalRevenue: newTotalRevenue
            }));

            console.log(`Updated revenue for ${slotTime}: ${calculatedRevenue}`);
            console.log(`Updated total revenue: ${newTotalRevenue}`);
        } catch (error) {
            console.error(`Error updating revenue for ${slotTime}:`, error);
        }
    };

    const renderSeatChart = (slotName) => {
        const { occupied, available } = calculateAvailableSeats(slotName);

        const chartData = {
            labels: ['Occupied', 'Vacant'],
            datasets: [
                {
                    data: [occupied, available],
                    backgroundColor: ['#4CAF50', '#FFA000'],
                    hoverBackgroundColor: ['#45a049', '#FF8F00'],
                },
            ],
        };

        return (
            <div key={slotName} className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
                <h3 className="text-lg font-semibold mb-4 dark:text-gray-100">{slotName}</h3>
                <div className="w-48 h-48 mx-auto">
                    <Doughnut data={chartData} options={{ responsive: true, maintainAspectRatio: true }} />
                </div>
                <p className="text-center mt-4 dark:text-gray-300">
                    Occupied: {occupied} / Available: {available} / Total: {totalSeats}
                </p>
            </div>
        );
    };

    const renderPaymentStats = (slot) => {
        if (!revenueStats || !libraryData || !seatStats) return null;

        const { time, price } = slot;
        const paid = revenueStats.paidPayments[time] || 0;
        const { occupied } = calculateAvailableSeats(time);
        const pending = Math.max(occupied - paid, 0);
        const overdue = revenueStats.overduePayments[time] || 0;
        const calculatedRevenue = paid * parseFloat(price);
        const storedRevenue = revenueStats.slotRevenue[time] || 0;
        const total = paid + pending + overdue;

        // Check if the calculated revenue is different from the stored revenue
        if (calculatedRevenue !== storedRevenue) {
            updateSlotRevenue(time, calculatedRevenue);
        }

        const chartData = {
            labels: ['Paid', 'Pending', 'Overdue'],
            datasets: [
                {
                    data: [paid, pending, overdue],
                    backgroundColor: ['#4CAF50', '#FFA000', '#F44336'],
                    hoverBackgroundColor: ['#45a049', '#FF8F00', '#E53935'],
                },
            ],
        };

        return (
            <div key={time} className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
                <h3 className="text-lg font-semibold mb-4 dark:text-gray-100">{time} - Payment Status</h3>
                <div className="w-48 h-48 mx-auto">
                    <Doughnut 
                        data={chartData} 
                        options={{ 
                            responsive: true, 
                            maintainAspectRatio: true,
                            plugins: {
                                tooltip: {
                                    callbacks: {
                                        label: function(context) {
                                            const label = context.label || '';
                                            const value = context.parsed || 0;
                                            const percentage = total > 0 ? ((value / total) * 100).toFixed(2) : 0;
                                            return `${label}: ${value} (${percentage}%)`;
                                        }
                                    }
                                }
                            }
                        }} 
                    />
                </div>
                <div className="mt-4 text-center dark:text-gray-300">
                    <p className="font-semibold">Revenue: ₹{calculatedRevenue.toFixed(2)}</p>
                    <p>Paid: {paid} | Pending: {pending} | Overdue: {overdue}</p>
                    <p>Price per slot: ₹{price}</p>
                </div>
            </div>
        );
    };

    const renderRevenueChart = () => {
        if (!revenueStats || !libraryData) return null;

        const slots = libraryData.slots;
        const revenues = slots.map(slot => revenueStats.slotRevenue[slot.time] || 0);

        const chartData = {
            labels: slots.map(slot => slot.time),
            datasets: [
                {
                    label: 'Revenue',
                    data: revenues,
                    backgroundColor: '#4CAF50',
                },
            ],
        };

        return (
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 col-span-full">
                <h3 className="text-lg font-semibold mb-4 dark:text-gray-100">Slot-wise Revenue</h3>
                <Bar 
                    data={chartData} 
                    options={{
                        responsive: true,
                        scales: {
                            y: {
                                beginAtZero: true,
                                title: {
                                    display: true,
                                    text: 'Revenue (₹)',
                                    color: 'gray',
                                }
                            },
                            x: {
                                ticks: {
                                    color: 'gray',
                                }
                            }
                        }
                    }}
                />
            </div>
        );
    };

    const renderExpenses = () => {
        if (!revenueStats) return null;

        return (
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
                <h3 className="text-lg font-semibold mb-4 dark:text-gray-100">Expenses</h3>
                <ul className="divide-y divide-gray-200 dark:divide-gray-600">
                    {revenueStats.expenses.map((expense, index) => (
                        <li key={index} className="py-3">
                            <p className="font-medium dark:text-gray-200">{expense.description}: ₹{expense.amount.toFixed(2)}</p>
                            <p className="text-sm text-gray-600 dark:text-gray-400">Date: {expense.date}, Category: {expense.category}</p>
                        </li>
                    ))}
                </ul>
                <p className="mt-4 font-semibold dark:text-gray-100">Total Expenses: ₹{revenueStats.totalExpenses.toFixed(2)}</p>
            </div>
        );
    };

    if (loading) {
        return <div className="flex justify-center items-center h-screen">Analyzing...</div>;
    }

    return (
        <div className="min-h-screen bg-gray-100 dark:bg-gray-900 p-8">
            <div className="mb-8 flex justify-between items-center">
                <h2 className="text-3xl font-semibold dark:text-white">Analytics</h2>
                <Link to="/admin-dashboard" className="flex items-center text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-600">
                    <FaArrowLeft className="mr-2" />
                    Back to Dashboard
                </Link>
            </div>

            <div className="mb-8">
                <h2 className="text-2xl font-semibold mb-4 dark:text-white">Seat Occupancy</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {Object.keys(seatStats).map(slotName => renderSeatChart(slotName))}
                </div>
            </div>

            <div className="mb-8">
                <div className='flex flex-row gap-4'>
                    <h2 className="text-2xl font-semibold mb-4 dark:text-white">Payment Analytics</h2>
                    <div className="mb-6">
                        <select
                            value={selectedMonth}
                            onChange={(e) => setSelectedMonth(e.target.value)}
                            className="block w-full max-w-xs px-3 py-2 text-base border-gray-300 dark:bg-gray-700 dark:text-white dark:border-gray-600 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                            <option value="">Select month</option>
                            {availableMonths.map((month) => (
                                <option key={month} value={month}>
                                    {month}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {libraryData && libraryData.slots.map(slot => renderPaymentStats(slot))}
                </div>
            </div>

            {renderRevenueChart()}

            <div className="mt-8">
                <h2 className="text-2xl font-semibold mb-4 dark:text-white">Expenses</h2>
                {renderExpenses()}
            </div>

            <div className="mt-8 bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
                <h2 className="text-2xl font-semibold mb-4 dark:text-white">Summary</h2>
                <p className="dark:text-gray-300">Total Revenue: ₹{revenueStats?.totalRevenue.toFixed(2)}</p>
                <p className="dark:text-gray-300">Total Expenses: ₹{revenueStats?.totalExpenses.toFixed(2)}</p>
                <p className="font-bold mt-2 dark:text-white">Net Profit: ₹{(revenueStats?.totalRevenue - revenueStats?.totalExpenses).toFixed(2)}</p>
            </div>
        </div>
    );
}
