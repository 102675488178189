import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className="bg-gradient-to-b from-blue-900 to-purple-900 min-h-screen text-white">
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-4xl mx-auto bg-white bg-opacity-10 rounded-lg shadow-xl overflow-hidden">
          <div className="p-8 sm:p-12">
            <h1 className="text-4xl font-bold mb-8 text-center">Privacy Policy</h1>
            <p className="mb-6 text-gray-300 text-sm">Last updated: 01/10/2024</p>
            
            <div className="space-y-8">
              <Section title="1. Introduction">
                <p>Welcome to The Library Hub. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services.</p>
              </Section>
              
              <Section title="2. Information We Collect">
                <p>We collect information that you provide directly to us, such as when you create an account, use our services, or communicate with us. This may include:</p>
                <ul className="list-disc list-inside ml-4 mt-2 space-y-1">
                  <li>Personal identification information (Name, email address, phone number)</li>
                  <li>User account information</li>
                  <li>Library preferences and booking history</li>
                </ul>
              </Section>
              
              <Section title="3. How We Use Your Information">
                <p>We use the information we collect to:</p>
                <ul className="list-disc list-inside ml-4 mt-2 space-y-1">
                  <li>Provide, maintain, and improve our services</li>
                  <li>Process and manage your library space bookings</li>
                  <li>Communicate with you about our services</li>
                  <li>Respond to your comments, questions, and requests</li>
                </ul>
              </Section>
              
              <Section title="4. Sharing of Your Information">
                <p>We do not sell your personal information. We may share your information with:</p>
                <ul className="list-disc list-inside ml-4 mt-2 space-y-1">
                  <li>Partner libraries to facilitate your bookings</li>
                  <li>Third-party service providers who perform services on our behalf</li>
                  <li>Law enforcement or other governmental entities when required by law</li>
                </ul>
              </Section>
              
              <Section title="5. Your Rights">
                <p>You have the right to:</p>
                <ul className="list-disc list-inside ml-4 mt-2 space-y-1">
                  <li>Access and receive a copy of your personal information</li>
                  <li>Correct or update your personal information</li>
                  <li>Request deletion of your personal information</li>
                  <li>Object to the processing of your personal information</li>
                </ul>
              </Section>
              
              <Section title="6. Changes to This Privacy Policy">
                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</p>
              </Section>
              
              <Section title="7. Contact Us">
                <p>If you have any questions about this Privacy Policy, please contact us at:</p>
                <p className="mt-2">Email: TheLibraryHub.in@gmail.com</p>
                <p>Phone: +91 9335567259</p>
              </Section>
            </div>
          </div>
        </div>
        <div className="mt-8 text-center">
          <Link to="/" className="inline-block px-6 py-3 bg-blue-600 text-white font-semibold rounded-full hover:bg-blue-700 transition-colors duration-300">
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

const Section = ({ title, children }) => (
  <section className="bg-white bg-opacity-5 rounded-lg p-6">
    <h2 className="text-2xl font-semibold mb-4">{title}</h2>
    <div className="text-gray-200">{children}</div>
  </section>
);

export default PrivacyPolicy;