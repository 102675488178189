import React, { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc, Timestamp, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { toast } from 'react-toastify';
import { X, Loader2, Plus, Trash } from 'lucide-react';

const EditLibrary = ({ libraryId, onClose, onUpdate }) => {
    const [libraryData, setLibraryData] = useState({
        name: '',
        phoneNumber: '',
        adminEmail: '',
        address: '',
        district: '',
        state: '',
        country: '',
        pincode: '',
        totalSeats: '',
        joinDate: '',
        description: '',
        slots: [],
    });
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        const fetchLibraryData = async () => {
            try {
                const docRef = doc(db, 'libraries', libraryId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    const joinDate = data.joinDate instanceof Timestamp 
                        ? data.joinDate.toDate().toISOString().split('T')[0]
                        : '';
                    setLibraryData({ ...data, joinDate });
                } else {
                    toast.error('Library not found');
                    onClose();
                }
            } catch (error) {
                console.error('Error fetching library data:', error);
                toast.error('Failed to fetch library data');
                onClose();
            }
        };

        fetchLibraryData();
    }, [libraryId, onClose]);

    const handleChange = (e) => {
        setLibraryData({
            ...libraryData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSlotChange = (index, field, value) => {
        const updatedSlots = [...libraryData.slots];
        updatedSlots[index] = { ...updatedSlots[index], [field]: value };
        setLibraryData({ ...libraryData, slots: updatedSlots });
    };

    const addSlot = () => {
        setLibraryData({
            ...libraryData,
            slots: [...libraryData.slots, { time: '', price: '' }]
        });
    };

    const removeSlot = (index) => {
        const updatedSlots = [...libraryData.slots];
        updatedSlots.splice(index, 1);
        setLibraryData({ ...libraryData, slots: updatedSlots });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setUpdating(true);
        try {
            const updatedData = { ...libraryData };
            if (updatedData.joinDate) {
                updatedData.joinDate = Timestamp.fromDate(new Date(updatedData.joinDate));
            }
            await updateDoc(doc(db, 'libraries', libraryId), updatedData);

            // Update seat_stats collection
            const seatStatsRef = doc(db, 'seat_stats', libraryId);
            const seatStatsSnap = await getDoc(seatStatsRef);
            let existingSeatStats = seatStatsSnap.exists() ? seatStatsSnap.data().slots : {};

            const seatStatsData = {
                libraryId,
                totalSeats: updatedData.totalSeats,
                slots: {}
            };

            updatedData.slots.forEach(slot => {
                if (existingSeatStats[slot.time]) {
                    // Preserve existing data for this slot
                    seatStatsData.slots[slot.time] = existingSeatStats[slot.time];
                } else {
                    // Initialize new slot without any value
                    seatStatsData.slots[slot.time] = [];
                }
            });

            await setDoc(seatStatsRef, seatStatsData, { merge: true });

            // Update revenue_stats collection
            const currentMonth = new Date().toISOString().slice(0, 7); // Format: YYYY-MM
            const revenueStatsRef = doc(db, 'revenue_stats', `${libraryId}_${currentMonth}`);
            const revenueStatsSnap = await getDoc(revenueStatsRef);
            let existingRevenueStats = revenueStatsSnap.exists() ? revenueStatsSnap.data() : {};

            const revenueStatsData = {
                libraryId,
                month: currentMonth,
                slotRevenue: {},
                overduePayments: {},
                paidPayments: {},
                pendingPayments: {}
            };

            updatedData.slots.forEach(slot => {
                if (existingRevenueStats.slotRevenue && existingRevenueStats.slotRevenue[slot.time] !== undefined) {
                    revenueStatsData.slotRevenue[slot.time] = existingRevenueStats.slotRevenue[slot.time];
                } else {
                    revenueStatsData.slotRevenue[slot.time] = null;
                }
                if (existingRevenueStats.overduePayments && existingRevenueStats.overduePayments[slot.time] !== undefined) {
                    revenueStatsData.overduePayments[slot.time] = existingRevenueStats.overduePayments[slot.time];
                } else {
                    revenueStatsData.overduePayments[slot.time] = null;
                }
                if (existingRevenueStats.paidPayments && existingRevenueStats.paidPayments[slot.time] !== undefined) {
                    revenueStatsData.paidPayments[slot.time] = existingRevenueStats.paidPayments[slot.time];
                } else {
                    revenueStatsData.paidPayments[slot.time] = null;
                }
                if (existingRevenueStats.pendingPayments && existingRevenueStats.pendingPayments[slot.time] !== undefined) {
                    revenueStatsData.pendingPayments[slot.time] = existingRevenueStats.pendingPayments[slot.time];
                } else {
                    revenueStatsData.pendingPayments[slot.time] = null;
                }
            });

            await setDoc(revenueStatsRef, revenueStatsData, { merge: true });

            toast.success('Library updated successfully.');
            onUpdate({ id: libraryId, ...updatedData });
            onClose();
        } catch (error) {
            console.error('Error updating library:', error);
            toast.error('Failed to update library.');
        } finally {
            setUpdating(false);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4">
            <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center p-6 border-b">
                    <h2 className="text-2xl font-semibold text-gray-800">Edit Library</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700 transition-colors"
                        aria-label="Close"
                    >
                        <X className="h-6 w-6" />
                    </button>
                </div>
                <form onSubmit={handleSubmit} className="p-6 space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={libraryData.name}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 mb-1">Phone Number</label>
                            <input
                                type="tel"
                                id="phoneNumber"
                                name="phoneNumber"
                                value={libraryData.phoneNumber}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="adminEmail" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                            <input
                                type="email"
                                id="adminEmail"
                                name="adminEmail"
                                value={libraryData.adminEmail}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">Address</label>
                            <input
                                type="text"
                                id="address"
                                name="address"
                                value={libraryData.address}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="district" className="block text-sm font-medium text-gray-700 mb-1">District</label>
                            <input
                                type="text"
                                id="district"
                                name="district"
                                value={libraryData.district}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="state" className="block text-sm font-medium text-gray-700 mb-1">State</label>
                            <input
                                type="text"
                                id="state"
                                name="state"
                                value={libraryData.state}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="country" className="block text-sm font-medium text-gray-700 mb-1">Country</label>
                            <input
                                type="text"
                                id="country"
                                name="country"
                                value={libraryData.country}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="pincode" className="block text-sm font-medium text-gray-700 mb-1">Pincode</label>
                            <input
                                type="text"
                                id="pincode"
                                name="pincode"
                                value={libraryData.pincode}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="totalSeats" className="block text-sm font-medium text-gray-700 mb-1">Total Seats</label>
                            <input
                                type="number"
                                id="totalSeats"
                                name="totalSeats"
                                value={libraryData.totalSeats}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="joinDate" className="block text-sm font-medium text-gray-700 mb-1">Join Date</label>
                            <input
                                type="date"
                                id="joinDate"
                                name="joinDate"
                                value={libraryData.joinDate}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>
                        <div className="md:col-span-2">
                            <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">Description</label>
                            <textarea
                                id="description"
                                name="description"
                                value={libraryData.description}
                                onChange={handleChange}
                                rows={3}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                required
                            ></textarea>
                        </div>
                    </div>

                    <div className="space-y-4">
                        <h3 className="text-lg font-semibold text-gray-800">Slots and Pricing</h3>
                        {libraryData.slots.map((slot, index) => (
                            <div key={index} className="flex items-center space-x-2">
                                <input
                                    type="text"
                                    value={slot.time}
                                    onChange={(e) => handleSlotChange(index, 'time', e.target.value)}
                                    placeholder="Time slot"
                                    className="flex-grow px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    required
                                />
                                <input
                                    type="number"
                                    value={slot.price}
                                    onChange={(e) => handleSlotChange(index, 'price', e.target.value)}
                                    placeholder="Price"
                                    className="w-24 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    required
                                />
                                <button
                                    type="button"
                                    onClick={() => removeSlot(index)}
                                    className="p-2 text-red-500 hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                >
                                    <Trash className="h-5 w-5" />
                                </button>
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={addSlot}
                            className="flex items-center text-blue-600 hover:text-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                            <Plus className="h-5 w-5 mr-1" />
                            Add Slot
                        </button>
                    </div>

                    <div className="flex justify-end space-x-4 pt-4 border-t">
                        <button
                            type="button"
                            className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-colors"
                            onClick={onClose}
                            disabled={updating}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors flex items-center"
                            disabled={updating}
                        >
                            {updating ? (
                                <>
                                    <Loader2 className="animate-spin mr-2 h-4 w-4" />
                                    Updating...
                                </>
                            ) : (
                                'Update'
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditLibrary;