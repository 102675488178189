import { useState, useEffect, useCallback } from 'react';
import { collection, query, where, getDocs, Timestamp, doc, updateDoc, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import dayjs from 'dayjs';

const CACHE_KEY = 'adminDashboardUsers';
const CACHE_EXPIRY = 5 * 60 * 1000; // 5 minutes

export function useOptimizedUsers(libraryId) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getStatusForCycle = useCallback((userData, cycleStartDate, cycleEndDate, currentDate) => {
    const cycleKey = cycleStartDate.format('YYYY-MM');
    if (userData.billingStatus && userData.billingStatus[cycleKey]) {
      return userData.billingStatus[cycleKey];
    }
    
    if (currentDate.isAfter(cycleEndDate)) {
      return 'Overdue';
    }
    
    return 'Unpaid';
  }, []);

  const calculateBillingCycles = useCallback((startDate, currentDate, userData) => {
    const cycles = [];
    
    let cycleStart = startDate.clone().date(startDate.date());
    let cycleEnd = cycleStart.clone().add(1, 'month').subtract(1, 'day');
  
    while (cycleEnd.isBefore(currentDate, 'day')) {
      cycles.push({
        startDate: cycleStart.format('DD MMM'),
        endDate: cycleEnd.format('DD MMM YYYY'),
        status: getStatusForCycle(userData, cycleStart, cycleEnd, currentDate)
      });
      cycleStart = cycleStart.add(1, 'month');
      cycleEnd = cycleStart.clone().add(1, 'month').subtract(1, 'day');
    }
  
    cycles.push({
      startDate: cycleStart.format('DD MMM'),
      endDate: cycleEnd.format('DD MMM YYYY'),
      status: getStatusForCycle(userData, cycleStart, cycleEnd, currentDate)
    });
  
    const nextCycleStart = cycleEnd.add(1, 'day');
    const nextCycleEnd = nextCycleStart.clone().add(1, 'month').subtract(1, 'day');
  
    cycles.push({
      startDate: nextCycleStart.format('DD MMM'),
      endDate: nextCycleEnd.format('DD MMM YYYY'),
      status: userData.billingStatus && userData.billingStatus[nextCycleStart.format('YYYY-MM')] 
        ? userData.billingStatus[nextCycleStart.format('YYYY-MM')] 
        : 'Unpaid'
    });
  
    return cycles;
  }, [getStatusForCycle]);

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const cachedData = localStorage.getItem(CACHE_KEY);
      if (cachedData) {
        const { data, timestamp } = JSON.parse(cachedData);
        if (Date.now() - timestamp < CACHE_EXPIRY) {
          setUsers(data);
          setLoading(false);
          return;
        }
      }

      const libraryDoc = await getDoc(doc(db, 'libraries', libraryId));
      const libraryData = libraryDoc.data();
      const slotPrices = libraryData.slotPrices || {};

      const usersCollection = collection(db, 'users');
      const q = query(usersCollection, where('libraryId', '==', libraryId), where('role', '==', 'user'));
      const usersSnapshot = await getDocs(q);
      const currentDate = dayjs();
      const usersList = usersSnapshot.docs.map((doc) => {
        const data = doc.data();
        const lastBillingDate = data.lastBillingDate instanceof Timestamp ? dayjs(data.lastBillingDate.toDate()) : null;
        const joinDate = data.joinDate instanceof Timestamp ? dayjs(data.joinDate.toDate()) : (data.joinDate ? dayjs(data.joinDate) : null);
        const createdAt = data.createdAt instanceof Timestamp ? dayjs(data.createdAt.toDate()) : (data.createdAt ? dayjs(data.createdAt) : null);
        
        const billingStartDate = joinDate || createdAt || currentDate;

        const billingCycles = calculateBillingCycles(billingStartDate, currentDate, data);

        const monthlyFee = slotPrices[data.selectedSlot] || 0;

        return {
          id: doc.id,
          ...data,
          joinDate: joinDate ? joinDate.format('DD-MM-YYYY') : 'N/A',
          lastBillingDate: lastBillingDate ? lastBillingDate.format('DD-MM-YYYY') : 'N/A',
          billingCycles,
          currentCycleIndex: data.currentCycleIndex !== undefined ? data.currentCycleIndex : billingCycles.findIndex(cycle => 
            currentDate.isSameOrAfter(dayjs(cycle.startDate, 'DD MMM')) && 
            currentDate.isSameOrBefore(dayjs(cycle.endDate, 'DD MMM YYYY'))
          ),
          monthlyFee
        };
      });

      localStorage.setItem(CACHE_KEY, JSON.stringify({ data: usersList, timestamp: Date.now() }));

      setUsers(usersList);
    } catch (error) {
      console.error("Error fetching users:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [libraryId, calculateBillingCycles]);

  useEffect(() => {
    fetchUsers();

    const usersCollection = collection(db, 'users');
    const q = query(usersCollection, where('libraryId', '==', libraryId), where('role', '==', 'user'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "modified" || change.type === "removed") {
          fetchUsers();
        }
      });
    }, (error) => {
      console.error("Error setting up real-time listener:", error);
      setError(error);
    });

    return () => unsubscribe();
  }, [fetchUsers, libraryId]);

  const refetchUsers = useCallback(() => {
    localStorage.removeItem(CACHE_KEY);
    fetchUsers();
  }, [fetchUsers]);

  const updateUser = useCallback(async (updatedUser) => {
    try {
      const userRef = doc(db, 'users', updatedUser.id);
      await updateDoc(userRef, {
        currentCycleIndex: updatedUser.currentCycleIndex,
        billingStatus: updatedUser.billingStatus,
        lastBillingDate: updatedUser.lastBillingDate,
      });
    } catch (error) {
      console.error("Error updating user:", error);
      setError(error);
    }
  }, []);

  return { users, loading, error, refetchUsers, updateUser };
}