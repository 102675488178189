import React from 'react';
import { useLocation } from 'react-router-dom';
import { DarkModeProvider } from './DarkModeContext';

export default function PageLayout({ children }) {
  const location = useLocation();

  // Check if the current path is home or login
  const excludedPaths = ['/', '/login', '/about', '/why-join'];

  if (excludedPaths.includes(location.pathname)) {
    return <>{children}</>;
  }

  // Render DarkModeProvider for all other pages
  return <DarkModeProvider>{children}</DarkModeProvider>;
}
