'use client'

import React, { useState, useEffect } from 'react'
import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail, updateCurrentUser } from 'firebase/auth'
import { doc, setDoc, collection, getDocs, getDoc, updateDoc, deleteField, query, where } from 'firebase/firestore'
import { db, auth } from '../firebase'
import { useAuth } from './AuthContext'
import { FaEye, FaEyeSlash, FaTimes, FaArrowLeft } from 'react-icons/fa'
import { ClipLoader } from 'react-spinners'
import dayjs from 'dayjs'

export default function NewStudent({ onClose, onAddStudent, libraryId, libraryData, approvedRequest = null }) {
  const { libraryName, user: adminUser } = useAuth()
  const [currentPage, setCurrentPage] = useState(approvedRequest ? 1 : 1)
  const [email, setEmail] = useState(approvedRequest?.email || '')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showOverdueModal, setShowOverdueModal] = useState(false)
  const [overdueInfo, setOverdueInfo] = useState(null)
  const [showExistingUserModal, setShowExistingUserModal] = useState(false)
  const [existingUserLibrary, setExistingUserLibrary] = useState('')
  const [studentData, setStudentData] = useState({
    name: approvedRequest?.name || '',
    phoneNumber: approvedRequest?.phoneNumber || '',
    selectedSlot: approvedRequest?.selectedSlot || '',
    selectedSeat: '',
    joinDate: approvedRequest?.createdAt ? dayjs(approvedRequest.createdAt.toDate()).format('YYYY-MM-DD') : '',
    goal: approvedRequest?.goal || '',
    address: approvedRequest?.address || '',
    college: approvedRequest?.collegeName || '',
    lastBillingDate: 'N/A',
    dob: '' // New field for Date of Birth
  })
  const [seatStats, setSeatStats] = useState({
    slots: {},
    totalSeats: 0
  })
  const [slots, setSlots] = useState([])
  const [isExistingUser, setIsExistingUser] = useState(false)
  const [existingUserData, setExistingUserData] = useState(null)
  const [availableSeats, setAvailableSeats] = useState([])

  const fetchSeatStats = async (libraryId) => {
    try {
      const seatStatsDoc = await getDoc(doc(db, 'seat_stats', libraryId))
      if (seatStatsDoc.exists()) {
        const data = seatStatsDoc.data()
        setSeatStats({
          slots: data.slots || {},
          totalSeats: data.totalSeats || 0
        })
      }
    } catch (error) {
      console.error('Error fetching seat stats:', error)
      setErrorMessage('Error fetching seat data. Please try again.')
    }
  }

  useEffect(() => {
    const fetchLibraryData = async () => {
      try {
        if (libraryData) {
          setSlots(libraryData.slots || [])
        } else {
          const libraryDoc = await getDoc(doc(db, 'libraries', libraryId))
          if (libraryDoc.exists()) {
            const fetchedLibraryData = libraryDoc.data()
            setSlots(fetchedLibraryData.slots || [])
          }
        }

        await fetchSeatStats(libraryId)

        if (approvedRequest && approvedRequest.selectedSlot) {
          const newAvailableSeats = calculateAvailableSeats(approvedRequest.selectedSlot)
          setAvailableSeats(newAvailableSeats)
        }
      } catch (error) {
        console.error('Error fetching library data:', error)
        setErrorMessage('Error fetching library data. Please try again.')
      }
    }

    fetchLibraryData()
  }, [libraryId, libraryData, approvedRequest])

  const calculateAvailableSeats = (selectedSlot) => {
    if (!selectedSlot || !seatStats.slots) return []

    const allOccupiedSeats = new Set()

    if (seatStats.slots['full day']) {
      seatStats.slots['full day'].forEach(seat => allOccupiedSeats.add(seat))
    }

    if (selectedSlot === 'full day') {
      Object.values(seatStats.slots).forEach(seats => {
        seats.forEach(seat => allOccupiedSeats.add(seat))
      })
    } else {
      if (seatStats.slots[selectedSlot]) {
        seatStats.slots[selectedSlot].forEach(seat => allOccupiedSeats.add(seat))
      }
    }

    return Array.from({ length: seatStats.totalSeats }, (_, i) => (i + 1).toString())
      .filter(seat => !allOccupiedSeats.has(seat))
  }

  const handleEmailSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setErrorMessage('')

    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email)
      const { exists, userData, userHistoryData } = await checkExistingUser(email)

      if (signInMethods.length > 0 || exists) {
        if (userData.libraryId === libraryId) {
          setErrorMessage(`User is already registered with your library ${libraryName}.`)
          setLoading(false)
          return
        }

        const overdueInfo = checkForOverduePayments(userHistoryData)
        if (overdueInfo) {
          const overdueMessage = await formatOverdueMessage(overdueInfo)
          setOverdueInfo(overdueMessage)
          setShowOverdueModal(true)
        } else {
          setExistingUserLibrary(userData.libraryName || 'another library')
          setShowExistingUserModal(true)
        }
        setIsExistingUser(true)
        setExistingUserData(userData)
      } else {
        setCurrentPage(2)
        setIsExistingUser(false)
      }
    } catch (error) {
      console.error("Error checking user:", error)
      setErrorMessage('An error occurred. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  const checkExistingUser = async (email) => {
    const userQuery = query(collection(db, 'users'), where('email', '==', email))
    const userSnapshot = await getDocs(userQuery)
    if (!userSnapshot.empty) {
      const userData = userSnapshot.docs[0].data()
      const userId = userSnapshot.docs[0].id
      const userHistoryDoc = await getDoc(doc(db, 'userHistory', userId))
      const userHistoryData = userHistoryDoc.exists() ? userHistoryDoc.data() : null
      return { exists: true, userData: { ...userData, id: userId }, userHistoryData }
    }
    return { exists: false }
  }

  const checkForOverduePayments = (userHistoryData) => {
    if (!userHistoryData) return null

    const checkLibrary = (library) => {
      if (!library || !library.billingStatus) return null
      const overdueMonths = Object.entries(library.billingStatus)
        .filter(([_, status]) => status === 'Overdue')
        .map(([month]) => month)
      return overdueMonths.length > 0 ? { libraryId: library.libraryId, overdueMonths } : null
    }

    const currentLibraryOverdue = checkLibrary(userHistoryData.currentLibrary)
    if (currentLibraryOverdue) return currentLibraryOverdue

    for (const prevLibrary of userHistoryData.previousLibraries || []) {
      const prevLibraryOverdue = checkLibrary(prevLibrary)
      if (prevLibraryOverdue) return prevLibraryOverdue
    }

    return null
  }

  const formatOverdueMessage = async (overdueInfo) => {
    if (!overdueInfo) return ''

    try {
      const libraryDoc = await getDoc(doc(db, 'libraries', overdueInfo.libraryId))
      const libraryName = libraryDoc.exists() ? libraryDoc.data().name : 'Unknown Library'

      const formattedMonths = overdueInfo.overdueMonths.map(month => 
        dayjs(month, 'YYYY-MM').format('MMMM YYYY')
      ).join(', ')

      return `User has overdue payments in ${libraryName} for the following billing cycle(s): ${formattedMonths}`
    } catch (error) {
      console.error("Error formatting overdue message:", error)
      return "User has overdue payments in a previous library."
    }
  }

  const handleExistingUserConfirm = () => {
    setStudentData(prevData => ({
      ...prevData,
      name: existingUserData.name || '',
      phoneNumber: existingUserData.phoneNumber || '',
      address: existingUserData.address || '',
      college: existingUserData.college || '',
      goal: existingUserData.goal || '',
      lastBillingDate: 'N/A',
      dob: existingUserData.dob || '' // Include DOB for existing users
    }))

    setShowExistingUserModal(false)
    setCurrentPage(2)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setStudentData(prevData => ({
      ...prevData,
      [name]: value,
    }))

    if (name === 'selectedSlot') {
      const newAvailableSeats = calculateAvailableSeats(value)
      setAvailableSeats(newAvailableSeats)
      setStudentData(prevData => ({
        ...prevData,
        selectedSeat: '',
      }))
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setErrorMessage('')

    try {
      let userId
      const currentUser = auth.currentUser

      if (isExistingUser) {
        userId = existingUserData.id
        await updateDoc(doc(db, 'users', userId), {
          ...studentData,
          email,
          libraryId,
          updatedAt: new Date(),
          lastBillingDate: 'N/A',
        })

        const userHistoryDoc = await getDoc(doc(db, 'userHistory', userId))
        const userHistoryData = userHistoryDoc.exists() ? userHistoryDoc.data() : null
        const updatedUserHistory = updateUserHistory(userHistoryData)
        await setDoc(doc(db, 'userHistory', userId), updatedUserHistory)
        await updateDoc(doc(db, 'users', userId), {
          billingStatus: deleteField()
        })
      } else {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password)
        userId = userCredential.user.uid

        await auth.updateCurrentUser(currentUser)

        await setDoc(doc(db, 'users', userId), {
          ...studentData,
          email,
          libraryId,
          createdAt: new Date(),
          role: 'user',
          status: 'Unpaid',
          lastBillingDate: 'N/A',
        })

        const newUserHistory = {
          userId,
          currentLibrary: {
            libraryId,
            joinDate: dayjs().format('YYYY-MM-DD'),
            billingStatus: {
              [dayjs().format('YYYY-MM')]: 'Pending'
            }
          },
          previousLibraries: []
        }
        await setDoc(doc(db, 'userHistory', userId), newUserHistory)
      }

      await updateSeatStats(libraryId, studentData.selectedSeat, studentData.selectedSlot)

      onAddStudent({
        ...studentData,
        id: userId,
        email,
        number: 1,
        joinDate: new Date().toLocaleDateString(),
        billingDate: new Date(new Date().setMonth(new Date().getMonth() + 1)).toLocaleDateString(),
        lastBillingDate: 'N/A',
      })

      onClose()
    } catch (error) {
      console.error("Error creating/updating user:", error)
      setErrorMessage('Error creating/updating user. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  const updateUserHistory = (existingHistory) => {
    if (!existingHistory || !existingHistory.userId) {
      throw new Error("Invalid user history data")
    }

    const currentDate = dayjs().format('YYYY-MM-DD')
    return {
      userId: existingHistory.userId,
      currentLibrary: {
        libraryId: libraryId,
        joinDate: currentDate,
        billingStatus: {
          [dayjs().format('YYYY-MM')]: 'Pending'
        }
      },
      previousLibraries: [
        ...(existingHistory.previousLibraries || []),
        existingHistory.currentLibrary ? {
          ...existingHistory.currentLibrary,
          exitDate: currentDate
        } : null
      ].filter(Boolean)
    }
  }

  const updateSeatStats = async (libraryId, newSeat, newSlot) => {
    try {
      const seatStatsDocRef = doc(db, 'seat_stats', libraryId)
      const seatStatsDoc = await getDoc(seatStatsDocRef)

      if (seatStatsDoc.exists()) {
        const seatStats = seatStatsDoc.data()
        let updatedSlots = { ...seatStats.slots }

        if (newSlot && newSeat) {
          updatedSlots[newSlot] = [...(updatedSlots[newSlot] || [])]
          if (!updatedSlots[newSlot].includes(newSeat)) {
            updatedSlots[newSlot].push(newSeat)
          }
        }

        await updateDoc(seatStatsDocRef, { slots: updatedSlots })
      } else {
        await setDoc(seatStatsDocRef, { 
          slots: { 
            [newSlot]: [newSeat] 
          },
          totalSeats: seatStats.totalSeats
        })
      }
    } catch (error) {
      console.error("Error updating seat stats:", error)
      throw error
    }
  }

  const inputStyle = "mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md text-sm shadow-sm placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 dark:text-white"
  const buttonStyle = "w-full px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50 dark:bg-blue-600 dark:hover:bg-blue-700"
  const backButtonStyle = "px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600"

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 dark:bg-opacity-70 z-50 p-4 overflow-y-auto">
      <div className="bg-white dark:bg-gray-900 rounded-lg shadow-xl p-4 sm:p-6 w-full max-w-lg">
        <div className="flex justify-between items-center mb-4 sm:mb-6">
          <h2 className="text-xl sm:text-2xl font-bold text-gray-800 dark:text-gray-200">Add New Student</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200">
            <FaTimes size={24} />
          </button>
        </div>

        {currentPage === 1 && (
          <form onSubmit={handleEmailSubmit} className="space-y-4 sm:space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Email</label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className={inputStyle}
              />
            </div>
            {!isExistingUser && (
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Password</label>
                <div className="relative">
                  <input
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className={inputStyle}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-500 dark:text-gray-400"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                  Password must be at least 6 characters long
                </p>
              </div>
            )}
            {errorMessage && <p className="text-red-500 dark:text-red-400 text-sm">{errorMessage}</p>}
            <button 
              type="submit" 
              disabled={loading || (!isExistingUser && password.length < 6)} 
              className={buttonStyle}
            >
              {loading ? <ClipLoader size={20} color="#ffffff" /> : 'Next'}
            </button>
          </form>
        )}

        {currentPage === 2 && (
          <form onSubmit={(e) => { e.preventDefault(); setCurrentPage(3); }} className="space-y-4 sm:space-y-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Name</label>
              <input
                id="name"
                name="name"
                value={studentData.name}
                onChange={handleChange}
                required
                className={inputStyle}
              />
            </div>
            <div>
              <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Phone Number</label>
              <input
                id="phoneNumber"
                name="phoneNumber"
                value={studentData.phoneNumber}
                onChange={handleChange}
                required
                className={inputStyle}
              />
            </div>
            <div>
              <label htmlFor="address" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Address</label>
              <input
                id="address"
                name="address"
                value={studentData.address}
                onChange={handleChange}
                required
                className={inputStyle}
              />
            </div>
            <div>
              <label htmlFor="dob" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Date of Birth</label>
              <input
                id="dob"
                name="dob"
                type="date"
                value={studentData.dob}
                onChange={handleChange}
                required
                className={inputStyle}
              />
            </div>
            <div className="flex justify-between">
              <button type="button" onClick={() => setCurrentPage(1)} className={backButtonStyle}>
                <FaArrowLeft className="inline mr-2" /> Back
              </button>
              <button type="submit" className={buttonStyle}>Next</button>
            </div>
          </form>
        )}

        {currentPage === 3 && (
          <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
            <div>
              <label htmlFor="goal" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Preparing for</label>
              <input
                id="goal"
                name="goal"
                value={studentData.goal}
                onChange={handleChange}
                placeholder="UPSC, SSC, JEE, NEET, etc."
                className={inputStyle}
              />
            </div>
            <div>
              <label htmlFor="college" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">College</label>
              <input
                id="college"
                name="college"
                value={studentData.college}
                onChange={handleChange}
                className={inputStyle}
              />
            </div>
            <div>
              <label htmlFor="selectedSlot" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Select Slot</label>
              <select
                id="selectedSlot"
                name="selectedSlot"
                value={studentData.selectedSlot}
                onChange={handleChange}
                required
                className={inputStyle}
              >
                <option value="">Select a slot</option>
                {slots.map((slot, index) => (
                  <option key={index} value={slot.time}>
                    {`${slot.time} - ₹${slot.price}`}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="selectedSeat" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Select Seat</label>
              <select
                id="selectedSeat"
                name="selectedSeat"
                value={studentData.selectedSeat}
                onChange={handleChange}
                required
                className={inputStyle}
                disabled={!studentData.selectedSlot}
              >
                <option value="">Select a seat</option>
                {availableSeats.map((seat) => (
                  <option key={seat} value={seat}>
                    {seat}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="joinDate" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Join Date</label>
              <input
                id="joinDate"
                name="joinDate"
                type="date"
                value={studentData.joinDate}
                onChange={handleChange}
                required
                className={inputStyle}
              />
            </div>
            {errorMessage && <p className="text-red-500 dark:text-red-400 text-sm">{errorMessage}</p>}
            <div className="flex justify-between">
              <button type="button" onClick={() => setCurrentPage(2)} className={backButtonStyle}>
                <FaArrowLeft className="inline mr-2" /> Back
              </button>
              <button type="submit" disabled={loading} className={buttonStyle}>
                {loading ? <ClipLoader size={20} color="#ffffff" /> : 'Submit'}
              </button>
            </div>
          </form>
        )}

        {showOverdueModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 dark:bg-opacity-70 flex items-center justify-center z-50 p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl p-4 sm:p-6 w-full max-w-md">
              <h2 className="text-lg sm:text-xl font-bold mb-4 text-gray-800 dark:text-gray-200">Overdue Payments</h2>
              <p className="mb-4 text-sm sm:text-base text-gray-700 dark:text-gray-300">{overdueInfo}</p>
              <p className="mb-4 text-sm sm:text-base text-gray-700 dark:text-gray-300">The user cannot be registered in a new library until overdue payments are settled.</p>
              <button
                onClick={() => setShowOverdueModal(false)}
                className={buttonStyle}
              >
                Close
              </button>
            </div>
          </div>
        )}

        {showExistingUserModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 dark:bg-opacity-70 flex items-center justify-center z-50 p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl p-4 sm:p-6 w-full max-w-md">
              <h2 className="text-lg sm:text-xl font-bold mb-4 text-gray-800 dark:text-gray-200">Existing User</h2>
              <p className="mb-4 text-sm sm:text-base text-gray-700 dark:text-gray-300">
                This user is already registered with {existingUserLibrary}. Would you like to admit this student to your library?
              </p>
              {errorMessage && <p className="text-red-500 dark:text-red-400 text-sm mb-4">{errorMessage}</p>}
              <div className="flex justify-end space-x-4">
                <button
                  onClick={() => setShowExistingUserModal(false)}
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600"
                >
                  Cancel
                </button>
                <button
                  onClick={handleExistingUserConfirm}
                  disabled={loading}
                  className={buttonStyle}
                >
                  {loading ? <ClipLoader size={20} color="#ffffff" /> : 'Confirm'}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}