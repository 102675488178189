import React, { useState } from 'react';
import { ClipLoader } from 'react-spinners';

export default function StatusChangeModal({ isOpen, onClose, onConfirm, userName, currentStatus, newStatus }) {
    const [loading, setLoading] = useState(false);

    if (!isOpen) return null;

    const handleConfirm = async () => {
        setLoading(true);
        try {
            await onConfirm();
        } catch (error) {
            console.error('Error during status change:', error);
        } finally {
            setLoading(false);
            onClose();
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-black bg-opacity-50 dark:bg-opacity-70 absolute inset-0"></div>
            <div className="bg-white dark:bg-gray-800 rounded-lg p-8 z-10 shadow-lg max-w-sm w-full">
                <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">Confirm Status Change</h2>
                <p className="mb-6 text-gray-600 dark:text-gray-300">
                    Are you sure you want to change the status for <strong className="text-gray-800 dark:text-gray-200">{userName}</strong> from{' '}
                    <strong className="text-gray-800 dark:text-gray-200">{currentStatus}</strong> to <strong className="text-gray-800 dark:text-gray-200">{newStatus}</strong>?
                </p>
                <div className="flex justify-end">
                    <button
                        className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md mr-2 hover:bg-gray-400 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        className={`px-4 py-2 rounded-md text-white ${
                            loading ? 'bg-blue-500 dark:bg-blue-600' : 'bg-blue-600 dark:bg-blue-700'
                        } ${
                            loading ? 'hover:bg-blue-600 dark:hover:bg-blue-700' : 'hover:bg-blue-700 dark:hover:bg-blue-800'
                        }`}
                        onClick={handleConfirm}
                        disabled={loading}
                    >
                        {loading ? <ClipLoader size={20} color="#fff" /> : 'Confirm'}
                    </button>
                </div>
            </div>
        </div>
    );
}