import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaSearch, FaStar, FaMapMarkerAlt, FaBook, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import statesData from '../data/States.json';

const LIBRARIES_PER_PAGE = 9;
const CACHE_EXPIRATION = 5 * 60 * 1000; // 5 minutes

const Libraries = () => {
  const [allLibraries, setAllLibraries] = useState([]);
  const [displayedLibraries, setDisplayedLibraries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    state: '',
    district: '',
    pincode: '',
  });
  const [districts, setDistricts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [cachedLibraries, setCachedLibraries] = useState({});

  const fetchLibraries = useCallback(async () => {
    setLoading(true);
    const cacheKey = `${filters.state}-${filters.district}-${filters.pincode}`;
    const now = Date.now();
    
    if (cachedLibraries[cacheKey] && (now - cachedLibraries[cacheKey].timestamp) < CACHE_EXPIRATION) {
      setAllLibraries(cachedLibraries[cacheKey].data);
      setLoading(false);
      return;
    }

    let q = query(collection(db, 'libraries'), orderBy('name'));

    if (filters.state) {
      q = query(q, where('state', '==', filters.state));
    }

    if (filters.district) {
      q = query(q, where('district', '==', filters.district));
    }

    if (filters.pincode) {
      q = query(q, where('pincode', '==', filters.pincode));
    }

    const querySnapshot = await getDocs(q);
    const librariesData = await Promise.all(querySnapshot.docs.map(async (doc) => {
      const libraryData = {
        id: doc.id,
        ...doc.data(),
        rating: 0,
        reviewCount: 0,
        slots: doc.data().slots || [],
      };

      // Fetch reviews for this library
      const reviewsQuery = query(
        collection(db, 'reviews'),
        where('libraryId', '==', doc.id),
        orderBy('timestamp', 'desc')
      );
      const reviewsSnapshot = await getDocs(reviewsQuery);
      const reviews = reviewsSnapshot.docs.map(reviewDoc => ({
        id: reviewDoc.id,
        ...reviewDoc.data(),
      }));

      // Calculate average rating and review count
      if (reviews.length > 0) {
        libraryData.rating = reviews.reduce((sum, review) => sum + review.rating, 0) / reviews.length;
        libraryData.reviewCount = reviews.length;
      }

      return libraryData;
    }));

    setAllLibraries(librariesData);

    setCachedLibraries(prev => ({
      ...prev,
      [cacheKey]: {
        data: librariesData,
        timestamp: now,
      },
    }));

    setLoading(false);
  }, [filters, cachedLibraries]);

  useEffect(() => {
    fetchLibraries();
  }, [filters, fetchLibraries]);

  useEffect(() => {
    if (filters.state) {
      const stateData = statesData.states.find(state => state.state === filters.state);
      setDistricts(stateData ? stateData.districts : []);
    } else {
      setDistricts([]);
    }
  }, [filters.state]);

  const filteredLibraries = useMemo(() => 
    allLibraries.filter(library =>
      library.name.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    [allLibraries, searchTerm]
  );

  useEffect(() => {
    const totalFilteredLibraries = filteredLibraries.length;
    const calculatedTotalPages = Math.ceil(totalFilteredLibraries / LIBRARIES_PER_PAGE);
    setTotalPages(calculatedTotalPages);
    setPage(1); // Reset to first page when search term changes
  }, [filteredLibraries]);

  useEffect(() => {
    const startIndex = (page - 1) * LIBRARIES_PER_PAGE;
    const endIndex = startIndex + LIBRARIES_PER_PAGE;
    setDisplayedLibraries(filteredLibraries.slice(startIndex, endIndex));
  }, [filteredLibraries, page]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => {
      const newFilters = { ...prevFilters, [filterName]: value };
      if (filterName === 'state') {
        newFilters.district = '';
      }
      return newFilters;
    });
    setPage(1);
    setSearchTerm('');
  };

  const LibraryCard = React.memo(({ library }) => (
    <motion.div
      className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
      whileHover={{ scale: 1.03 }}
      whileTap={{ scale: 0.98 }}
      layout
    >
      <Link to={`/library/${library.id}`} className="block h-full">
        <div className="relative h-48">
          {library.imageUrl ? (
            <img
              src={library.imageUrl}
              alt={library.name}
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
              <FaBook className="text-6xl text-gray-400 dark:text-gray-500" />
            </div>
          )}
          <div className="absolute top-2 right-2 bg-white dark:bg-gray-800 px-2 py-1 rounded-full shadow">
            <div className="flex items-center">
              <FaStar className="text-yellow-400 mr-1" />
              <span className="text-sm font-semibold dark:text-white">{library.rating.toFixed(1)}</span>
            </div>
          </div>
        </div>
        <div className="p-4">
          <h3 className="text-lg font-semibold mb-2 truncate dark:text-white">{library.name}</h3>
          <p className="text-gray-600 dark:text-gray-300 text-sm mb-2 line-clamp-2">{library.description || 'No description available.'}</p>
          <div className="flex items-center text-sm text-gray-500 dark:text-gray-400 mb-2">
            <FaMapMarkerAlt className="mr-1" />
            <span className="truncate">{library.address || 'Location not available'}</span>
          </div>
          <div className="flex items-center text-sm text-gray-500 dark:text-gray-400 mb-2">
            <FaStar className="mr-1 text-yellow-400" />
            <span>{library.rating.toFixed(1)} ({library.reviewCount} reviews)</span>
          </div>
          <div className="flex flex-wrap gap-1">
            {library.slots.slice(0, 3).map((slot, index) => (
              <span key={index} className="bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 text-xs font-medium px-2 py-0.5 rounded-full">
                {slot.time}
              </span>
            ))}
            {library.slots.length > 3 && (
              <span className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 text-xs font-medium px-2 py-0.5 rounded-full">
                +{library.slots.length - 3} more
              </span>
            )}
          </div>
        </div>
      </Link>
    </motion.div>
  ));

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold text-gray-800 dark:text-white mb-8">Discover Libraries</h1>
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Sidebar */}
          <div className="w-full lg:w-1/4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 sticky top-4">
              <h2 className="text-xl font-semibold mb-4 dark:text-white">Filters</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">State</label>
                  <select
                    className="w-full h-8 border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-700 dark:text-white"
                    value={filters.state}
                    onChange={(e) => handleFilterChange('state', e.target.value)}
                  >
                    <option value="">Select State</option>
                    {statesData.states.map((state) => (
                      <option key={state.state} value={state.state}>
                        {state.state}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">District</label>
                  <select
                    className="w-full h-8 border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-700 dark:text-white"
                    value={filters.district}
                    onChange={(e) => handleFilterChange('district', e.target.value)}
                    disabled={!filters.state}
                  >
                    <option value="">Select District</option>
                    {districts.map((district) => (
                      <option key={district} value={district}>
                        {district}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Pincode</label>
                  <input
                    type="text"
                    className="w-full h-8 border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-700 dark:text-white"
                    value={filters.pincode}
                    onChange={(e) => handleFilterChange('pincode', e.target.value)}
                    placeholder="Enter pincode"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="w-full lg:w-3/4">
            <div className="mb-6">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search libraries..."
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-gray-700 dark:text-white"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FaSearch className="text-gray-400 dark:text-gray-500" />
                </div>
              </div>
            </div>

            <AnimatePresence>
              <motion.div 
                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
                layout
              >
                {displayedLibraries.map((library) => (
                  <LibraryCard key={library.id} library={library} />
                ))}
              </motion.div>
            </AnimatePresence>

            {displayedLibraries.length === 0 && !loading && (
              <div className="text-center py-12">
                <p className="text-xl text-gray-600 dark:text-gray-400">No libraries found matching your criteria.</p>
              </div>
            )}

            {loading && (
              <div className="text-center py-12">
                <p className="text-xl text-gray-600 dark:text-gray-400">Loading libraries...</p>
              </div>
            )}

            <div className="flex justify-center items-center mt-8">
              <button
                className="mr-2 px-3 py-1 rounded-md bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-300 disabled:opacity-50"
                onClick={() => setPage(prev => Math.max(prev - 1, 1))}
                disabled={page === 1}
              >
                <FaChevronLeft />
              </button>
              <span className="mx-2 text-gray-600 dark:text-gray-300">
                Page {page} of {totalPages}
              </span>
              <button
                className="ml-2 px-3 py-1 rounded-md bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-300 disabled:opacity-50"
                onClick={() => setPage(prev => Math.min(prev + 1, totalPages))}
                disabled={page === totalPages}
              >
                <FaChevronRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Libraries;