import React, { useState, useEffect } from 'react';

const ImageCarousel = () => {
    const images = [
        'image1.png',
        'image2.png',
        'image3.png',
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval);
    }, [images.length]);

    // Calculate positions and opacity
    const getOpacity = (index) => {
        if (index === currentIndex) return 1; // Center image (full opacity)
        if (index === (currentIndex + 1) % images.length || index === (currentIndex - 1 + images.length) % images.length) return 0.5; // Side images (reduced opacity)
        return 0.2; // All other images (low opacity)
    };

    const getTransform = (index) => {
        if (index === currentIndex) return 'translateX(0%) scale(1)';
        if (index === (currentIndex + 1) % images.length) return 'translateX(100%) scale(0.9)';
        if (index === (currentIndex - 1 + images.length) % images.length) return 'translateX(-100%) scale(0.9)';
        return 'translateX(200%) scale(0)'; // Move off-screen and scale down for all other images
    };

    return (
        <div className="relative w-full h-96 mt-20 overflow-hidden flex justify-center items-center">
            {images.map((image, index) => (
                <div
                    key={index}
                    className="absolute transition-transform duration-1000 ease-in-out"
                    style={{
                        transform: getTransform(index),
                        opacity: getOpacity(index),
                        transition: 'opacity 1s ease, transform 1s ease',
                    }}
                >
                    <img
                        src={`/images/${image}`}
                        alt={`Slide ${index + 1}`}
                        className="w-80 h-80 object-cover rounded-lg"
                    />
                </div>
            ))}
        </div>
    );
};

export default ImageCarousel;
