import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTimes } from 'react-icons/fa';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { toast } from 'react-toastify';
import statesData from '../data/States.json';

const JoinTlh = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    country: '',
    state: '',
    district: '',
    pincode: '',
    address: '',
    phoneNumber: '',
    totalSeats: '',
    description: '',
    email: '',
  });

  const [districts, setDistricts] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));

    if (name === 'state') {
      const selectedState = statesData.states.find(state => state.state === value);
      setDistricts(selectedState ? selectedState.districts : []);
      setFormData(prevData => ({ ...prevData, district: '' }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await addDoc(collection(db, 'request_lib'), {
        ...formData,
        createdAt: new Date()
      });
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error submitting request:', error);
      toast.error('Failed to submit request. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const inputClass = "mt-1 block w-full px-4 py-3 bg-gray-50 text-gray-900 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-150 ease-in-out";
  const labelClass = "block text-sm font-medium text-gray-700 mb-1";

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          onClick={onClose}
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-white rounded-xl shadow-2xl p-8 max-w-4xl w-full relative max-h-[90vh] overflow-y-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors duration-200"
              onClick={onClose}
              aria-label="Close"
            >
              <FaTimes className="w-6 h-6" />
            </button>
            {!isSubmitted ? (
              <>
                <h2 className="text-4xl font-bold mb-6 text-gray-800 text-center">Join The Library Hub</h2>
                <p className="text-gray-600 mb-8 text-center text-lg">
                  Fill out the form below to join The Library Hub and start expanding your reach!
                </p>
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label htmlFor="name" className={labelClass}>
                        Library Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={inputClass}
                        required
                        placeholder="Enter library name"
                      />
                    </div>
                    <div>
                      <label htmlFor="email" className={labelClass}>
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={inputClass}
                        required
                        placeholder="Enter email address"
                      />
                    </div>
                    <div>
                      <label htmlFor="country" className={labelClass}>
                        Country
                      </label>
                      <input
                        type="text"
                        id="country"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        className={inputClass}
                        required
                        placeholder="Enter country"
                      />
                    </div>
                    <div>
                      <label htmlFor="state" className={labelClass}>
                        State
                      </label>
                      <select
                        id="state"
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                        className={`${inputClass} appearance-none`}
                        required
                      >
                        <option value="">Select State</option>
                        {statesData.states.map((state, index) => (
                          <option key={index} value={state.state}>
                            {state.state}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label htmlFor="district" className={labelClass}>
                        District
                      </label>
                      <select
                        id="district"
                        name="district"
                        value={formData.district}
                        onChange={handleChange}
                        className={`${inputClass} appearance-none`}
                        required
                        disabled={!districts.length}
                      >
                        <option value="">Select District</option>
                        {districts.map((district, index) => (
                          <option key={index} value={district}>
                            {district}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label htmlFor="pincode" className={labelClass}>
                        Pincode
                      </label>
                      <input
                        type="text"
                        id="pincode"
                        name="pincode"
                        value={formData.pincode}
                        onChange={handleChange}
                        className={inputClass}
                        required
                        placeholder="Enter pincode"
                      />
                    </div>
                    <div>
                      <label htmlFor="phoneNumber" className={labelClass}>
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        id="phoneNumber"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        className={inputClass}
                        required
                        placeholder="Enter phone number"
                      />
                    </div>
                    <div>
                      <label htmlFor="totalSeats" className={labelClass}>
                        Total Seats
                      </label>
                      <input
                        type="number"
                        id="totalSeats"
                        name="totalSeats"
                        value={formData.totalSeats}
                        onChange={handleChange}
                        className={inputClass}
                        required
                        placeholder="Enter total seats"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="address" className={labelClass}>
                      Address
                    </label>
                    <textarea
                      id="address"
                      name="address"
                      rows="3"
                      value={formData.address}
                      onChange={handleChange}
                      className={inputClass}
                      required
                      placeholder="Enter full address"
                    ></textarea>
                  </div>
                  <div>
                    <label htmlFor="description" className={labelClass}>
                      Description
                    </label>
                    <textarea
                      id="description"
                      name="description"
                      rows="4"
                      value={formData.description}
                      onChange={handleChange}
                      className={inputClass}
                      required
                      placeholder="Describe your library"
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold py-3 px-4 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed text-lg"
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit Request'}
                  </button>
                </form>
              </>
            ) : (
              <div className="text-center py-8">
                <h2 className="text-3xl font-bold mb-6 text-gray-800">Thank You for Your Interest!</h2>
                <p className="text-gray-600 mb-6">
                  We appreciate your interest in joining The Library Hub. Our team will contact you soon to verify the details provided. Once verified, your library will be listed on The Library Hub.
                </p>
                <p className="text-gray-600 mb-6">
                  We have implemented this verification process to ensure we list only authenticated libraries like yours. This helps maintain the quality and reliability of our platform.
                </p>
                <button
                  onClick={onClose}
                  className="bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold py-3 px-6 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 text-lg"
                >
                  Close
                </button>
              </div>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default JoinTlh;