import React, { useState, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { IdCard } from 'lucide-react';
import QRCode from 'qrcode';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { toast } from 'react-toastify';

export default function IdCardGenerator({ user, libraryId }) {
  const [libraryDetails, setLibraryDetails] = useState({
    name: '',
    address: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchLibraryDetails = async () => {
      setIsLoading(true);
      try {
        const libraryDoc = await getDoc(doc(db, 'libraries', libraryId));
        if (libraryDoc.exists()) {
          const data = libraryDoc.data();
          setLibraryDetails({
            name: data.name || '',
            address: data.address || ''
          });
        } else {
          console.error("Library not found");
          toast.error("Library details not found");
        }
      } catch (error) {
        console.error("Error fetching library details:", error);
        toast.error("Failed to fetch library details");
      } finally {
        setIsLoading(false);
      }
    };

    fetchLibraryDetails();
  }, [libraryId]);

  const generateIdCard = async () => {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: [54, 86] // Standard ID card size
    });

    // Helper functions
    const centerText = (text, y, fontSize, font = 'normal', color = '#FFFFFF') => {
      if (typeof text !== 'string' || text.length === 0) {
        console.warn('Invalid text provided to centerText:', text);
        return;
      }
      doc.setFont('helvetica', font);
      doc.setFontSize(fontSize);
      doc.setTextColor(color);
      const textWidth = doc.getTextWidth(text);
      const textOffset = (86 - textWidth) / 2;
      doc.text(text, textOffset, y);
    };

    const safeAddText = (text, x, y, fontSize, font = 'normal', color = '#FFFFFF') => {
      if (typeof text !== 'string' || text.length === 0) {
        console.warn('Invalid text provided to safeAddText:', text);
        return;
      }
      doc.setFont('helvetica', font);
      doc.setFontSize(fontSize);
      doc.setTextColor(color);
      doc.text(text, x, y);
    };

    // Front side
    const createGradientBackground = (colors, reverse = false) => {
      for (let i = 0; i < 54; i++) {
        const ratio = reverse ? 1 - i / 54 : i / 54;
        const r = Math.floor(colors[0].r + (colors[1].r - colors[0].r) * ratio);
        const g = Math.floor(colors[0].g + (colors[1].g - colors[0].g) * ratio);
        const b = Math.floor(colors[0].b + (colors[1].b - colors[0].b) * ratio);
        doc.setFillColor(r, g, b);
        doc.rect(0, i, 86, 1, 'F');
      }
    };

    // Premium gradient background
    const gradientColors = [
      { r: 20, g: 40, b: 100 },
      { r: 60, g: 80, b: 160 }
    ];
    createGradientBackground(gradientColors);

    // Add a subtle pattern overlay for texture
    for (let i = 0; i < 86; i += 1.2) {
      for (let j = 0; j < 54; j += 1.2) {
        doc.setFillColor(255, 255, 255, 0.02);
        doc.circle(i, j, 0.08, 'F');
      }
    }

    // Enhanced header with library name
    doc.setFillColor(255, 255, 255, 0.15);
    // doc.roundedRect(2, 2, 82, 12, 3, 3, 'F');
    
    // Split library name if too long
    const maxLineLength = 25;
    let libraryName = libraryDetails.name || 'Library Name';
    if (libraryName.length > maxLineLength) {
      const midpoint = libraryName.lastIndexOf(' ', maxLineLength);
      const line1 = libraryName.substring(0, midpoint);
      const line2 = libraryName.substring(midpoint + 1);
      centerText(line1, 7, 12, 'bold', '#FFFFFF');
      centerText(line2, 11, 12, 'bold', '#FFFFFF');
    } else {
      centerText(libraryName, 9, 14, 'bold', '#FFFFFF');
    }

    // Add decorative line below header
    doc.setDrawColor(255, 255, 255, 0.6);
    doc.setLineWidth(0.3);
    doc.line(5, 14, 81, 14);

    //photo placeholder
    doc.setDrawColor(255, 255, 255);
    doc.setFillColor(255, 253, 208);
    doc.roundedRect(5, 16, 22, 28, 3, 3, 'FD');
    //subtle photo frame design
    doc.setDrawColor('#FFFFFF');
    doc.setLineWidth(0.4);
    doc.roundedRect(4.5, 15.5, 23, 29, 3, 3);
    
    // User details
    safeAddText(user.name || 'User Name', 30, 22, 14, 'bold');
    
    //small divider line under name
    doc.setDrawColor('#FFFFFF');
    doc.setLineWidth(0.3);
    doc.line(30, 24, 81, 24);

    // User details with icons
    doc.setLineWidth(0.2);
    safeAddText(`ID: ${user.id || 'N/A'}`, 30, 29, 8);
    safeAddText(`Email: ${user.email || 'N/A'}`, 30, 34, 8);
    safeAddText(`Phone: ${user.phoneNumber || 'N/A'}`, 30, 39, 8);
    // safeAddText(`Valid Until: ${user.validUntil || 'N/A'}`, 30, 44, 8);

    //footer with website
    doc.setFillColor(255, 255, 255, 0.2);
    // doc.roundedRect(2, 48, 82, 4, 2, 2, 'F');
    doc.setFontSize(7);
    centerText('TheLibraryHub.in', 50.5, 7, 'normal', '#FFFFFF');

    // Back side
    doc.addPage();

    // bg
    createGradientBackground(gradientColors, true);

    //texture overlay
    for (let i = 0; i < 86; i += 1.2) {
      for (let j = 0; j < 54; j += 1.2) {
        doc.setFillColor(255, 255, 255, 0.02);
        doc.circle(i, j, 0.08, 'F');
      }
    }

    //Library Information header
    doc.setFillColor(255, 255, 255, 0.15);
    // doc.roundedRect(2, 2, 82, 8, 3, 3, 'F');
    centerText('Library Information', 7.5, 12, 'bold', '#FFFFFF');

    // Enhanced content layout
    safeAddText(`Library: ${libraryDetails.name || 'N/A'}`, 5, 16, 10, 'bold');
    
    // Format address with word wrap if needed
    const address = libraryDetails.address || 'N/A';
    const words = address.split(' ');
    let line = '';
    let yPos = 21;
    
    words.forEach(word => {
      if ((line + word).length > 35) {
        safeAddText(line, 5, yPos, 8);
        line = word + ' ';
        yPos += 4;
      } else {
        line += word + ' ';
      }
    });
    if (line) {
      safeAddText(line, 5, yPos, 8);
    }

    safeAddText(`Seat: ${user.selectedSeat || 'Not selected'}`, 5, yPos + 5, 8);
    safeAddText(`Slot: ${user.selectedSlot || 'N/A'}`, 5, yPos + 9, 8);

    //QR Code
    try {
      const qrCodeDataUrl = await QRCode.toDataURL('https://TheLibraryHub.in');
      doc.addImage(qrCodeDataUrl, 'PNG', 61, 13, 20, 20);
      doc.setDrawColor('#ffffff');
      doc.setLineWidth(0.3);
      doc.roundedRect(60, 12, 22, 22, 3, 3);
    } catch (error) {
      console.error('Error generating QR code:', error);
      doc.setDrawColor(255, 255, 255);
      doc.setFillColor(240, 240, 240, 0.2);
      doc.roundedRect(61, 13, 20, 20, 3, 3, 'FD');
      centerText('QR CODE', 24, 8, 'normal', '#95a5a6');
    }

    //terms and conditions section
    safeAddText('Terms & Conditions:', 5, 36, 9, 'bold');
    const terms = [
      '1. Card must be carried during library visits',
      '2. Report lost cards immediately',
      '3. Non-transferable and valid with ID proof',
      '4. Subject to library rules and regulations'
    ];
    terms.forEach((term, index) => {
      safeAddText(term, 5, 40 + (index * 3), 6);
    });

    //decorative elements
    doc.setDrawColor('#ffffff');
    doc.setLineWidth(0.3);
    doc.line(5, 38, 81, 38);
    doc.line(5, 52, 81, 52);

    doc.save(`${user.name || 'User'}_ID_Card.pdf`);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <button
      onClick={generateIdCard}
      className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-lg hover:from-blue-600 hover:to-indigo-700 transition-all duration-300 shadow-md hover:shadow-lg"
    >
      <IdCard className="w-5 h-5" />
      <span className="hidden sm:inline">Generate ID Card</span>
    </button>
  );
}