import React from 'react';
import { motion } from 'framer-motion';
import {FaCalendar, FaBuilding, FaSearch, FaUser } from 'react-icons/fa';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <motion.div 
    className="bg-white bg-opacity-5 rounded-lg p-6 md:p-8 shadow-lg hover:shadow-xl transition-shadow duration-300"
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    viewport={{ once: true, amount: 0.2 }}
  >
    <Icon className="text-4xl md:text-5xl text-blue-400 mb-4 md:mb-6" />
    <h3 className="text-xl md:text-2xl font-semibold mb-2 md:mb-4">{title}</h3>
    <p className="text-gray-300 text-base md:text-lg">{description}</p>
  </motion.div>
);

const About = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-900 via-indigo-900 to-purple-900 text-white">
      <div className="container mx-auto px-4 py-12 md:py-24">
        <motion.section 
          className="mb-16 md:mb-32"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className="text-4xl md:text-5xl lg:text-7xl font-bold mb-6 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
            About The LibraryHub
          </h1>
          <p className="text-lg md:text-xl text-center text-gray-300 mb-8 max-w-3xl mx-auto">
            TheLibraryHub is a growing platform designed to connect students with the best libraries across the country. We're just starting, but we're rapidly expanding to bring you thousands of libraries to choose from.
          </p>
        </motion.section>

        <motion.section 
          className="mb-16 md:mb-32"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true, amount: 0.2 }}
        >
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-8 md:mb-12 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
            What We Offer
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
            <FeatureCard 
              icon={FaSearch}
              title="Easy Search"
              description="Students can easily search and select the perfect library that suits their study needs."
            />
            <FeatureCard 
              icon={FaCalendar}
              title="Flexible Plans"
              description="Each library offers multiple slots with different pricing, giving you the flexibility to choose a plan that fits your schedule and budget."
            />
            <FeatureCard 
              icon={FaBuilding}
              title="Empowering Libraries"
              description="We provide powerful management tools for libraries, enabling them to efficiently manage their student subscriptions, payments, and more."
            />
          </div>
        </motion.section>

        <motion.section 
          className="mb-16 md:mb-32"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true, amount: 0.2 }}
        >
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-8 md:mb-12 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
            Our Vision
          </h2>
          <p className="text-lg md:text-xl text-center text-gray-300 mb-8 max-w-3xl mx-auto">
            Our vision is to make studying accessible and convenient by creating a comprehensive platform where students can find their ideal study space with just a few clicks. As we grow, we aim to list thousands of libraries and create a thriving ecosystem for both students and libraries.
          </p>
        </motion.section>

        <motion.section 
          className="mb-16 md:mb-32"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true, amount: 0.2 }}
        >
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-8 md:mb-12 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
            Meet the Founder
          </h2>
          <div className="bg-white bg-opacity-5 rounded-lg p-6 md:p-8 shadow-lg max-w-3xl mx-auto">
            <div className="flex items-center mb-6">
              <FaUser className="text-4xl md:text-5xl text-blue-400 mr-4" />
              <h3 className="text-2xl md:text-3xl font-semibold">Anurag Saroj</h3>
            </div>
            <p className="text-gray-300 text-base md:text-lg mb-4">
              As the founder of The Library Hub, I've always believed in the importance of creating environments where students can focus and excel in their studies. I started TheLibraryHub with the goal of making it easier for students to find the best study spaces without the hassle.
            </p>
            <p className="text-gray-300 text-base md:text-lg mb-4">
              The idea for TheLibraryHub came from my own experience of searching for the perfect study environment. I wanted to create a solution where students don't have to waste time looking for a place to study; instead, they can quickly find and subscribe to a library that meets their needs.
            </p>
            <p className="text-gray-300 text-base md:text-lg">
              I see TheLibraryHub becoming the go-to platform for students seeking reliable, well-managed libraries and study spaces. My goal is to grow this platform into a network of thousands of libraries, giving students the convenience and flexibility they deserve.
            </p>
          </div>
        </motion.section>
      </div>
    </div>
  );
};

export default About;