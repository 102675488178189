import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import AdminDashboard from './components/AdminDashboard';
import OwnerDashboard from './components/OwnerDashboard';
import EditStudent from './components/EditStudent';
import LibraryDetails from './components/LibraryDetails';
import AuthProvider, { useAuth } from './components/AuthContext.js';
import Analytics from './components/Analytics.js';
import Libraries from './components/LIbraries.js';
import PageLayout from './components/PageLayout.js';
import WhyJoin from './components/WhyJoin.js';
import PrivacyPolicy from './components/privacy-policy.js';
import TermsOfService from './components/terms-of-service.js';
function App() {
    return (
        <AuthProvider>
            <Router>
                <PageLayout>
                <Navbar />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                        <Route path="/admin-dashboard" element={<PrivateRoute role="admin"><AdminDashboard /></PrivateRoute>} />
                        <Route path="/owner-dashboard" element={<PrivateRoute role="owner"><OwnerDashboard /></PrivateRoute>} />
                        <Route path="/edit-user/:userId" element={<PrivateRoute role={['admin', 'owner']}><EditStudent /></PrivateRoute>} />
                        <Route path="/library/:libraryId" element={<LibraryDetails />} />
                        <Route path="/analytics" element={<Analytics />} />
                        <Route path="/libraries" element={<Libraries />} />
                        <Route path="/why-join" element={<WhyJoin />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
                        <Route path="/terms-of-service" element={<TermsOfService />} />
                    </Routes>
                </PageLayout>
            </Router>
        </AuthProvider>
    );
}

// PrivateRoute component for route protection
const PrivateRoute = ({ children, role }) => {
    const { currentUser, role: userRole } = useAuth();

    if (!currentUser) {
        return <Navigate to="/login" />;
    }

    if (role && (Array.isArray(role) ? !role.includes(userRole) : role !== userRole)) {
        return <Navigate to="/" />;
    }

    return children;
};

export default App;
